<template>
    <div class="container-main">
        
        <div class="banner-box2">

            

            <div class="frame-100 delayed-animation2">
                <div>
                    <div class="a-powerful-tool-you3 center">A powerful tool for your Digital Workplace</div>
                    <!-- <div class="a-powerful-tool-you4">Digital Workplace</div> -->
                </div>
            </div>

            <div class="description2 center delayed-animation">
                <div class="left">
                    <div class="top h2-Medium">Hyperdrive productivity and well-being</div>
                    <div class="bottom Large">One app to bring everyone together</div>
                </div>
                <div class="right">
                    <div class="top H6-Medium">
                        <!-- Supercharge microsoft teams and google meet for your CX teams -->
                        <div class="supercharge-with-live-desk-digital-workplace">
                            <div class="supercharge-with-img"> Supercharge <img class="microteams"
                                    src="../assets/image/microteams.png"> </div>
                            with LiveDesk Digital Workplace
                        </div>

                    </div>
                    <div class="bottom">
                        <div class="btn-left">
                            <span class="Regular-Medium" @click="$router.push('/contact')">Get Started</span>
                            <img src="../assets/svg/arrow_forward.svg">
                        </div>
                        <!-- <div class="btn-right">
                            <img src="../assets/svg/play_circle_filled.svg">
                            <span class="Regular-Medium">Watch how it works</span>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="browser moving-box">
                <WorkplaceAnimation />
            </div>

            <div class="toolkit center">
                <div class="left">
                    <img class="working-image" src="../assets/image/middle.png" alt="" loading="lazy">
                </div>
                <div class="right">
                    <div class="title H1-Medium">The Collaboration Canvas <br> for CX Teams</div>
                    <div class="description Left-Vadodara-24">Discover LiveDesk, the powerful tool that augments MS Teams
                        (Meet, Slack & Zoom) to bring collaboration & communication to the next level. <br><br>
                        Built for distributed CX teams at home and in the office, LiveDesk ensures everyone works to the
                        same beat, and nothing is missed by having access to the same real-time information, support & care.
                    </div>
                </div>
            </div>
        </div>

        <div class="benefits">
            <div class="title Center-Vadodara">Benefits for your team, customers & business</div>
            <div class="tabs">
                <div class="btn" :class="{ active: isPageActive[0] }" @click="findActiveTab(0, true)">
                    <img src="@/assets/svg/Vector.svg" class="icon">
                    <div class="text Regular-Medium">People Benefits</div>
                </div>

                <div class="btn" :class="{ active: isPageActive[1] }" @click="findActiveTab(1, true)">
                    <img src="@/assets/svg/suggest.svg" class="icon">
                    <div class="text Regular-Medium">OPS Benefits</div>
                </div>

                <div class="btn" :class="{ active: isPageActive[2] }" @click="findActiveTab(2, true)">
                    <img src="@/assets/svg/satisfied.svg" class="icon">
                    <div class="text Regular-Medium">CX Benefits</div>
                </div>
            </div>

            <div class="benefits-content">
            </div>
        </div>

        <div class="swiper-content">
            <swiper @swiper="onSwiperInit" @slideChange="onSlideChange" :pagination="{ clickable: true }" :modules="modules"
                :autoplay="{
                    delay: 2500,
                    disableOnInteraction: false,
                }">
                <swiper-slide>
                    <div class="flex-box2 center">
                        <div class="item">
                            <img src="../assets/image/acute.png" alt="">
                            <div class="title H5-Regular">Simplicity</div>
                            <div class="description Regular">One app with everything needed for all to perform at their best
                            </div>
                        </div>

                        <div class="item">
                            <img src="../assets/image/new_releases.png" alt="">
                            <div class="title H5-Regular">Assurance</div>
                            <div class="description Regular">All the support needed to get answers & care in a click</div>
                        </div>

                        <div class="item">
                            <img src="../assets/image/data_check.png" alt="">
                            <div class="title H5-Regular">Connected</div>
                            <div class="description Regular">Work or social, one place bringing everyone together</div>
                        </div>

                        <div class="item">
                            <img src="../assets/image/mood.png" alt="">
                            <div class="title H5-Regular">Engaged</div>
                            <div class="description Regular">Happier, well-supported people means hybrid working at it’s
                                best</div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="flex-box2 center">
                        <div class="item">
                            <img src="../assets/image/volunteer_activism.png" alt="">
                            <div class="title H5-Regular">Consistent Delivery</div>
                            <div class="description Regular">Equal information, care & support for all your work citizens
                            </div>
                        </div>

                        <div class="item">
                            <img src="../assets/image/diversity_3.png" alt="">
                            <div class="title H5-Regular">Less management overheads</div>
                            <div class="description Regular">Support everyone virtually, centrally & concurrently</div>
                        </div>

                        <div class="item">
                            <img src="../assets/image/video_camera_front.png" alt="">
                            <div class="title H5-Regular">Delivery Control</div>
                            <div class="description Regular">No need to ship your people to supervise sites & suppliers
                            </div>
                        </div>

                        <div class="item">
                            <img src="../assets/image/monitoring.png" alt="">
                            <div class="title H5-Regular">Digital Workplace Analytics</div>
                            <div class="description Regular">Analytics to continuously improve your operation inside & out
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="flex-box2 center">
                        <div class="item">
                            <img src="../assets/image/benefitsLogo/alarm.png" alt="">
                            <div class="title H5-Regular">Efficiency</div>
                            <div class="description Regular">Doesn’t matter where you are, with LiveDesk your workers get
                                answers fast. Up to a 10% reduction in handle time.</div>
                        </div>

                        <div class="item">
                            <img src="../assets/image/benefitsLogo/publishe.png" alt="">
                            <div class="title H5-Regular">Effectiveness</div>
                            <div class="description Regular">Get the right answers 1st time. No repeat calls. No redundant
                                costs. Happy customers.</div>
                        </div>

                        <div class="item">
                            <img src="../assets/image/benefitsLogo/task.png" alt="">
                            <div class="title H5-Regular">Compliance</div>
                            <div class="description Regular">With LiveDesk, everything is tracked, giving you ultimate
                                visibility & control on your entire CX operation.</div>
                        </div>

                        <div class="item">
                            <img src="../assets/image/benefitsLogo/favorite.png" alt="">
                            <div class="title H5-Regular">Happier Customers</div>
                            <div class="description Regular">LiveDesk delivers better customer outcomes, translating to
                                happier customers. With LiveDesk CSAT & NPS soars.</div>
                        </div>
                    </div>
                </swiper-slide>

                <div class="pagination-bullets-container">
                    <div :class="['custom-pagination', { 'custom-pagination-active': isPageActive[0] }]" slot="pagination">
                    </div>
                    <div :class="['custom-pagination', { 'custom-pagination-active': isPageActive[1] }]" slot="pagination">
                    </div>
                    <div :class="['custom-pagination', { 'custom-pagination-active': isPageActive[2] }]" slot="pagination">
                    </div>
                </div>



            </swiper>
            <!-- <div class="swiper-pagination"></div> -->
        </div>

        <!-- New swiper imple -->
        <!-- <div class="swiper-content">
            <swiper :slides-per-view="swiperOptions.slidesPerView" :space-between="swiperOptions.spaceBetween"
                :centered-slides="swiperOptions.centeredSlides" :pagination="swiperOptions.pagination"
                :breakpoints="swiperOptions.breakpoints" @swiperprogress="onSwiperProgress"
                @swiperslidechange="onSlideChange">
                <swiper-slide v-for="(item, index) in items" :key="index">
                    <div class="flex-box center">
                        <div class="item">
                            <img :src="item.image" :alt="item.title">
                            <div class="title H5-Regular">{{ item.title }}</div>
                            <div class="description Regular">{{ item.description }}</div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="swiper-pagination" slot="pagination"></div>
        </div> -->


        <div class="leading-brands center">
            <div class="title H6-Medium">Trusted by leading brands</div>
            <div class="brands">
                <img src="../assets/image/logo/warehouse.png">
                <img src="../assets/image/logo/sensee.png">
                <img src="../assets/image/logo/Ageas.png">
                <img src="../assets/image/logo/allianz.png">
                <img src="../assets/image/logo/bupa.png">
                <img src="../assets/image/logo/power.png">
            </div>
        </div>

        <div class="warehouse">
            <div class="left">
                <img src="../assets/image/warehousePic.png" alt="">
            </div>
            <div class="right">
                <span class="livedeskLogo">“LiveDesk</span>
                <span class="H4-Medium"> delivers highly effective team collaboration. We’re delighted with its impact, and
                    have never come across another solution with the same features and functionality.”</span>
                <div>
                    <img src="../assets/image/logo/warehouse.png">
                </div>
            </div>
        </div>

        <BottomCtaComponent />

    </div>
</template>
<script>
// let that;
import WorkplaceAnimation from './WorkplaceAnimation.vue';
import { scrollTo } from 'vue-scrollto';
import BottomCtaComponent from '../components/bottomCta.vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay } from 'swiper/vue';

// import TopNavBar from './TopNavBar.vue';

import 'swiper/swiper-bundle.css';

export default {
    name: 'frontPage',
    props: {
    },

    mounted() {
        this.scrollToHash();
        this.loopInterval = setInterval(this.loopSwiper, 5000);
    },
    beforeRouteLeave() {
        clearInterval(this.loopInterval);
    },
    data() {
        return {

            curInd: 1,
            loopInterval: null,
            modules: [Navigation, Pagination, Autoplay],
            pagination: {
                clickable: true,

            },
            swiperInstance: null,
            tabs: [
                { title: 'People Benefits', svg: '@/assets/svg/logo.svg' },
                { title: 'OPS Benefits', svg: '@/assets/svg/suggest.svg' },
                { title: 'CX Benefits', svg: '@/assets/svg/satisfied.svg' },
            ],
            activeIndex: 1,
            isFixed: false,
            isPageActive: new Array(3).fill(false).map((value, index) => index === 0),

            items: [
                {
                    image: '../assets/image/acute.png',
                    title: 'Simplicity',
                    description: 'One app with everything needed for all to perform at their best',
                },
                {
                    image: '../assets/image/new_releases.png',
                    title: 'Assurance',
                    description: 'All the support needed to get answers & care in a click',
                },
                {
                    image: '../assets/image/data_check.png',
                    title: 'Connected',
                    description: 'Work or social, one place bringing everyone together',
                },
                {
                    image: '../assets/image/mood.png',
                    title: 'Engaged',
                    description: 'Happier, well-supported people means hybrid working at its best',
                },
                {
                    image: '../assets/image/volunteer_activism.png',
                    title: 'Consistent Delivery',
                    description: 'Equal information, care & support for all your work citizens',
                },
                {
                    image: '../assets/image/diversity_3.png',
                    title: 'Less management overheads',
                    description: 'Support everyone virtually, centrally & concurrently',
                },
                {
                    image: '../assets/image/video_camera_front.png',
                    title: 'Delivery Control',
                    description: 'No need to ship your people to supervise sites & suppliers',
                },
                {
                    image: '../assets/image/monitoring.png',
                    title: 'Digital Workplace Analytics',
                    description: 'Analytics to continuously improve your operation inside & out',
                },
                {
                    image: '../assets/image/benefitsLogo/alarm.png',
                    title: 'Efficiency',
                    description: "Doesn't matter where you are, with LiveDesk your workers get answers fast. Up to a 10% reduction in handle time.",
                },
                {
                    image: '../assets/image/benefitsLogo/publishe.png',
                    title: 'Effectiveness',
                    description: 'Get the right answers 1st time. No repeat calls. No redundant costs. Happy customers.',
                },
                {
                    image: '../assets/image/benefitsLogo/task.png',
                    title: 'Compliance',
                    description: 'With LiveDesk, everything is tracked, giving you ultimate visibility & control on your entire CX operation.',
                },
                {
                    image: '../assets/image/benefitsLogo/favorite.png',
                    title: 'Happier Customers',
                    description: 'LiveDesk delivers better customer outcomes, translating to happier customers. With LiveDesk CSAT & NPS soars.',
                },
            ],
            spaceBetween: 10
        }
    },
    components: {
        WorkplaceAnimation,
        BottomCtaComponent,
        Swiper,
        SwiperSlide,
        // TopNavBar
    },
    methods: {

        onSwiperInit(swiper) {
            this.swiperInstance = swiper;
        },
        activateSlide(index) {
            if (this.swiperInstance) {
                this.swiperInstance.slideTo(index);
            }
        },
        onSwiperProgress() {

        },
        onSlideChange() {
            // that.findActiveTab(this.activeIndex);
        },
        loopSwiper() {
            this.swiperInstance.slideTo(this.curInd);
            this.isPageActive.fill(false);
            this.isPageActive[this.curInd] = true;
            this.curInd = (this.curInd + 1) % 3;

        },
        findActiveTab(e, isClick) {

            console.log(e, isClick)
            this.swiperInstance.slideTo(e);
            this.isPageActive.fill(false);
            this.isPageActive[e] = true;


        },

        scrollToHash() {
            const hash = window.location.hash;
            if (hash) {
                // console.log(hash);
                try { scrollTo(hash, 1000, { easing: 'ease-in-out' }); }
                catch (error) { console.log(error) }

            }
        },
    },
    computed: {

    },

}
</script>



<style scoped lang="less">
:root {
    // --swiper-pagination-color: #000000 --swiper-theme-color: #FFFFFF !important;
    // --swiper-pagination-bullet-inactive-color: #000000 !important;
    // --swiper-pagination-bullet-inactive-color: #000;
}

// /deep/.swiper-pagination-bullet-active {
//     background-color: #fff;
// }

// /deep/.swiper-pagination-bullet {
//     opacity: 1;
// }


.custom-pagination {
    /* Your custom styles for pagination bullets */
    width: 10px;
    height: 10px;
    border: 2px solid #fff;

    margin-right: 5px;
    border-radius: 50%;
    //   display: inline-block;
}

.custom-pagination-active {
    background-color: #fff;
}

.pagination-bullets-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}


.frame-100 {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.a-powerful-tool-you3 {
    color: var(--cloudworks-neutral-white, #ffffff);
    // text-align: left; 
    // margin-left: -50px;
    display: flex;
    justify-content: center;
    font: var(--pri-web-heading-h-2-medium,
            500 36px/52px "Work Sans");
    position: relative;
    // left: 500px;
}

@keyframes fadeIn3 {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

.delayed-animation {
    opacity: 0;
    visibility: hidden;
    animation: fadeIn3 1s ease-in-out 3s forwards;
}


@keyframes fadeOut4 {

    0% {
        opacity: 1;
        visibility: visible;
        height: 52px;
    }

    100% {
        opacity: 0;
        visibility: hidden;
        // display: none;
        height: 45px;
    }
}

@keyframes fadeIn4 {

    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;

    }
}

.supercharge-with-live-desk-digital-workplace {
    color: var(--grayscale-white, #FFF);

    /* Pri -WEB/Heading H6/Medium */
    font-family: Work Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 140% */
    letter-spacing: -0.4px;
}

.supercharge-with-img {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.microteams {
    margin-bottom: -2px;
    margin-left: 5px;
}

.working-image {
    z-index: 0;
}

.delayed-animation2 {
    opacity: 0;
    visibility: hidden;
    animation: fadeIn4 1s ease-in-out 1s forwards, fadeOut4 1s ease-in-out 3s forwards;

}


@keyframes moveTop {
    0% {
        // top: 25px; /* Initial position */
        transform: translateY(-170px);
    }

    100% {
        // top: 165px; /* Final position after animation */
        transform: translateY(0px);
    }
}

.moving-box {
    // position:absolute;
    top: 25px;
    transform: translateY(-170px);
    animation: moveTop 1s ease-in-out 3s forwards;
}

a {
    text-decoration: none;
}

.center {
    margin: 0 auto;
    width: 80%;
}

.container-main {
    margin: 0 auto;
    background: #F2F3F4;

    // .clearFloat {
    //     overflow: hidden;
    // }
}



.banner-box2 {
    // overflow: hidden;
    width: 100%;
    background: url('../assets/image/main-bg.svg');
    background-repeat: no-repeat;
    margin-top: -1px;
    // background-size: 100% 50%;

    // .background-svg {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     // width: 100%;
    //     // height: 100%;
    // }

    .description2 {
        // margin-top: 20px;
        display: flex;
        justify-content: space-between;
        margin-top: -10px;

        .left {
            color: #fff;
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            width: 493px;
            margin-left: 45px;

            .top {
                //styleName: Pri -WEB/Heading H2/Medium;
                font-family: Work Sans;
                font-size: 44px;
                font-weight: 600;
                line-height: 52px;
                // letter-spacing: -0.02em;
                letter-spacing: -0.88px;
                // text-align: left;
            }

            .bottom {
                //styleName: Pri -WEB/Display Large/Large;
                font-family: Work Sans;
                font-size: 28px;
                font-weight: 500;
                line-height: 36px;
                // letter-spacing: -0.02em;
                letter-spacing: -0.56px;
                text-align: left;
            }
        }

        .right {
            width: 457px;
            // padding-top: 45px;
            display: flex;
            flex-direction: column;

            // align-items: center;

            .top {

                text-align: left;
                color: #fff;
            }

            .bottom {
                margin-top: 16px;
                display: flex;

                .btn-left {
                    cursor: pointer;
                    width: 148px;
                    height: 48px;
                    background: #fff;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-size: 16px;
                        color: #0C5C6A;
                    }

                    img {
                        width: 18px;
                        height: 18px;
                        margin-left: 8px;
                    }
                }

                .btn-right {
                    margin-left: 16px;
                    width: 206px;
                    height: 48px;
                    cursor: pointer;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #fff;

                    span {
                        font-size: 16px;
                        color: white;
                        margin-left: 8px;
                    }

                    img {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }


        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;

            .left,
            .right {
                width: 100%;
                margin-left: 0;
                text-align: center;

                .top {
                    font-size: 36px;
                    line-height: 42px;
                }

                .bottom {
                    font-size: 22px;
                    line-height: 30px;
                }
            }

            .right {
                margin-top: 20px;

                .top {
                    text-align: center;
                }

                .bottom {
                    flex-direction: column;

                    .btn-left,
                    .btn-right {
                        width: 100%;
                        max-width: 300px;
                        margin-left: 0;
                    }
                }
            }
        }


    }

    .browser {
        margin: 0 auto;
        width: 1140px;
        max-width: 100vw;
        //height: 641px;
        // margin-top: 20px;
        background-size: 108%;
        background-position: -46px -29px;

        @media (max-width: 767px) {
            width: 100%;
            height: auto;
            max-width: 100%;
            background-size: cover;
            background-position: center;
        }

    }

    .toolkit {
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        // height: 424px;

        .right {
            padding-top: 45px;
            margin-left: 64px;

            .title {
                //styleName: Pri -WEB/Heading H1/Medium;
                color: var(--grayscale-100, #1D2226);

                /* Pri -WEB/Heading H1/Medium */
                font-family: Work Sans;
                font-size: 44px;
                font-style: normal;
                font-weight: 500;
                line-height: 52px;
                /* 118.182% */
                letter-spacing: -0.88px;
                text-align: left;
            }

            .description {
                margin-top: 20px;
            }
        }

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            text-align: center;

            margin-top: 50px;

            .right {
                margin-left: 0;
                padding-top: 0;

                .title {
                    font-size: 28px;
                    line-height: 36px;
                    letter-spacing: -0.56px;
                    text-align: center;
                }
            }
        }
    }



}



// css for svg main background


// .rectangle-685,
// .rectangle-685 * {
//   box-sizing: border-box;
// }
// .rectangle-685 {
//   width: 1440px;
//   height: 760px;
//   position: relative;
//   overflow: visible;
// }




.benefits {
    padding-top: 40px;
    padding-bottom: 12px;
    background: #0C5C6A;

    @media (max-width: 767px) {}

    .title {
        font-family: Hind Vadodara;
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;

    }

    .tabs {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
            flex-direction: column;
        }

        .btn {
            position: relative;
            cursor: pointer;

            img {
                width: 24px;
                height: 24px;
            }

            display: flex;
            padding: 12px 16px;
            margin-left: 18px;
            background: #FFFFFF33;
            border-radius: 4px;

            &:first-child {
                margin-left: 0px;
            }

            .text {
                //styleName: Sec -WEB/Paragraph Regular/Medium;
                font-family: Roboto;
                font-size: 16px;
                margin-left: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: #fff;
                text-wrap: nowrap;
                display: flex;
                align-items: center;
            }

            &.active {
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));

                &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    bottom: -12px;
                    right: 0px;
                    width: 100%;
                    background: #fff;

                }

                .text {
                    color: #0C5C6A;
                }
            }
        }
    }
}

.swiper-content {
    padding-top: 30px;
    height: 452px;
    background: url('../assets/image/nenefitsBG.png') no-repeat;
    background-size: 100% auto;

    @media only screen and (min-width: 900px) and (max-width: 1358px) {
        height: 560px;
    }

    .item {
        padding: 20px 18px 20px 20px;
        width: 270px;
        background: #fff;
        border-radius: 16px;
        box-sizing: border-box;

        &:nth-child(n+2) {
            margin-left: 20px;
        }
    }

    .flex-box {
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }



    .title {
        //styleName: Pri -WEB/Heading H5/Regular;
        font-family: Work Sans;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #111133;
        margin-top: 20px;
    }

    .description {
        margin-top: 6px;
        //styleName: Sec -WEB/Paragraph Regular/Regular;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #545D7D;
    }
}

.flex-box2 {
    display: flex;

    @media (max-width: 767px) {
        flex-direction: column;
    }

    justify-content: center;
    height: auto;
}

.leading-brands {
    padding: 30px;
    margin-top: -90px;
    /*  height: 178px; */
    border-radius: 16px;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px 0px #00000026;

    .title {
        //styleName: Pri -WEB/Heading H6/Medium;
        font-family: Work Sans;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #1D2226;
    }

    .brands {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 767px) {
            flex-direction: column;
        }

        margin-top: 35px;
        justify-content: space-evenly;
    }

}

.warehouse {
    display: flex;

    @media (max-width: 767px) {
        flex-direction: column;
    }

    align-items: center;
    justify-content: center;
    margin-top: 60px;
    padding-bottom: 20px;

    .right {
        width: 736px;
        //styleName: Pri -WEB/Heading H4/Medium;
        font-family: Work Sans;
        font-size: 28px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: -0.02em;
        text-align: left;

        .livedeskLogo {
            color: #0C5C6A;
        }
    }
}

.banner-box2 {
    overflow: hidden;
}

@media screen and (max-width:900px) {
    .center {
        width: 90%;
    }

    .supercharge-with-live-desk-digital-workplace {
        font-size: 16px;
    }

    .h2-Medium {
        font-size: 28px !important;
    }

    .Large {
        font-size: 20px !important;
    }

    .H4-Medium,
    .livedeskLogo {
        font-size: 20px !important;
    }

    .a-powerful-tool-you3 {}

    .banner-box2 {
        background-image: url('../assets/image/Rectangle-685.png');
        background-size: 100% 50vh;
    }

    .flex-box2 {
        gap: 8px;
    }

    .banner-box2 .description2 .right .top {
        text-align: start;
    }

    .banner-box2 .description2 .right .bottom {
        flex-direction: row;
        gap: 4px;
        width: 100%;
    }

    .banner-box2 .description2 .right .bottom [class^="btn-"] {
        // min-width: max-content;
        width: fit-content;
        padding-inline: 20px;
    }

    .banner-box2 .toolkit .right .title {
        font-size: 24px;
        text-align: start;
        margin-top: 20px;
    }

    .Left-Vadodara-24 {
        font-size: 16px;
    }

    .benefits .tabs {
        flex-direction: row;
        gap: 4px;

        .btn {
            margin-left: 0;
            gap: 1px;
            padding: 8px 8px;
            min-width: max-content;

            .text {
                font-size: 14px;
                margin: 0;
            }
        }
    }

    .swiper-content {
        height: unset;
        background-size: 100% calc(100% + 178px);
        padding-bottom: 178px;

        .item {
            width: unset;
            margin: 0;
            margin-left: 0 !important;
        }

        .pagination-bullets-container {
            margin-top: 20px;
        }
    }

    .leading-brands {
        height: unset;

        .brands {
            flex-direction: row;


            img {
                width: 90px;
            }
        }
    }

    .warehouse {
        display: block;

        .right {
            width: 90%;
            margin: auto;

        }
    }

    .microteams {
        height: 30px;
    }

    .banner-box2 .description2 .right {
        align-items: start;
    }

    .a-powerful-tool-you3 {
        display: block;
        max-width: 90%;
        font-size: 28px !important;
        white-space: normal;
    }

    .banner-box2 .toolkit {
        margin-top: 0px;
        flex-direction: column;
    }
}

// /deep/.swiper-pagination {
//     position: static;
//     height: 20px;
//     width: 20px;
// }

// .swiper-pagination > .swiper-pagination-bullet {
//     opacity: 1 !important;
//     border: white solid 1px !important;
//     background-color: transparent !important;
// }

// .swiper-pagination-bullet {
//     background-color: #FFFFFF !important;
// }

// .swiper-pagination-bullet-active {
//     background-color: white !important;
// }</style>
