<template>
    <!-- DIGITAL WORKPLACE START -->
    <div class="container position-relative py-5" id="virtual-office">

        
        <!-- <div class="text-center mb-4">
            <p class="d-none d-lg-block h6 strong text-secondary">Hover the computer screen to learn more about each
                type of pod.</p>
            <p class="d-block d-lg-none h6 strong text-secondary">Click "Find out more" to learn more about each type of
                pod.</p>
        </div> -->
        <div ref="carouselContainer" class="position-relative h-centered w-90-vw w-xxl-60-vw carousel-container">
            <img class="w-100" src="../assets/image/landing/virtual_office_wide.svg" alt="">

            <div class="carousel">
                <div class="carousel__track-container rounded-sm">
                    <div class="carousel__track">
                        <img class="position-absolute w-100 d-block d-lg-none" src="../assets/image/navbar-mockup.png"
                            alt="">
                        <div class="desktop__navbar">
                            <div class="d-none d-lg-flex">
                                <div class="d-flex align-items-center">
                                    <img style="height: 20px;" src="../assets/image/sensee-logo-blue.svg"
                                        alt="LiveDesk Logo">
                                </div>
                                <div class="ml-2 position-relative d-flex align-items-center shadow-lg" id="navbar-link-1">
                                    <label class="d-flex align-items-center cursor-pointer mb-0">
                                        <p class="mb-0">Livedesk</p>
                                        <img style="width: 12px;" class="invertY ml-0-5"
                                            src="../assets/image/icons/chevron.svg" alt="">
                                    </label>
                                    <div class="desktop__dropdown --livedesk">
                                        <li class="position-relative">
                                            <ul class="highlighted-option --broadcast">Broadcast message</ul>
                                            <div class="desktop__popup --highlight --broadcast">
                                                <h6 class="text-primary">Broadcast message</h6>
                                                <p>Broadcast something as a popup message to all users or to individual
                                                    people. Enforce a checkbox that requires the user(s) to acknowledge
                                                    they have seen your message before closing the popup.</p>
                                            </div>
                                            <ul class="highlighted-option --edit-department">Edit department lobby
                                                message</ul>
                                            <div class="desktop__popup --highlight --edit-department">
                                                <h6 class="text-primary">Lobby message</h6>
                                                <p>The lobby message allows you to produce a message that will be
                                                    displayed to all users as they log into LiveDesk.</p>
                                            </div>
                                            <ul>Enable mood meter</ul>
                                        </li>
                                        <hr class="bg-gray-100 my-0-5">
                                        <li>
                                            <ul>Toggle emcee only area</ul>
                                            <ul>Toggle full screen</ul>
                                        </li>
                                        <hr class="bg-gray-100 my-0-5">
                                        <li>
                                            <ul>End session for all</ul>
                                        </li>
                                        <hr class="bg-gray-100 my-0-5">
                                        <li class="highlighted-option --admin position-relative">
                                            <ul>Go to LiveDesk admin</ul>
                                            <ul>Open metrics dashboard</ul>
                                            <div class="desktop__popup --highlight --admin">
                                                <h6 class="text-primary">Admin and Metrics</h6>
                                                <p>Access your administration area to configure who can access your
                                                    department and update configurations. Visit the LiveDesk metrics
                                                    page to learn about how agents and managers are performing.</p>
                                            </div>
                                        </li>
                                    </div>
                                </div>
                                <div class="ml-2 position-relative d-flex align-items-center shadow-lg" id="navbar-link-2">
                                    <label class="d-flex align-items-center cursor-pointer mb-0">
                                        <p class="mb-0">Layouts</p>
                                        <img style="width: 12px;" class="invertY ml-0-5"
                                            src="../assets/image/icons/chevron.svg" alt="">
                                    </label>
                                    <div class="desktop__dropdown --layouts">
                                        <div class="highlighted-option --new-layout">
                                            <li>
                                                <ul>Create new layout</ul>
                                            </li>
                                            <hr class="bg-gray-100 my-0-5">
                                            <li>
                                                <ul>Main layout</ul>
                                                <ul>Training layout</ul>
                                            </li>
                                        </div>
                                        <div class="desktop__popup --highlight --new-layout">
                                            <h6 class="text-primary">Layouts</h6>
                                            <p>LiveDesks can have multiple layouts configured. Each layout can have
                                                different pods enabled and different positions and sizes.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="ml-2 position-relative d-flex align-items-center shadow-lg" id="navbar-link-3">
                                    <label class="d-flex align-items-center cursor-pointer mb-0">
                                        <p class="mb-0">Pods</p>
                                        <img style="width: 12px;" class="invertY ml-0-5"
                                            src="../assets/image/icons/chevron.svg" alt="">
                                    </label>
                                    <div class="desktop__dropdown --pods">
                                        <li class="highlighted-option --pods">
                                            <ul class="d-flex justify-content-between">
                                                <span>Image</span>
                                                <img style="width: 10px; transform: rotate(90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                            </ul>
                                            <ul class="d-flex justify-content-between">
                                                <span>Note</span>
                                                <img style="width: 10px; transform: rotate(90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                            </ul>
                                            <ul class="d-flex justify-content-between">
                                                <span>Users</span>
                                                <img style="width: 10px; transform: rotate(90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                            </ul>
                                            <ul class="d-flex justify-content-between">
                                                <span>Chat</span>
                                                <img style="width: 10px; transform: rotate(90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                            </ul>
                                            <ul class="d-flex justify-content-between">
                                                <span>Threaded chat</span>
                                                <img style="width: 10px; transform: rotate(90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                            </ul>
                                            <ul class="d-flex justify-content-between">
                                                <span>Files</span>
                                                <img style="width: 10px; transform: rotate(90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                            </ul>
                                            <ul class="d-flex justify-content-between">
                                                <span>Poll</span>
                                                <img style="width: 10px; transform: rotate(90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                            </ul>
                                            <ul class="d-flex justify-content-between">
                                                <span>Web</span>
                                                <img style="width: 10px; transform: rotate(90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                            </ul>
                                            <ul class="d-flex justify-content-between">
                                                <span>Mood meter</span>
                                                <img style="width: 10px; transform: rotate(90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                            </ul>
                                            <ul class="d-flex justify-content-between">
                                                <span>Multi-department</span>
                                                <img style="width: 10px; transform: rotate(90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                            </ul>
                                        </li>
                                        <div class="desktop__popup --highlight --pods">
                                            <h6 class="text-primary">Pods</h6>
                                            <p>LiveDesks is made of pods. In this menu you can see existing Pods and
                                                create new ones. Selecting a Pod will add it to the manager area of the
                                                LiveDesk, so that it can be resized and positioned in the perfect place.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="ml-2 position-relative d-flex align-items-center shadow-lg" id="navbar-link-4">
                                    <label class="d-flex align-items-center cursor-pointer mb-0">
                                        <p class="mb-0">Help</p>
                                        <img style="width: 12px;" class="invertY ml-0-5"
                                            src="../assets/image/icons/chevron.svg" alt="">
                                    </label>
                                    <div class="desktop__dropdown --help">
                                        <li class="highlighted-option --help">
                                            <ul>Helpdesk</ul>
                                            <ul>External documents</ul>
                                            <ul>External dashboard</ul>
                                        </li>
                                        <div class="desktop__popup --highlight --help">
                                            <h6 class="text-primary">Help links</h6>
                                            <p>You can configure help links to show in the LiveDesk top bar. These links
                                                are accessible by anyone in the LiveDesk, so they are perfect to have
                                                quick access to important urls.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="ml-2 position-relative d-flex align-items-center shadow-lg" id="navbar-link-5">
                                    <label class="d-flex align-items-center cursor-pointer border border-primary m-0 p-0">
                                        <img class="border-right border-primary"
                                            style="width: 16px; height: 16px; padding-top: .15rem; padding-left: .15rem; padding-bottom: .15rem; padding-right: .3rem;"
                                            src="../assets/image/icons/raised-hand.svg" alt="">
                                        <img style="width: 16px; height: 16px; padding: .15rem;"
                                            src="../assets/image/icons/arrow-down.svg" alt="">
                                    </label>
                                    <div class="desktop__dropdown --status">
                                        <div class="highlighted-option --status">
                                            <li>
                                                <ul>
                                                    <img style="width: 10px;"
                                                        src="../assets/image/icons/raised-hand-black.svg" alt="">
                                                    <span>Raised hand</span>
                                                </ul>
                                                <ul>
                                                    <img style="width: 10px;" src="../assets/image/icons/agree.svg" alt="">
                                                    <span>Agree</span>
                                                </ul>
                                                <ul>
                                                    <img style="width: 10px;" src="../assets/image/icons/disagree.svg"
                                                        alt="">
                                                    <span>Disagree</span>
                                                </ul>
                                                <ul>
                                                    <img style="width: 10px;" src="../assets/image/icons/coaching.svg"
                                                        alt="">
                                                    <span>Coaching</span>
                                                </ul>
                                                <ul>
                                                    <img style="width: 10px;"
                                                        src="../assets/image/icons/troubleshooting.svg" alt="">
                                                    <span>Troubleshooting</span>
                                                </ul>
                                                <ul>
                                                    <img style="width: 10px;" src="../assets/image/icons/break.svg" alt="">
                                                    <span>On break</span>
                                                </ul>
                                                <ul>
                                                    <img style="width: 10px;" src="../assets/image/icons/meeting.svg"
                                                        alt="">
                                                    <span>Meeting</span>
                                                </ul>
                                                <ul>
                                                    <img style="width: 10px;" src="../assets/image/icons/floorwalking.svg"
                                                        alt="">
                                                    <span>Floorwalking</span>
                                                </ul>
                                            </li>
                                            <hr class="bg-gray-100 my-0-5">
                                            <li>
                                                <ul>Clear status</ul>
                                            </li>
                                        </div>
                                        <div class="desktop__popup --highlight --status">
                                            <h6 class="text-primary">Status</h6>
                                            <p>People can use the status menu to apply a status to their name on the
                                                user list. Statuses can be used to know what activity each person is
                                                doing or to quickly poll your desk about something.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-none d-lg-flex">
                                <div class="position-relative d-flex align-items-center shadow-lg" id="navbar-link-6">
                                    <label class="d-none d-lg-flex align-items-center cursor-pointer mb-0">
                                        <p class="mb-0">Amy Doe</p>
                                        <img style="width: 14px; padding: .2rem; background-color: var(--pink); overflow: hidden;"
                                            class="rounded-xl ml-0-5 bg-gray-200" src="../assets/image/icons/advisor.svg"
                                            alt="">
                                        <img style="width: 12px;" class="invertY ml-0-5"
                                            src="../assets/image/icons/chevron.svg" alt="">
                                    </label>
                                    <div class="desktop__dropdown --profile">
                                        <li class="position-relative">
                                            <ul class="d-flex justify-content-between">
                                                <img style="width: 10px; transform: rotate(-90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                                <span>Change my role</span>
                                            </ul>
                                            <ul class="d-flex justify-content-between highlighted-option --location">
                                                <img style="width: 10px; transform: rotate(-90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                                <span>Change my location</span>
                                            </ul>
                                            <div class="desktop__popup --highlight --location">
                                                <h6 class="text-primary">Location</h6>
                                                <p>Each person can set their location to be either "home" or "office".
                                                    Depending on their location there will be a "roof" icon on top of
                                                    their profile in the users list.</p>
                                            </div>
                                        </li>
                                        <hr class="bg-gray-100 my-0-5">
                                        <li>
                                            <ul class="d-flex justify-content-between">
                                                <img style="width: 10px; transform: rotate(-90deg);"
                                                    src="../assets/image/icons/chevron.svg" alt="">
                                                <span>Go to department</span>
                                            </ul>
                                        </li>
                                        <hr class="bg-gray-100 my-0-5">
                                        <li>
                                            <ul>Logout from LiveDesk</ul>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <li class="carousel__slide w-100 position-relative">
                            <div class="desktop__pod --users">
                                <img src="../assets/image/landing/dw-pictures/pod-users.png">
                            </div>
                            <div class="desktop__popup --users">
                                <h6 class="text-primary">Users Pod</h6>
                                <p class="small">The Users Pod lists users by role (Agents, Floorwalkers, Managers, IT).
                                    It shows where everyone is in the digital workplace (virtual bays, breakout rooms)
                                    and also in the physical space (@home or in the office). Finally, it shows what
                                    status people have from "on break" to "IT downtime". In the meeting room tab you can
                                    create on the fly groups to go into virtual rooms for 1-2-1s, team huddles and the
                                    like using Teams, Webex or Zoom.</p>
                            </div>
                            <div class="desktop__pod --image">
                                <img src="../assets/image/landing/dw-pictures/pod-image.png">
                            </div>
                            <div class="desktop__popup --image">
                                <h6 class="text-primary">Image Pod</h6>
                                <p class="small">Design your digital workplace by displaying persistent images via JPEG,
                                    GIF and PNG formats.</p>
                            </div>
                            <div class="desktop__pod --poll">
                                <img src="../assets/image/landing/dw-pictures/pod-poll.png">
                            </div>
                            <div class="desktop__popup --poll">
                                <h6 class="text-primary">Poll Pod</h6>
                                <p class="small">Getting the pulse from your people quickly is quintessential to the
                                    smooth running of your operations. With Poll Pods, you can ask anything, anytime and
                                    get answers quickly whether it's overtime, mood, or validating debriefs.</p>
                            </div>
                            <div class="desktop__pod --threaded-chat-1">
                                <img src="../assets/image/landing/dw-pictures/pod-threaded-chat-1.png">
                            </div>
                            <div class="desktop__popup --threaded-chat-1">
                                <h6 class="text-primary">Virtual Bay Scoped Chat Pod</h6>
                                <p class="small">Virtual Bays allow you to assign users within a chat Pod to specific
                                    areas of work. The virtual bay feature allows you to segregate the workforce (both
                                    agents and floorwalkers) into specific workflows so that work activities within one
                                    chat window are streamlined. Any Chat Pod, both threaded and non-threaded, can be
                                    scoped to virtual bays so that you can customise your LiveDesk to your exact needs.
                                </p>
                            </div>
                            <div class="desktop__pod --notes">
                                <img src="../assets/image/landing/dw-pictures/pod-notes.png">
                            </div>
                            <div class="desktop__popup --notes">
                                <h6 class="text-primary">Notes Pod</h6>
                                <p class="small">You can't rely on email to ensure your teams are in the know about
                                    something. The Notes Pod is your friend to ensure the information and communication
                                    is seen – and read! With rich text editing as well as link share for quick & easy
                                    access to website information.</p>
                            </div>
                            <div class="desktop__pod --files">
                                <img src="../assets/image/landing/dw-pictures/pod-files.png">
                            </div>
                            <div class="desktop__popup --files">
                                <h6 class="text-primary">Files Pod</h6>
                                <p class="small">The Files Pod provides quick & easy access to all must-have reference
                                    files. Managers can upload files for Agents to have access to. Read access is logged
                                    automatically for future auditing.</p>
                            </div>
                            <div class="desktop__pod --mood-meter">
                                <img src="../assets/image/landing/dw-pictures/pod-mood_meter.png">
                            </div>
                            <div class="desktop__popup --mood-meter">
                                <h6 class="text-primary">Mood Meter Pod</h6>
                                <p class="small">Get live mood responses from agents throughout their shifts. Learn what
                                    your agents are feeling so you can provide a great and happy work environment.
                                    Agents are asked periodically for a mood rating and agents with low scores appear in
                                    the "Needs Support" area of the Mood Meter Pod. This allows managers to pick up
                                    agents when they most need it.</p>
                            </div>
                            <div class="desktop__pod --web">
                                <img src="../assets/image/landing/dw-pictures/pod-web.png">
                            </div>
                            <div class="desktop__popup --web">
                                <h6 class="text-primary">Web Pod</h6>
                                <p class="small">Add an external dashboard to your LiveDesk. With the Web Pod you can
                                    load a website for everyone to see. Any website that support embedding can be
                                    displayed.</p>
                            </div>
                            <div class="desktop__pod --chat">
                                <img src="../assets/image/landing/dw-pictures/pod-chat.png">
                            </div>
                            <div class="desktop__popup --chat">
                                <h6 class="text-primary">Chat Pod</h6>
                                <p class="small">Send messages, images to everyone in a LiveDesk. Emojis and mentions
                                    are supported too. Chat Pods are useful for having conversations between multiple
                                    people inside a LiveDesk.</p>
                            </div>
                            <div class="desktop__pod --threaded-chat-2">
                                <img src="../assets/image/landing/dw-pictures/pod-threaded-chat-2.png">
                            </div>
                            <div class="desktop__popup --threaded-chat-2">
                                <h6 class="text-primary">Threaded Chat Pod</h6>
                                <p class="small">As the LiveDesk is built to be in the know and in the now, it's most
                                    versatile Pod type is for chat. Threaded chats enable SMEs to claim a user question
                                    and to launch a dedicated discussion with that user. These Q&A discussion are
                                    visible to others once it is completed, so all users can learn from one another.
                                    Threaded chats measure time to claim, to answer and pops a rating for both agent and
                                    floorwalker so scores and records on quality, as well as quantity of support
                                    required is tracked for continuous operational and people improvement.</p>
                            </div>
                        </li>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-lg-none mt-4">
            <input class="pod__activator" type="checkbox" name="pod-1" id="pod-1" checked="checked" />
            <input class="pod__activator" type="checkbox" name="pod-2" id="pod-2" />
            <input class="pod__activator" type="checkbox" name="pod-3" id="pod-3" />
            <input class="pod__activator" type="checkbox" name="pod-4" id="pod-4" />
            <input class="pod__activator" type="checkbox" name="pod-5" id="pod-5" />
            <input class="pod__activator" type="checkbox" name="pod-6" id="pod-6" />
            <input class="pod__activator" type="checkbox" name="pod-7" id="pod-7" />
            <input class="pod__activator" type="checkbox" name="pod-8" id="pod-8" />
            <input class="pod__activator" type="checkbox" name="pod-9" id="pod-9" />
            <input class="pod__activator" type="checkbox" name="pod-10" id="pod-10" />

            <h3 class="mx-auto mb-3">Know your pods</h3>
            <div class="pods__track">
                <div id="pod-1">
                    <div class="d-flex justify-content-between">
                        <label class="d-flex justify-content-between cursor-pointer w-100 mb-0" for="pod-1">
                            <h5 class="font-weight-normal">Users Pod</h5>
                            <span class="pod__control pod__control--downwards" for="pod-1"></span>
                        </label>
                    </div>
                    <p class="pod__description">The Users Pod lists users by role (Agents, Floorwalkers, Managers, IT).
                        It shows where everyone is in the digital workplace (virtual bays, breakout rooms) and also in
                        the physical space (@home or in the office). Finally, it shows what status people have from "on
                        break" to "IT downtime". In the meeting room tab you can create on the fly groups to go into
                        virtual rooms for 1-2-1s, team huddles and the like using Teams, Webex or Zoom.</p>
                </div>
                <hr class="bg-dark">
                <div id="pod-2">
                    <div class="d-flex justify-content-between">
                        <label class="d-flex justify-content-between cursor-pointer w-100 mb-0" for="pod-2">
                            <h5 class="font-weight-normal">Image Pod</h5>
                            <span class="pod__control pod__control--downwards"></span>
                        </label>
                    </div>
                    <p class="pod__description">Design your digital workplace by displaying persistent images via JPEG,
                        GIF and PNG formats.</p>
                </div>
                <hr class="bg-dark">
                <div id="pod-3">
                    <div class="d-flex justify-content-between">
                        <label class="d-flex justify-content-between cursor-pointer w-100 mb-0" for="pod-3">
                            <h5 class="font-weight-normal">Poll Pod</h5>
                            <span class="pod__control pod__control--downwards"></span>
                        </label>
                    </div>
                    <p class="pod__description">Getting the pulse from your people quickly is quintessential to the
                        smooth running of your operations. With Poll Pods, you can ask anything, anytime and get answers
                        quickly whether it's overtime, mood or validating debriefs.</p>
                </div>
                <hr class="bg-dark">
                <div id="pod-4">
                    <div class="d-flex justify-content-between">
                        <label class="d-flex justify-content-between cursor-pointer w-100 mb-0" for="pod-4">
                            <h5 class="font-weight-normal">Virtual Bay Scoped Chat Pod</h5>
                            <span class="pod__control pod__control--downwards"></span>
                        </label>
                    </div>
                    <p class="pod__description">Virtual Bays allow you to assign users within a chat Pod to specific
                        areas of work. The virtual bay feature allows you to segregate the workforce (both agents and
                        floorwalkers) into specific workflows so that work activities within one chat window are
                        streamlined. Any Chat Pod, both threaded and non-threaded, can be scoped to virtual bays so that
                        you can customise you LiveDesk to your exact needs.</p>
                </div>
                <hr class="bg-dark">
                <div id="pod-5">
                    <div class="d-flex justify-content-between">
                        <label class="d-flex justify-content-between cursor-pointer w-100 mb-0" for="pod-5">
                            <h5 class="font-weight-normal">Notes Pod</h5>
                            <span class="pod__control pod__control--downwards"></span>
                        </label>
                    </div>
                    <p class="pod__description">You can't rely on email to ensure your teams are in the know about
                        something. The Notes Pod is your friend to ensure the information and communication is seen –and
                        read! With rich text editing as well as link share for quick & easy access to website
                        information.</p>
                </div>
                <hr class="bg-dark">
                <div id="pod-6">
                    <div class="d-flex justify-content-between">
                        <label class="d-flex justify-content-between cursor-pointer w-100 mb-0" for="pod-6">
                            <h5 class="font-weight-normal">Files Pod</h5>
                            <span class="pod__control pod__control--downwards"></span>
                        </label>
                    </div>
                    <p class="pod__description">The Files Pod provides quick & easy access to all must-have reference
                        files. Managers can upload files for Agents to have access to. Read access is logged
                        automatically for future autiting.</p>
                </div>
                <hr class="bg-dark">
                <div id="pod-7">
                    <div class="d-flex justify-content-between">
                        <label class="d-flex justify-content-between cursor-pointer w-100 mb-0" for="pod-7">
                            <h5 class="font-weight-normal">Mood Meter Pod</h5>
                            <span class="pod__control pod__control--downwards" for="pod-7"></span>
                        </label>
                    </div>
                    <p class="pod__description">Get live mood responses from agents throughout their shifts. Learn what
                        your agents are feeling so you can provide a great and happy work environment. Agents are asked
                        periodically for a mood rating and agents with low scores appear in the "Needs Support" area of
                        the Mood Meter Pod. This allows managers to pick up agents when they most need it.</p>
                </div>
                <hr class="bg-dark">
                <div id="pod-8">
                    <div class="d-flex justify-content-between">
                        <label class="d-flex justify-content-between cursor-pointer w-100 mb-0" for="pod-8">
                            <h5 class="font-weight-normal">Web Pod</h5>
                            <span class="pod__control pod__control--downwards"></span>
                        </label>
                    </div>
                    <p class="pod__description">Add an external dashboard to your LiveDesk. With the Web Pod you can
                        load a website for everyone to see. Any website that support embedding can be displayed.</p>
                </div>
                <hr class="bg-dark">
                <div id="pod-9">
                    <div class="d-flex justify-content-between">
                        <label class="d-flex justify-content-between cursor-pointer w-100 mb-0" for="pod-9">
                            <h5 class="font-weight-normal">Chat Pod</h5>
                            <span class="pod__control pod__control--downwards"></span>
                        </label>
                    </div>
                    <p class="pod__description">Send messages and images to everyone in LiveDesk. Emojis and mentions are
                        supported too. Chat Pods are useful for having conversations between multiple people inside a
                        LiveDesk.</p>
                </div>
                <hr class="bg-dark">
                <div id="pod-10">
                    <div class="d-flex justify-content-between">
                        <label class="d-flex justify-content-between cursor-pointer w-100 mb-0" for="pod-10">
                            <h5 class="font-weight-normal">Threaded Chat Pod</h5>
                            <span class="pod__control pod__control--downwards"></span>
                        </label>
                    </div>
                    <p class="pod__description">As the LiveDesk is built to be in the know and in the now, it's most
                        versatile Pod type is for chat. Threaded chats enables SMEs to claim a user question and to
                        launch a dedicated discussion with that user. These Q&A discussion are visible to others once it
                        is completed, so all users can learn from one another. Threaded chats measure time to claim, to
                        answer and pops a rating for both agent and floorwalker so scores and records on quality, as
                        well as quantity of support required is tracked for continuous operational and people
                        improvement.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- DIGITAL WORKPLACE END -->
</template>
<script>
export default {


    mounted() {
        let start = 0;

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (start < 1) {
                        entry.target.classList.add('carousel-start');
                        setTimeout(() => {
                            entry.target.classList.remove('carousel-start');
                            entry.target.classList.add('carousel-motion');
                            start = 1;
                        }, 2500);
                    } else {
                        entry.target.classList.add('carousel-motion');
                    }
                    return;
                } else {
                    start = 0;
                }

                entry.target.classList.remove('carousel-motion');
            });
        }, { threshold: '0.5', });

        observer.observe(this.$refs.carouselContainer);
    },
    data() {
        return {

        };
    }
}
</script>

<style scoped>

@import '../css/anim.css';


@font-face {
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../assets/fonts/HindVadodara-Regular.ttf") format("ttf");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../assets/fonts/HindVadodara-Medium.ttf") format("ttf");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../assets/fonts/HindVadodara-SemiBold.ttf") format("ttf");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* 
html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
}

body {
    overflow-x: hidden;
} */

/*
    * NAVBAR
    */

.navbar {
    position: fixed;
    width: 100%;
    padding: .75rem 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: var(--white);
    color: var(--primary);
}

.navbar-brand {
    display: flex;
    align-items: center;
    color: var(--primary);
}

.navbar-brand:hover {
    color: var(--primary);
}

.navbar-brand>img {
    width: 40px;
}

.nav-item>.btn {
    color: var(--white);
    min-width: 140px;
}

.navbar>.container>.navbar-toggler {
    color: var(--black);
}

.navbar>.container>.navbar-toggler:not([aria-expanded="false"])>.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 50 50' fill='%23000000'%3e%3cpath d='M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z'/%3e%3c/svg%3e")
}

.navbar-toggler>.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%280, 0, 0%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.nav-link {
    color: var(--black);
}

.navbar-toggler * {
    pointer-events: none;
}

@media (max-width: 767.98px) {
    .collapse {
        display: block;
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    }

    .collapse.show {
        max-height: 99em;
        transition: max-height .5s ease-in-out;
    }
}

/*
    * HERO
    */
#hero-gradient-canvas {
    position: absolute;
    width: 100%;
    height: 110%;
    --gradient-color-1: #40b0c3;
    --gradient-color-2: #0C5C6A;
    --gradient-color-3: #b0d9e0;
    --gradient-color-4: #208b9f;
    background: linear-gradient(#0C5C6A, #2AA1B6);
}

.hero-clip {
    position: absolute;
    width: 100%;
    bottom: -17%;
    left: 0;
    right: 0;
}

@media (min-width: 768px) {
    #hero-gradient-canvas {
        height: 110%;
    }

    .hero-clip {
        bottom: -15%;
    }
}

@media (min-width: 992px) {
    #hero-gradient-canvas {
        height: 130%;
    }

    .hero-clip {
        bottom: -32%;
    }
}

/*
    * DIGITAL WORKPLACE INTERACTIVE LAYOUT
    */

.carousel {
    text-align: center;
    padding: 0;
    list-style: none;
    border-radius: 4px;
    position: absolute;
    top: 4.9985390071%;
    left: 8.8992537313%;
    width: 82.4214925373%;
    height: 84.1418439716%;
}

.carousel__track-container {
    /* overflow: hidden; */
}

.carousel__track-container,
.carousel__track {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
}

.carousel__track {
    transition: transform 0.5s ease;
}

.carousel__slide {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    position: absolute;
    opacity: 1;
    /* overflow: hidden; */
    transition: opacity 0.15s linear;
}

.pod__activator {
    display: none;
}

.pod__description {
    display: none;
}

.pod__control,
.benefits__control {
    height: 0.75rem;
    width: 0.75rem;
    display: block;
    cursor: pointer;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: var(--black);
    outline: 0;
    z-index: 3;
    border-radius: 2px;
    transition: opacity .1s;
    margin-top: 0.3rem;
    margin-right: 0.3rem;
}

.pod__control--downwards,
.benefits__control--downwards {
    transform: rotate(135deg);
    transform-origin: center;
    transition: all 0.5s ease-in-out;
}

.pod__control--upwards .benefits__control--upwards {
    transform: rotate(-135deg);
}

.pod__activator:nth-of-type(1):checked~.pods__track #pod-1 .pod__description,
.pod__activator:nth-of-type(2):checked~.pods__track #pod-2 .pod__description,
.pod__activator:nth-of-type(3):checked~.pods__track #pod-3 .pod__description,
.pod__activator:nth-of-type(4):checked~.pods__track #pod-4 .pod__description,
.pod__activator:nth-of-type(5):checked~.pods__track #pod-5 .pod__description,
.pod__activator:nth-of-type(6):checked~.pods__track #pod-6 .pod__description,
.pod__activator:nth-of-type(7):checked~.pods__track #pod-7 .pod__description,
.pod__activator:nth-of-type(8):checked~.pods__track #pod-8 .pod__description,
.pod__activator:nth-of-type(9):checked~.pods__track #pod-9 .pod__description,
.pod__activator:nth-of-type(10):checked~.pods__track #pod-10 .pod__description {
    display: block;
}

.pod__activator:nth-of-type(1):checked~.pods__track #pod-1 .pod__control--downwards,
.pod__activator:nth-of-type(2):checked~.pods__track #pod-2 .pod__control--downwards,
.pod__activator:nth-of-type(3):checked~.pods__track #pod-3 .pod__control--downwards,
.pod__activator:nth-of-type(4):checked~.pods__track #pod-4 .pod__control--downwards,
.pod__activator:nth-of-type(5):checked~.pods__track #pod-5 .pod__control--downwards,
.pod__activator:nth-of-type(6):checked~.pods__track #pod-6 .pod__control--downwards,
.pod__activator:nth-of-type(7):checked~.pods__track #pod-7 .pod__control--downwards,
.pod__activator:nth-of-type(8):checked~.pods__track #pod-8 .pod__control--downwards,
.pod__activator:nth-of-type(9):checked~.pods__track #pod-9 .pod__control--downwards,
.pod__activator:nth-of-type(10):checked~.pods__track #pod-10 .pod__control--downwards {
    transform: translate(0, 50%) rotate(-45deg);
}

.navbar__activator {
    display: none;
}

.desktop__navbar {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    justify-content: space-between;
    padding: 0;
    font-size: 70%;
    cursor: default;
}

@media (min-width: 992px) {
    .desktop__navbar {
        padding: .25rem;
    }
}

@media (min-width: 2040px) {
    .desktop__navbar {
        padding-right: .4rem;
        padding-left: .4rem;
        padding-top: .4rem;
        font-size: 85%;
    }
}

.desktop__dropdown {
    position: absolute;
    background-color: var(--white);
    min-width: 140px;
    top: 100%;
    display: none;
    border-radius: .1rem;
    border: var(--light) solid 1px;
}

#navbar-link-1:hover .desktop__dropdown.--livedesk,
#navbar-link-2:hover .desktop__dropdown.--layouts,
#navbar-link-3:hover .desktop__dropdown.--pods,
#navbar-link-4:hover .desktop__dropdown.--help,
#navbar-link-5:hover .desktop__dropdown.--status,
#navbar-link-6:hover .desktop__dropdown.--profile {
    display: block;
}

@media (min-width: 2040px) {
    .desktop__dropdown {
        top: 90%;
        border-radius: .2rem;
    }
}

.desktop__dropdown li>ul {
    text-transform: capitalize;
    padding: 0;
    white-space: nowrap;
    text-align: left;
    font-size: 80%;
    margin-bottom: .25rem;
}

@media (min-width: 2040px) {
    .desktop__dropdown li>ul {
        font-size: 90%;
    }
}

.desktop__dropdown li>ul span {
    display: inline-block;
}

.desktop__dropdown li {
    padding: .5rem;
}

.highlighted-option {
    cursor: pointer;
}

.highlighted-option.--broadcast:hover~.desktop__popup.--broadcast,
.highlighted-option.--edit-department:hover~.desktop__popup.--edit-department,
.highlighted-option.--admin:hover .desktop__popup.--admin,
.highlighted-option.--new-layout:hover~.desktop__popup.--new-layout,
.highlighted-option.--pods:hover~.desktop__popup.--pods,
.highlighted-option.--help:hover~.desktop__popup.--help,
.highlighted-option.--status:hover~.desktop__popup.--status,
.highlighted-option.--location:hover~.desktop__popup.--location {
    display: block;
    transform: scale(1);
    opacity: 1;
}

.desktop__dropdown.--status {
    left: 50%;
}

.desktop__dropdown.--profile {
    right: 0;
    padding: 0;
}

.desktop__dropdown.--profile span,
.desktop__dropdown.--profile ul {
    text-align: right !important;
}

.desktop__pod {
    position: absolute;
    border-radius: 0.3vw;
    transition: 0.2s ease;
    overflow: hidden;
    z-index: 1;
    opacity: 0;
}

.carousel-start .desktop__pod,
.carousel-motion .desktop__pod {
    opacity: 1;
}

.desktop__pod img {
    width: 100%;
}

@media (min-width: 992px) {

    .carousel-motion .carousel__slide:hover .desktop__pod,
    .carousel-motion .desktop__navbar:hover+.carousel__slide .desktop__pod {
        animation: none;
        opacity: 0.2;
    }

    .desktop__pod:hover {
        transition: 0.2s ease;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
        z-index: 2;
        opacity: 1 !important;
    }

    .desktop__pod:hover+.desktop__popup {
        display: block;
        transform: scale(1);
        opacity: 1;
    }
}

.desktop__pod.--users {
    left: 0;
    top: 5.2%;
    width: 14.9%;
    height: 64.55%;
}

.carousel-start .desktop__pod.--users {
    animation: pod-users-start 2s ease 1;
}

.carousel-motion .desktop__pod.--users {
    animation: pod-users-motion 5s .5s ease-in-out infinite;
}

.desktop__pod.--image {
    left: 0;
    top: 70.75%;
    width: 14.9%;
    height: 28.25%;
    display: flex;
    align-items: center;
    background-color: var(--gray-dark);
}

.carousel-start .desktop__pod.--image {
    animation: pod-image-start 2s ease 1;
}

.carousel-motion .desktop__pod.--image {
    animation: pod-image-motion 5s .5s ease-in-out infinite;
}

.desktop__pod.--image img {
    width: 100%;
    object-fit: cover;
}

.desktop__pod.--poll {
    left: 15.4%;
    top: 5.2%;
    width: 22.6%;
    height: 38.1%;
    background-color: var(--gray-dark);
}

.carousel-start .desktop__pod.--poll {
    animation: pod-poll-start 2s ease 1;
}

.carousel-motion .desktop__pod.--poll {
    animation: pod-poll-motion 5s .5s ease-in-out infinite;
}

.desktop__pod.--threaded-chat-1 {
    left: 15.4%;
    top: 44.3%;
    width: 22.6%;
    height: 54.7%;
    background-color: var(--white);
}

.carousel-start .desktop__pod.--threaded-chat-1 {
    animation: pod-tchat-1-start 2s ease 1;
}

.carousel-motion .desktop__pod.--threaded-chat-1 {
    animation: pod-tchat-1-motion 5s .5s ease-in-out infinite;
}

.desktop__pod.--notes {
    left: 38.5%;
    top: 5.2%;
    width: 22.8%;
    height: 63%;
    background-color: var(--light);
}

.carousel-start .desktop__pod.--notes {
    animation: pod-notes-start 2s ease 1;
}

.carousel-motion .desktop__pod.--notes {
    animation: pod-notes-motion 5s .5s ease-in-out infinite;
}

.desktop__pod.--files {
    left: 38.5%;
    top: 69.2%;
    width: 22.8%;
    height: 29.8%;
    background-color: var(--gray-dark);
}

.carousel-start .desktop__pod.--files {
    animation: pod-files-start 2s ease 1;
}

.carousel-motion .desktop__pod.--files {
    animation: pod-files-motion 5s .5s ease-in-out infinite;
}

.desktop__pod.--mood-meter {
    left: 62.1%;
    top: 5.2%;
    width: 18.6%;
    height: 57.8%;
}

.carousel-start .desktop__pod.--mood-meter {
    animation: pod-mood-meter-start 2s ease 1;
}

.carousel-motion .desktop__pod.--mood-meter {
    animation: pod-mood-meter-motion 5s .5s ease-in-out infinite;
}

.desktop__pod.--web {
    left: 62.1%;
    top: 63.9%;
    width: 18.6%;
    height: 35.1%;
}

.carousel-start .desktop__pod.--web {
    animation: pod-web-start 2s ease 1;
}

.carousel-motion .desktop__pod.--web {
    animation: pod-web-motion 5s .5s ease-in-out infinite;
}

.desktop__pod.--chat {
    left: 81.4%;
    top: 5.2%;
    width: 18.3%;
    height: 35.7%;
    background-color: var(--white);
}

.carousel-start .desktop__pod.--chat {
    animation: pod-chat-start 2s ease 1;
}

.carousel-motion .desktop__pod.--chat {
    animation: pod-chat-motion 5s .5s ease-in-out infinite;
}

.desktop__pod.--threaded-chat-2 {
    left: 81.4%;
    top: 41.9%;
    width: 18.3%;
    height: 57.2%;
    background-color: var(--white);
}

.carousel-start .desktop__pod.--threaded-chat-2 {
    animation: pod-tchat-2-start 2s ease 1;
}

.carousel-motion .desktop__pod.--threaded-chat-2 {
    animation: pod-tchat-2-motion 5s .5s ease-in-out infinite;
}

.desktop__popup {
    position: absolute;
    text-align: left;
    color: var(--white);
    background-color: var(--dark);
    border-radius: 4px;
    padding: 1rem;
    width: 30%;
    z-index: 2;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    transform: scale(0.9);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease;
}

.desktop__popup.--highlight {
    width: 150%;
    font-size: 110%;
}

.desktop__popup.--broadcast,
.desktop__popup.--admin,
.desktop__popup.--new-layout,
.desktop__popup.--pods,
.desktop__popup.--help,
.desktop__popup.--status {
    top: 0%;
    left: 102%;
}

.desktop__popup.--edit-department {
    top: 25%;
    left: 102%;
}

.desktop__popup.--location {
    right: 102%;
    top: 0;
}

.desktop__popup.--users {
    top: 5.2%;
    left: 15.4%;
}

.desktop__popup.--image {
    top: 70.75%;
    left: 15.4%;
}

.desktop__popup.--poll {
    top: 5.2%;
    left: 38.5%;
}

.desktop__popup.--threaded-chat-1 {
    top: 44.3%;
    left: 38.5%;
}

.desktop__popup.--notes {
    top: 5.2%;
    left: 61.6%;
}

.desktop__popup.--notes {
    top: 5.2%;
    left: 61.8%;
}

.desktop__popup.--files {
    top: 69.2%;
    left: 61.8%;
}

.desktop__popup.--mood-meter {
    top: 5.2%;
    left: 31.6%;
}

.desktop__popup.--web {
    top: 63.9%;
    left: 31.6%;
}

.desktop__popup.--chat {
    top: 5.2%;
    left: 50.8%;
}

.desktop__popup.--threaded-chat-2 {
    top: 41.9%;
    left: 50.8%;
}

@keyframes pod-users-start {
    0% {
        left: -140%;
        top: 5.2%;
        height: 64.55%;
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        left: 0%;
        top: 5.2%;
        height: 64.55%;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pod-users-motion {
    0% {
        left: 0%;
        top: 5.2%;
        height: 64.55%;
    }

    10% {
        left: 0%;
        top: 5.2%;
        height: 64.55%;
    }

    20% {
        left: 46.4%;
        top: 34.45%;
        height: 64.55%;
    }

    40% {
        left: 46.4%;
        top: 34.45%;
        height: 64.55%;
    }

    50% {
        left: 23.6%;
        top: 34.45%;
        height: 64.55%;
    }

    70% {
        left: 23.6%;
        top: 34.45%;
        height: 64.55%;
    }

    80% {
        left: 0%;
        top: 5.2%;
        height: 64.55%;
    }

    100% {
        left: 0%;
        top: 5.2%;
        height: 64.55%;
    }
}

@keyframes pod-image-start {
    0% {
        left: -140%;
        top: 70.75%;
        height: 28.25%;
        width: 14.9%;
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        left: 0%;
        top: 70.75%;
        height: 28.25%;
        width: 14.9%;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pod-image-motion {
    0% {
        left: 0%;
        top: 70.75%;
        height: 28.25%;
        width: 14.9%;
    }

    10% {
        left: 0%;
        top: 70.75%;
        height: 28.25%;
        width: 14.9%;
    }

    20% {
        left: 46.4%;
        top: 5.2%;
        height: 28.25%;
        width: 14.9%;
    }

    40% {
        left: 46.4%;
        top: 5.2%;
        height: 28.25%;
        width: 14.9%;
    }

    50% {
        left: 0%;
        top: 5.2%;
        height: 28.25%;
        width: 38.5%;
    }

    70% {
        left: 0%;
        top: 5.2%;
        height: 28.25%;
        width: 38.5%;
    }

    80% {
        left: 0%;
        top: 70.75%;
        height: 28.25%;
        width: 14.9%;
    }

    100% {
        left: 0%;
        top: 70.75%;
        height: 28.25%;
        width: 14.9%;
    }
}

@keyframes pod-poll-start {
    0% {
        left: 15.4%;
        top: -145.2%;
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        left: 15.4%;
        top: 5.2%;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pod-poll-motion {
    0% {
        left: 15.4%;
        top: 5.2%;
    }

    10% {
        left: 15.4%;
        top: 5.2%;
    }

    20% {
        left: 23.2%;
        top: 5.2%;
    }

    40% {
        left: 23.2%;
        top: 5.2%;
    }

    50% {
        left: 38.9%;
        top: 60.9%
    }

    70% {
        left: 38.9%;
        top: 60.9%;
    }

    80% {
        left: 15.4%;
        top: 5.2%;
    }

    100% {
        left: 15.4%;
        top: 5.2%;
    }
}

@keyframes pod-tchat-1-start {
    0% {
        left: 15.4%;
        top: 184.3%;
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        left: 15.4%;
        top: 44.3%;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pod-tchat-1-motion {
    0% {
        left: 15.4%;
        top: 44.3%;
    }

    10% {
        left: 15.4%;
        top: 44.3%;
    }

    20% {
        left: 23.2%;
        top: 44.3%;
    }

    40% {
        left: 23.2%;
        top: 44.3%;
    }

    50% {
        left: 38.9%;
        top: 5.2%;
    }

    70% {
        left: 38.9%;
        top: 5.2%;
    }

    80% {
        left: 15.4%;
        top: 44.3%;
    }

    100% {
        left: 15.4%;
        top: 44.3%;
    }
}

@keyframes pod-notes-start {
    0% {
        left: 38.5%;
        top: -145.2%;
        height: 63%;
        width: 22.8%;
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        left: 38.5%;
        top: 5.2%;
        height: 63%;
        width: 22.8%;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pod-notes-motion {
    0% {
        left: 38.5%;
        top: 5.2%;
        height: 63%;
        width: 22.8%;
    }

    10% {
        left: 38.5%;
        top: 5.2%;
        height: 63%;
        width: 22.8%;
    }

    20% {
        left: 0%;
        top: 5.2%;
        height: 29.8%;
        width: 22.8%;
    }

    40% {
        left: 0%;
        top: 5.2%;
        height: 29.8%;
        width: 22.8%;
    }

    50% {
        left: 0%;
        top: 34.45%;
        height: 30.5%;
        width: 23.1%;
    }

    70% {
        left: 0%;
        top: 34.45%;
        height: 30.5%;
        width: 23.1%;
    }

    80% {
        left: 38.5%;
        top: 5.2%;
        height: 63%;
        width: 22.8%;
    }

    100% {
        left: 38.5%;
        top: 5.2%;
        height: 63%;
        width: 22.8%;
    }
}

@keyframes pod-files-start {
    0% {
        left: 38.5%;
        top: 209.2%;
        height: 29.8%;
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        left: 38.5%;
        top: 69.2%;
        height: 29.8%;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pod-files-motion {
    0% {
        left: 38.5%;
        top: 69.2%;
        height: 29.8%;
    }

    10% {
        left: 38.5%;
        top: 69.2%;
        height: 29.8%;
    }

    20% {
        left: 0%;
        top: 36.1%;
        height: 63%;
    }

    40% {
        left: 0%;
        top: 36.1%;
        height: 63%;
    }

    50% {
        left: 0%;
        top: 66.1%;
        height: 33%;
    }

    70% {
        left: 0%;
        top: 66.1%;
        height: 33%;
    }

    80% {
        left: 38.5%;
        top: 69.2%;
        height: 29.8%;
    }

    100% {
        left: 38.5%;
        top: 69.2%;
        height: 29.8%;
    }
}

@keyframes pod-mood-meter-start {
    0% {
        left: 62.1%;
        top: -145.2%;
        width: 18.6%;
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        left: 62.1%;
        top: 5.2%;
        width: 18.6%;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pod-mood-meter-motion {
    0% {
        left: 62.1%;
        top: 5.2%;
        width: 18.6%;
    }

    10% {
        left: 62.1%;
        top: 5.2%;
        width: 18.6%;
    }

    20% {
        left: 81.4%;
        top: 5.2%;
        width: 18.3%;
    }

    40% {
        left: 81.4%;
        top: 5.2%;
        width: 18.3%;
    }

    50% {
        left: 62.1%;
        top: 41.3%;
        width: 18.6%;
    }

    70% {
        left: 62.1%;
        top: 41.3%;
        width: 18.6%;
    }

    80% {
        left: 62.1%;
        top: 5.2%;
        width: 18.6%;
    }

    100% {
        left: 62.1%;
        top: 5.2%;
        width: 18.6%;
    }
}

@keyframes pod-web-start {
    0% {
        left: 62.1%;
        top: 203.9%;
        width: 18.6%;
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        left: 62.1%;
        top: 63.9%;
        width: 18.6%;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pod-web-motion {
    0% {
        left: 62.1%;
        top: 63.9%;
        width: 18.6%;
    }

    10% {
        left: 62.1%;
        top: 63.9%;
        width: 18.6%;
    }

    20% {
        left: 62.1%;
        top: 5.2%;
        width: 18.6%;
    }

    40% {
        left: 62.1%;
        top: 5.2%;
        width: 18.6%;
    }

    50% {
        left: 81.4%;
        top: 63.9%;
        width: 18.3%;
    }

    70% {
        left: 81.4%;
        top: 63.9%;
        width: 18.3%;
    }

    80% {
        left: 62.1%;
        top: 63.9%;
        width: 18.6%;
    }

    100% {
        left: 62.1%;
        top: 63.9%;
        width: 18.6%;
    }
}

@keyframes pod-chat-start {
    0% {
        left: 221.4%;
        top: 5.2%;
        width: 18.3%;
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        left: 81.4%;
        top: 5.2%;
        width: 18.3%;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pod-chat-motion {
    0% {
        left: 81.4%;
        top: 5.2%;
        width: 18.3%;
    }

    10% {
        left: 81.4%;
        top: 5.2%;
        width: 18.3%;
    }

    20% {
        left: 81.4%;
        top: 63.4%;
        width: 18.3%;
    }

    40% {
        left: 81.4%;
        top: 63.4%;
        width: 18.3%;
    }

    50% {
        left: 62.1%;
        top: 5.2%;
        width: 18.6%;
    }

    70% {
        left: 62.1%;
        top: 5.2%;
        width: 18.6%;
    }

    80% {
        left: 81.4%;
        top: 5.2%;
        width: 18.3%;
    }

    100% {
        left: 81.4%;
        top: 5.2%;
        width: 18.3%;
    }
}

@keyframes pod-tchat-2-start {
    0% {
        left: 221.4%;
        top: 41.9%;
        width: 18.3%;
        height: 57.2%;
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        left: 81.4%;
        top: 41.9%;
        width: 18.3%;
        height: 57.2%;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pod-tchat-2-motion {
    0% {
        left: 81.4%;
        top: 41.9%;
        width: 18.3%;
        height: 57.2%;
    }

    10% {
        left: 81.4%;
        top: 41.9%;
        width: 18.3%;
        height: 57.2%;
    }

    20% {
        left: 62.1%;
        top: 41.5%;
        width: 18.6%;
        height: 57.5%;
    }

    40% {
        left: 62.1%;
        top: 41.5%;
        width: 18.6%;
        height: 57.5%;
    }

    50% {
        left: 81.4%;
        top: 5.2%;
        width: 18.3%;
        height: 57.4%;
    }

    70% {
        left: 81.4%;
        top: 5.2%;
        width: 18.3%;
        height: 57.4%;
    }

    80% {
        left: 81.4%;
        top: 41.9%;
        width: 18.3%;
        height: 57.2%;
    }

    100% {
        left: 81.4%;
        top: 41.9%;
        width: 18.3%;
        height: 57.2%;
    }
}

/*
    * START TRIAL
    */

#trial-gradient-canvas {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    --gradient-color-1: #F271A2;
    --gradient-color-2: #E82F59;
    --gradient-color-3: #D5DBE0;
    --gradient-color-4: #FF6689;
    background: linear-gradient(#E82F59, #FF6689);
}

.trial-image {
    position: absolute;
    top: -100%;
    right: -5%;
    width: 120%;
}

/*
    * BENEFITS
    */

.benefits-type {
    padding: 1rem;
    color: var(--white);
    border-radius: 1.5rem 1.5rem 1.5rem 0;
}

.benefits-type.--primary {
    background-color: var(--primary);
}

.benefits-type.--dark {
    background-color: var(--dark-cyan);
}

.benefits-type.--pink {
    background-color: var(--pink);
}

.benefit-wrapper {
    margin-top: 2rem;
    margin-left: .25rem;
}

.benefits-title {
    display: flex;
    justify-content: space-between;
    color: var(--dark-cyan);
    font-weight: bolder;
}

.benefits-title.--primary {
    color: var(--primary);
}

.benefits-title.--pink {
    color: var(--pink);
}

.benefits-description {
    color: var(--dark-cyan);
}

.benefits__activator {
    display: none;
}

.benefits__control {
    margin-right: 0.5rem;
}

@media (max-width: 992px) {
    .benefits-title {
        cursor: pointer;
    }

    .benefits__info {
        display: none;
    }

    .benefits__activator:nth-of-type(1):checked~div .benefit-wrapper#benefit-1 .benefits__info,
    .benefits__activator:nth-of-type(2):checked~div .benefit-wrapper#benefit-2 .benefits__info,
    .benefits__activator:nth-of-type(3):checked~div .benefit-wrapper#benefit-3 .benefits__info,
    .benefits__activator:nth-of-type(4):checked~div .benefit-wrapper#benefit-4 .benefits__info,
    .benefits__activator:nth-of-type(5):checked~div .benefit-wrapper#benefit-5 .benefits__info,
    .benefits__activator:nth-of-type(6):checked~div .benefit-wrapper#benefit-6 .benefits__info,
    .benefits__activator:nth-of-type(7):checked~div .benefit-wrapper#benefit-7 .benefits__info,
    .benefits__activator:nth-of-type(8):checked~div .benefit-wrapper#benefit-8 .benefits__info,
    .benefits__activator:nth-of-type(9):checked~div .benefit-wrapper#benefit-9 .benefits__info,
    .benefits__activator:nth-of-type(10):checked~div .benefit-wrapper#benefit-10 .benefits__info,
    .benefits__activator:nth-of-type(11):checked~div .benefit-wrapper#benefit-11 .benefits__info,
    .benefits__activator:nth-of-type(12):checked~div .benefit-wrapper#benefit-12 .benefits__info {
        display: block;
    }

    .benefits__activator:nth-of-type(1):checked~div .benefit-wrapper#benefit-1 .benefits__control--downwards,
    .benefits__activator:nth-of-type(2):checked~div .benefit-wrapper#benefit-2 .benefits__control--downwards,
    .benefits__activator:nth-of-type(3):checked~div .benefit-wrapper#benefit-3 .benefits__control--downwards,
    .benefits__activator:nth-of-type(4):checked~div .benefit-wrapper#benefit-4 .benefits__control--downwards,
    .benefits__activator:nth-of-type(5):checked~div .benefit-wrapper#benefit-5 .benefits__control--downwards,
    .benefits__activator:nth-of-type(6):checked~div .benefit-wrapper#benefit-6 .benefits__control--downwards,
    .benefits__activator:nth-of-type(7):checked~div .benefit-wrapper#benefit-7 .benefits__control--downwards,
    .benefits__activator:nth-of-type(8):checked~div .benefit-wrapper#benefit-8 .benefits__control--downwards,
    .benefits__activator:nth-of-type(9):checked~div .benefit-wrapper#benefit-9 .benefits__control--downwards,
    .benefits__activator:nth-of-type(10):checked~div .benefit-wrapper#benefit-10 .benefits__control--downwards,
    .benefits__activator:nth-of-type(11):checked~div .benefit-wrapper#benefit-11 .benefits__control--downwards,
    .benefits__activator:nth-of-type(12):checked~div .benefit-wrapper#benefit-12 .benefits__control--downwards {
        transform: translate(0, 50%) rotate(-45deg);
    }
}

/*
    * QUOTES
    */

.quote-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    width: 80%;
}

.quote-image {
    position: relative;
    filter: grayscale(100%) brightness(80%);
    border-radius: 50% 50% 1rem;
    width: 140px;
    height: 140px;
    object-fit: cover;
}

.quote-company {
    width: 160px;
}

.overlay-wrapper {
    position: relative;
}

.primary-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--info);
    z-index: 10;
    mix-blend-mode: color;
    border-radius: 50% 50% 1rem;
}

@media (min-width: 768px) {
    .quote-image {
        width: 200px;
        height: 200px;
    }

    .quote-wrapper {
        flex-direction: row;
    }

    .quote-company {
        width: 180px;
    }
}

.carousel__activator {
    display: none;
}

.carousel__activator:nth-of-type(1):checked~#quote-1,
.carousel__activator:nth-of-type(1):checked~#quote-2,
.carousel__activator:nth-of-type(1):checked~#quote-3,
.carousel__activator:nth-of-type(1):checked~#quote-4 {
    transform: translateX(0%);
}

.carousel__activator:nth-of-type(1):checked~.carousel__controls#quote-controls-2,
.carousel__activator:nth-of-type(1):checked~.carousel__controls#quote-controls-3,
.carousel__activator:nth-of-type(1):checked~.carousel__controls#quote-controls-4 {
    display: none;
}

.carousel__activator:nth-of-type(1):checked~.carousel__controls:nth-of-type(1) {
    display: block;
    opacity: 1;
}

.carousel__activator:nth-of-type(2):checked~#quote-1,
.carousel__activator:nth-of-type(2):checked~#quote-2,
.carousel__activator:nth-of-type(2):checked~#quote-3,
.carousel__activator:nth-of-type(2):checked~#quote-4 {
    transform: translateX(-100%);
}

.carousel__activator:nth-of-type(2):checked~.carousel__controls#quote-controls-1,
.carousel__activator:nth-of-type(2):checked~.carousel__controls#quote-controls-3,
.carousel__activator:nth-of-type(2):checked~.carousel__controls#quote-controls-4 {
    display: none;
}

.carousel__activator:nth-of-type(2):checked~.carousel__controls:nth-of-type(2) {
    display: block;
    opacity: 1;
}

.carousel__activator:nth-of-type(3):checked~#quote-1,
.carousel__activator:nth-of-type(3):checked~#quote-2,
.carousel__activator:nth-of-type(3):checked~#quote-3,
.carousel__activator:nth-of-type(3):checked~#quote-4 {
    transform: translateX(-200%);
}

.carousel__activator:nth-of-type(3):checked~.carousel__controls#quote-controls-1,
.carousel__activator:nth-of-type(3):checked~.carousel__controls#quote-controls-2,
.carousel__activator:nth-of-type(3):checked~.carousel__controls#quote-controls-4 {
    display: none;
}

.carousel__activator:nth-of-type(3):checked~.carousel__controls:nth-of-type(3) {
    display: block;
    opacity: 1;
}

.carousel__activator:nth-of-type(4):checked~#quote-1,
.carousel__activator:nth-of-type(4):checked~#quote-2,
.carousel__activator:nth-of-type(4):checked~#quote-3,
.carousel__activator:nth-of-type(4):checked~#quote-4 {
    transform: translateX(-300%);
}

.carousel__activator:nth-of-type(4):checked~.carousel__controls#quote-controls-1,
.carousel__activator:nth-of-type(4):checked~.carousel__controls#quote-controls-2,
.carousel__activator:nth-of-type(4):checked~.carousel__controls#quote-controls-3 {
    display: none;
}

.carousel__activator:nth-of-type(4):checked~.carousel__controls:nth-of-type(3) {
    display: block;
    opacity: 1;
}

.carousel__control {
    height: 2rem;
    width: 2rem;
    margin-top: -1rem;
    top: 50%;
    position: absolute;
    display: block;
    cursor: pointer;
    border-width: 5px 5px 0 0;
    border-style: solid;
    border-color: #363F47;
    opacity: 0.5;
    outline: 0;
    z-index: 3;
    border-radius: 2px;
    transition: opacity .1s;
}

.carousel__control:hover {
    opacity: 1;
}

.carousel__control--backward {
    left: 1rem;
    transform: rotate(-135deg);
}

.carousel__control--forward {
    right: 1rem;
    transform: rotate(45deg);
}

/*
    * FOOTER 
    */

#contact-gradient-canvas {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    --gradient-color-1: #40b0c3;
    --gradient-color-2: #0C5C6A;
    --gradient-color-3: #b0d9e0;
    --gradient-color-4: #208b9f;
    background: linear-gradient(#0C5C6A, #2AA1B6);
}

/*
    * CARDS
    */

@media (max-width: 991.98px) {
    .card-deck {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        overflow-x: scroll;
        overflow-y: visible;
        margin-left: 0;
        margin-right: 0;
        padding-left: 9px;
        padding-right: 9px;
    }

    .card-deck:after {
        content: '';
        width: 1px;
        height: 1px;
        flex-grow: 1;
        flex-shrink: 0;
        margin: 0;
    }
}

@media (min-width: 991.98px) {
    .card-deck {
        margin-right: 0;
        margin-left: 0;
    }

    .card-deck .card {
        margin-right: 0;
        margin-left: 0;
    }
}

@media (min-width: 576px) {
    .card-deck .card {
        margin-right: 0;
        margin-left: 0;
    }
}

.card-clear {
    border: none;
}

.card-clear,
.card-clear .card-body {
    padding-left: 0;
    padding-right: 0;
}

.card-body {
    background-color: var(--light);
    display: flex;
    flex-direction: column;
}

.card-clear,
.card-clear .card-header {
    background: none;
}

.card {
    border-radius: 0;
    border: none;
    background-color: var(--light);
}

@media (max-width: 991.98px) {
    .card-border {
        border-radius: 1rem;
        border: solid 8px var(--gray-dark);
    }

    .card-border--pink {
        border: solid 8px var(--pink);
        z-index: 10;
    }

    .card-border--blue {
        border: solid 8px var(--blue);
        z-index: 10;
    }
}

@media (min-width: 991.98px) {
    .card-md-border {
        border-radius: 1rem;
        border: solid 8px var(--gray-dark);
    }

    .card-border--dark-left {
        margin-right: -28px;
        padding-right: 40px;
        padding-left: 20px;
        z-index: 5;
    }

    .card-border--dark-right {
        margin-left: -32px;
        padding-left: 40px;
    }

    .card-border--flat-right {
        border-radius: 1rem 0 0 1rem;
        margin-right: -16px;
    }
}

.card-border--primary {
    border: solid 8px var(--primary);
    z-index: 10;
}

.card .card-header {
    background-color: transparent;
    border-bottom: none;
    min-height: 6rem;
    color: var(--black);
}

@media (max-width: 991.98px) {
    .card {
        flex-grow: 1;
        flex-shrink: 0;
        min-width: 65vw;
    }
}

.card-label {
    white-space: nowrap;
    white-space: normal;
    height: 1.5rem;
}

/*
    * CHAT
    */

.chat {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;
}

.chat:before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F2F3F4;
}

@media (min-width: 768px) {
    .chat:before {
        left: 15px;
        height: calc(100% - 2rem);
        width: 62.5%;
        border-radius: 2px;
    }

    .flex-md-row-reverse .chat:before {
        left: auto;
        right: 15px;
    }
}

@media (max-width: 767.98px) {
    .row:nth-last-child(even) .chat:before {
        display: none;
    }
}

/*
    * BUTTONS
    */

.btn-white {
    color: var(--pink);
    background-color: var(--white);
    border-color: var(--white);
}

.btn-outline-white {
    color: var(--white);
    border-color: var(--white);
}

.btn-outline-white:hover {
    color: var(--black);
    background-color: rgba(255, 255, 255, 0.5);
}

.btn-lg small {
    font-size: 1rem;
}

.btn-link {
    font-weight: 700;
}

.btn-text-primary {
    color: #2AA1B6;
}

.btn-text-primary:hover {
    color: #52CBE0;
    text-decoration: none;
}

.btn-text-blue {
    color: #255A91;
}

.btn-text-blue:hover {
    color: #5298E0;
    text-decoration: none;
}

.btn-text-pink {
    color: #DD3877;
}

.btn-text-pink:hover {
    color: #E05288;
    text-decoration: none;
}

/*
    * GRADIENT BACKGROUNDS
    */

.bg-grad-grey-inverted {
    background: linear-gradient(#FFFFFF, #F2F3F4);
}

.bg-grad-blue-indigo {
    background: linear-gradient(#0C5C6A, #2AA1B6);
}

.bg-grad-grey {
    position: relative;
    z-index: 0;
}

.bg-grad-grey:before {
    background: linear-gradient(#F8F8F8, #FFFFFF);
    z-index: -1;
    position: absolute;
    content: '';
    width: 100%;
    top: 0;
    height: 26rem;
}

.bg-grad-light-blue {
    position: relative;
    z-index: 0;
}

.bg-grad-light-blue:before {
    background: linear-gradient(#F4FDFF, #FFFFFF);
    z-index: -1;
    position: absolute;
    content: '';
    width: 100%;
    top: 0;
    height: 26rem;
}

.bg-grad-pink {
    position: relative;
    z-index: 0;
}

.bg-grad-pink:before {
    background: linear-gradient(#FFFAFC, #FFFFFF);
    z-index: -1;
    position: absolute;
    content: '';
    width: 100%;
    top: 0;
    height: 26rem;
}

.bg-grad-yellow {
    position: relative;
    z-index: 0;
}

.bg-grad-yellow:before {
    background: linear-gradient(#FFFCF5, #FFFFFF);
    z-index: -1;
    position: absolute;
    content: '';
    width: 100%;
    top: 0;
    height: 26rem;
}

.bg-grad-blue {
    position: relative;
    z-index: 0;
}

.bg-grad-blue:before {
    background: linear-gradient(#F2F9FF, #FFFFFF);
    z-index: -1;
    position: absolute;
    content: '';
    width: 100%;
    top: 0;
    height: 26rem;
}

/*
    * UTILITIES
    */

.transition-transform {
    transition: transform 1s ease-in-out;
}

.invertY {
    transform: rotate(180deg);
}

.rounded-xl {
    border-radius: 1rem;
}

.font-weight-medium {
    font-weight: 500 !important;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-visible {
    overflow-y: visible;
}

.overflow-x-scroll {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.overflow-x-scroll::-webkit-scrollbar {
    display: none;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.cursor-pointer {
    cursor: pointer;
}

.border-none {
    border: none;
}

.border-dark-cyan {
    border-left: 1px solid rgba(12, 92, 106, 0.25);
}

.border-cyan {
    border-left: 1px solid rgba(42, 161, 182, 0.25);
}

.border-pink {
    border-left: 1px solid rgba(221, 56, 119, 0.25);
}

.bg-dark-cyan {
    background-color: var(--dark-cyan);
}

.opacity-25 {
    opacity: 25%;
}

.index-1 {
    z-index: 1;
}

.index-9999 {
    z-index: 9999;
}

.w-icon-small {
    width: 24px;
    height: 24px;
}

.my-0-5 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
}

.-my-1 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
}

html {
    scroll-behavior: smooth;
}

.min-w-100 {
    min-width: 100%;
}

.w-100-vw {
    width: 100vw !important;
}

.w-60 {
    width: 60% !important;
}

.w-90-vw {
    width: 90vw !important;
}

@media (min-width: 576px) {
    .w-sm-50 {
        width: 50% !important;
    }

    .w-sm-auto {
        width: auto !important;
    }

    .w-sm-80 {
        width: 80% !important;
    }
}

.pt-15 {
    padding-top: 7.5rem !important;
}

@media (min-width: 768px) {
    .pt-md-20 {
        padding-top: 10rem !important;
    }
}

@media (min-width: 992px) {
    .w-lg-auto {
        width: auto !important;
    }

    .w-lg-60 {
        width: 60% !important;
    }

    .w-lg-100 {
        width: 100% !important;
    }
}

@media (min-width: 2040px) {
    .w-xxl-60-vw {
        width: 60vw !important;
    }
}

.h-20 {
    height: 20% !important;
}

.h-95 {
    height: 92% !important;
}

.h-centered {
    transform: translateX(-50%);
    margin-left: 50%;
}

.top-12 {
    top: 6rem;
}

.right-4 {
    right: 2rem;
}

.ml-0-5 {
    margin-left: .25rem;
}

.mt-0-5 {
    margin-top: .25rem;
}

.ml-1-5 {
    margin-left: .75rem;
}

.pl-2-5 {
    padding-left: 1.25rem;
}

.mt-9-5 {
    margin-top: 5.1rem !important;
}

.mt-14 {
    margin-top: 7rem !important;
}

.lead {
    line-height: 1.2;
}

.leading-normal {
    line-height: 1;
}

.leading-loose br {
    content: '';
    height: 0.6rem;
    display: block;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.strong-primary strong {
    color: #2AA1B6;
}

.typescale-lg {
    font-size: 1.25rem;
    line-height: 1.4;
}

.typescale-xs {
    font-size: 0.75rem;
    line-height: 1.3;
}

.bg-gray-100 {
    background: var(--light);
}

.bg-gray-200 {
    background: #D5DBE0;
}

.bg-gray-400 {
    background: #7D93A7;
}

.bg-pink {
    background: #DD3877;
}

.bg-blue {
    background: #255A91;
}

.bg-indigo {
    background: #5298E0;
}

.bg-black {
    background-color: #20252a !important;
}

.bg-yellow {
    background-color: #FAF4E6;
}

.text-pink {
    color: var(--pink);
}

.text-blue {
    color: #255A91;
}

.text-indigo {
    color: #5298E0;
}

.col-auto {
    flex: 0 0 auto !important;
    width: auto;
    max-width: 100%;
}

small {
    font-weight: inherit;
}

.floating {
    animation: floatingX 6s ease-in-out infinite;
}

.row:nth-child(2n) div .floating {
    animation-name: floatingXY;
}

.row:nth-child(3n) div .floating {
    animation-name: floatingY;
}

@keyframes floatingY {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-8px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes floatingX {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(-8px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes floatingXY {
    0% {
        transform: translate(0px, 0px);
    }

    50% {
        transform: translate(4px, 4px);
    }

    100% {
        transform: translate(0px, 0px);
    }
}



/*******************************************************************************************************/



/* LR SPACERS START */
.spacer1 {
    height: 1px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer2 {
    height: 2px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer3 {
    height: 3px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer4 {
    height: 4px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer5 {
    height: 5px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer10 {
    height: 10px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer15 {
    height: 15px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer20 {
    height: 20px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer25 {
    height: 25px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer30 {
    height: 30px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer35 {
    height: 35px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer40 {
    height: 40px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer45 {
    height: 45px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer50 {
    height: 50px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer60 {
    height: 60px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer70 {
    height: 70px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer80 {
    height: 80px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer90 {
    height: 90px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer100 {
    height: 100px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer150 {
    height: 150px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer200 {
    height: 200px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer250 {
    height: 250px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer300 {
    height: 300px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer350 {
    height: 350px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer400 {
    height: 400px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer450 {
    height: 450px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer500 {
    height: 500px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

/* LR SPACERS END */



/* FEATURE COMPARISON TABLE SECTION START */
#feWrap {
    padding-top: 0;
    padding-bottom: 70px;
}

#feWrap h2 {
    margin-top: 0;
    margin-bottom: 80px !important;
    padding-top: 80px;
}

#feWrap .header-row {
    text-align: center;
    margin-bottom: 0;
}

#feWrap .header-row div:nth-child(1) {
    text-align: left;
}

#feWrap .content-row {
    text-align: center;
}

#feWrap .content-row div:nth-child(1) {
    text-align: left;
    padding-bottom: 8px;
    padding-top: 6px;
}

#feWrap .content-row div {
    border-bottom: 2px solid rgba(29, 34, 38, 0.1);
}

#feWrap .feHeaderTitle {
    font-weight: 700;
}

#feWrap .feTitle {
    font-weight: 700;
}

#feWrap .feIcon {
    display: block;
    padding: 5px 0 0 0;
}

#feWrap .feNumb {
    display: block;
    padding: 5px 0 0 0;
}

#feWrap .appendix-num {
    display: inline-block;
    position: relative;
    width: 15px;
    font-size: 14px;
    font-style: italic;
    padding: 0 0 0 0;
}

#feWrap .appendix-ol {
    margin-top: 30px;
    padding: 0;
}

#feWrap .appendix-ol li {
    font-size: 14px;
    font-style: italic;
}

#feWrap .fa-check {
    color: #2bcf4c;
}

#feWrap .fa-times {
    color: #d04a4a;
}

#feWrap .titleFeature {}

#feWrap .titleLivedesk {
    background-color: #2AA1B6;
    color: #fff;
    padding: 20px 0 20px 0;
    border-top-right-radius: 40px;
    text-align: center !important;
    border: 1px solid #e1e1e1;
    border-bottom-color: #fff;
}

#feWrap .titleTeams {
    background-color: #4B53BC;
    color: #fff;
    padding: 20px 0 20px 0;
    border-top-right-radius: 40px;
    text-align: center !important;
    border: 1px solid #e1e1e1;
    border-bottom-color: #fff;
}

#feWrap .titleSlack {
    background-color: #4A154B;
    color: #fff;
    padding: 20px 0 20px 0;
    border-top-right-radius: 40px;
    text-align: center !important;
    border: 1px solid #e1e1e1;
    border-bottom-color: #fff;
}

#feWrap .titleZoom {
    background-color: #2D8CFF;
    color: #fff;
    padding: 20px 0 20px 0;
    border-top-right-radius: 40px;
    text-align: center !important;
    border: 1px solid #e1e1e1;
    border-bottom-color: #fff;
}

#feWrap .col-2 {
    padding-right: 1px !important;
    padding-left: 1px !important;
}

/*#feWrap .content-row > div:nth-child(2) { background-color: #ffffff; border-left: solid 3px #fff; border-right: solid 3px #fff; }
    #feWrap .content-row > div:nth-child(3) { background-color: #e4f3f7; border-left: solid 3px #fff; border-right: solid 3px #fff; }
    #feWrap .content-row > div:nth-child(4) { background-color: #ffffff; border-left: solid 3px #fff; border-right: solid 3px #fff; }
    #feWrap .content-row > div:nth-child(5) { background-color: #e4f3f7; border-left: solid 3px #fff; border-right: solid 3px #fff; }*/
#feWrap .content-row>div:nth-child(2) {
    background-color: #ffffff;
    border-left: solid 1px rgba(209, 209, 209, 0.5);
    border-right: solid 1px rgba(209, 209, 209, 0.5);
}

#feWrap .content-row>div:nth-child(3) {
    background-color: #ffffff;
    border-left: none;
    border-right: solid 1px rgba(209, 209, 209, 0.5);
}

#feWrap .content-row>div:nth-child(4) {
    background-color: #ffffff;
    border-left: none;
    border-right: solid 1px rgba(209, 209, 209, 0.5);
}

#feWrap .content-row>div:nth-child(5) {
    background-color: #ffffff;
    border-left: none;
    border-right: solid 1px rgba(209, 209, 209, 0.5);
}

#feWrap .titleFeature .feHeaderTitle {
    font-size: 22px;
}

#feWrap .content-row .fa-question-circle {
    display: block;
    position: absolute;
    width: 20px;
    right: 10px;
    top: 11px;
    color: #000;
    opacity: 0.3;
}

@media only screen and (max-width : 1200px) {}

@media only screen and (max-width : 991px) {

    .content-row-sm-double {}

    .content-row-sm-triple {}

    .content-row-xs-double {}

    .content-row-xs-triple {}

    #feWrap .col-titles {
        padding-right: 30px;
    }

    #feWrap .feIcon {
        padding: 5px 0 0 0;
    }

    #feWrap .feNumb {
        padding: 5px 0 0 0;
    }

    #feWrap .content-row.content-row-sm-double .feIcon {
        padding: 19px 0 0 0;
    }

    #feWrap .content-row.content-row-sm-double .feNumb {
        padding: 19px 0 0 0;
    }
}

@media only screen and (max-width : 767px) {

    #feWrap .titleLivedesk,
    #feWrap .titleTeams,
    #feWrap .titleSlack,
    #feWrap .titleZoom {
        border-top-right-radius: 20px;
    }

    #feWrap .feIcon {
        padding: 7px 0 0 0;
    }

    #feWrap .feNumb {
        padding: 7px 0 0 0;
    }

    #feWrap .content-row.content-row-xs-double .feIcon {
        padding: 19px 0 0 0;
    }

    #feWrap .content-row.content-row-xs-double .feNumb {
        padding: 19px 0 0 0;
    }

    #feWrap .content-row.content-row-xs-triple .feIcon {
        padding: 30px 0 0 0;
    }

    #feWrap .content-row.content-row-xs-triple .feNumb {
        padding: 30px 0 0 0;
    }

}

/* FEATURE COMPARISON TABLE SECTION END */








/* BOOTSTRAP TOOLTIP OVERRIDE START */
.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Hind Vadodara", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #1D2226;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #1D2226;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #1D2226;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #1D2226;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #FFFFFF;
    text-align: center;
    background-color: #1D2226;
    border-radius: 0.25rem;
}

/* BOOTSTRAP TOOLTIP OVERRIDE END */






/* BOOTSTRAP TOOLTIPS START */
.tooltip.show {
    opacity: 1;
}

.tooltip-inner {
    background-color: #2AA1B6 !important;
    color: #fff;
    max-width: 400px !important;
    opacity: 1;
}

.tooltip-inner h5 {
    font-size: 15px;
    font-weight: 700;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 8px;
    padding-top: 4px;
    margin-bottom: 0px;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #2AA1B6 !important;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #2AA1B6 !important;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #2AA1B6 !important;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #2AA1B6 !important;
}

/* BOOTSTRAP TOOLTIPS END */
</style>