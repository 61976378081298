<template>
    <div class="getStarted">
        <div class="content center">
            <div class="left">
                <div class="top-title H1-Medium">Ready to get started</div>
                <div class="top-description H6-Regular">Give us a call, drop us an email or how about a webchat?</div>
                <div class="bottom">
                    <div class="btn-left" @click="$router.push('/contact')">
                        <span class="Regular-Medium">Get Started</span>
                        <img src="../assets/svg/whitearrowRight.svg">
                    </div>
                    <div class="btn-right" @click="$router.push('/contact')">
                        <img src="../assets/svg/message.svg">
                        <span class="Regular-Medium">Contact Us</span>
                    </div>
                </div>
            </div>
        </div>
        <img src="../assets/image/ready-to.png" class="footerImage">
    </div>
</template>


<script>
export default {
    name: 'BottomCtaComponent',

}
</script>


<style scoped lang="less">
.center {
    margin: 0 auto;
    width: 80%;
}

.getStarted {
    position: relative;
    overflow: hidden;
    background: url('../assets/image/footerBG.png') no-repeat;
    height: 298px;
    background-size: 100% 298px;

    .footerImage {
        position: absolute;
        right: 0px;
        top: 63px;
        width: 554px;
        height: 270px;
    }

    .content {
        padding: 60px;
        position: relative;

        .left {
            .top-title {
                //styleName: Pri -WEB/Heading H1/Medium;
                font-family: Work Sans;
                font-size: 44px;
                font-weight: 500;
                line-height: 52px;
                letter-spacing: -0.02em;
                text-align: left;
                color: #fff;
            }

            .top-description {
                margin-top: 10px;
                //styleName: Pri -WEB/Heading H6/Regular;
                font-family: Work Sans;
                font-size: 20px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: -0.02em;
                text-align: left;
                color: #fff;
            }
        }

        .bottom {
            margin-top: 30px;
            display: flex;

            .btn-left {
                cursor: pointer;
                width: 148px;
                height: 48px;
                background: #0C5C6A;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    font-size: 16px;
                    color: #FFFFFF;

                }

                img {
                    width: 12px;
                    height: 12px;
                    margin-left: 8px;
                }
            }

            .btn-right {
                margin-left: 16px;
                width: 148px;
                height: 48px;
                cursor: pointer;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #fff;
                background: linear-gradient(0deg, #FFFFFF, #FFFFFF);

                span {
                    font-size: 16px;
                    color: #0C5C6A;
                    margin-left: 8px;
                }

                img {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}

@media screen and (max-width:900px) {

    // On small screens
    .md-text-center {
        text-align: start;
    }

    .center {
        width: 90%;
    }


    /*  */
    .getStarted {
        height: 508px;
        background-size: 100% 568px;

        .content {
            padding-inline: 20px;

            .left {
                .top-title {
                    font-size: 28px;
                }
                .top-description {
                    margin-top: 0;
                    font-size: 16px;
                }
            }
        }

        .footerImage {
            top: unset;
            bottom: -5px;
            width: 97%;
            height: unset;
            max-height: 50%;
        }

    }
}
@media screen and (max-width:400px) {
    input {
        width: 250px !important;
        max-width: 250px !important;
    }
}

</style>