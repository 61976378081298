import { createRouter, createWebHistory } from 'vue-router';
import pricing from '../components/price.vue'
import frontPage from '../components/frontPage'
import contactPage from '../components/contact'
import faqsPage from '../components/faqsPage'
import termsPage from '../components/Terms'
import privacyPage from '../components/Privacy'
import headerNav from '../components/HeaderNav'
import BookMeeting from '../components/BookMeeting.vue'

const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path:'/',
            component:headerNav,
            children: [
                {
                    path:'',
                    component:frontPage
                },
                {
                    path:'/pricing',
                    component:pricing
                },
                {
                    path: '/contact',
                    component:contactPage
                },
                {
                    path:'/faqsPage',
                    component:faqsPage,
                },
                {
                    path :'/termsPage',
                    component:termsPage
                },
                {
                    path :'/privacyPage',
                    component:privacyPage
                },
                {
                    path :'/bookMeeting',
                    component:BookMeeting
                }
            ]


        }
        
    ]
})

router.beforeEach((to, from, next) => {
    // Scroll to top when navigating to a new route
    window.scrollTo(0, 0);
    next();
  });

export default router;