import { createApp } from 'vue';
import App from './App.vue';
// import router from './route';
import router from './route';
// import ElementPlus from 'element-plus';
// import 'element-plus/lib/theme-chalk/index.css';

import 'swiper/swiper-bundle.css';
// import 'element-plus/dist/index.css';

const app = createApp(App);

// Use Vue Router
app.use(router);

// Use Element Plus
// app.use(ElementPlus);

// app.use(vueRouter);

app.mount('#app');
