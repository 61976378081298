<template>
    <div class="container-main">
        <div class="banner-box">



            <!-- upper -->

            <div class="component-66 center">
                <img class="termsUpper" src="../assets/image/TermsUpper.png">
            </div>
            <div class="Group-626612 center">
                <p>Our home based advisors provide brilliant service for leading brands</p>
                <img class="termsUpper" src="../assets/image/Group-626612.png">
            </div>




            <div class="component-67 center">
                <div class="terms">Terms</div>
                <div
                    class="these-terms-and-conditions-of-use-thereafter-the-terms-and-conditions-govern-your-use-of-the-sensee-co-uk-website-the-website-provided-by-sens-e-ltd-the-company-by-accessing-and-or-using-the-website-you-accept-without-limitation-or-qualification-the-following-terms-and-conditions">
                    THESE TERMS AND CONDITIONS OF USE (THEREAFTER THE &quot;TERMS AND
                    CONDITIONS&quot;) GOVERN YOUR USE OF THE CLOUDWORKS.CC WEBSITE (THE
                    &quot;WEBSITE&quot;) PROVIDED BY SENSÉE LTD. TRADING AS CLOUDWORKS. (THE &quot;COMPANY&quot;). BY
                    ACCESSING AND/OR USING THE WEBSITE, YOU ACCEPT, WITHOUT LIMITATION OR
                    QUALIFICATION, THE FOLLOWING TERMS AND CONDITIONS.
                </div>
                <div class="frame-2608801">
                    <div class="paragraph">
                        <div class="_1-permitted-use">1. Permitted Use</div>
                        <div
                            class="you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease">
                            You have a non-exclusive, non-transferable, limited, revocable right to
                            use the Website solely for your personal educational, informational, and
                            entertainment use or any other use the Company and you agreed to in a
                            separate written agreement. You will not use the Website for any other
                            purpose, including any commercial purpose, without the Company&#039;s
                            express prior written consent. For example, you will not authorise any
                            other person to (i) co-brand the Website or portion thereof, or (ii)
                            frame the Website or portion thereof (whereby the Website or portion
                            thereof will appear on the same screen with a portion of another
                            Website). &quot;Co-branding&quot; means to display a name, logo,
                            trademark, or other means of attribution or identification of any party
                            in such a manner reasonably likely to give a user the impression that
                            such other party has the right to display, publish, or distribute the
                            Website or content accessible within the Website. You agree to cooperate
                            with the Company in causing any unauthorised co-branding, framing or
                            linking to immediately cease.
                        </div>
                        <div
                            class="you-may-use-the-website-for-lawful-purposes-only-you-may-not-post-or-transmit-through-the-website-any-material-which-violates-or-infringes-in-any-way-upon-the-rights-of-others-which-is-unlawful-threatening-abusive-defamatory-libellous-invasive-of-privacy-or-publicity-rights-vulgar-obscene-profane-or-otherwise-objectionable-which-encourages-conduct-that-would-constitute-a-criminal-offence-give-rise-to-civil-liability-or-otherwise-violate-any-law-the-company-will-fully-cooperate-with-any-law-enforcement-authorities-or-court-order-requesting-or-directing-the-company-to-disclose-the-identity-of-anyone-posting-such-information-or-materials">
                            You may use the Website for lawful purposes only. You may not post or
                            transmit through the Website any material which violates or infringes in
                            any way upon the rights of others, which is unlawful, threatening,
                            abusive, defamatory, libellous, invasive of privacy or publicity rights,
                            vulgar, obscene, profane or otherwise objectionable, which encourages
                            conduct that would constitute a criminal offence, give rise to civil
                            liability or otherwise violate any law. The Company will fully cooperate
                            with any law enforcement authorities or court order requesting or
                            directing the Company to disclose the identity of anyone posting such
                            information or materials.
                        </div>
                    </div>
                    <div class="paragraph2">
                        <div class="_1-permitted-use">2. Trademarks</div>
                        <div
                            class="you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease">
                            The trademarks, logos, and service marks (collectively the
                            &quot;Trademarks&quot;) displayed on the Website are registered and
                            unregistered Trademarks of the Company and its licensors, content
                            providers, clients and business partners. Nothing contained on the
                            Website should be construed as granting, by implication, estoppel, or
                            otherwise, any license or right to use any Trademark displayed on the
                            Website without the written permission of the Company or such party that
                            may own the Trademark. Your use of the Trademarks displayed on the
                            Website, or any other content on the Website, except as provided in
                            these Terms and Conditions, is strictly prohibited. The Company will
                            aggressively enforce its intellectual property rights to the fullest
                            extent of the law, including seeking criminal prosecution.
                        </div>
                    </div>
                    <div class="paragraph3">
                        <div class="_1-permitted-use">3. Proprietary Information</div>
                        <div
                            class="you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease">
                            The content accessible within the Website, including without limitation,
                            all articles, press releases, Trademarks, graphics, charts, screen
                            captures, clipart, text, links, product information, and other
                            information (collectively, the &quot;Content&quot;) is the proprietary
                            information of the Company and its content providers, and the Company
                            and its content providers retain all right, title, and interest in the
                            Content. Accordingly, you will not reproduce, transmit, publish or
                            distribute such Content to any third party without the express written
                            consent of the Company or the applicable content provider, except that
                            you may print out a copy of the Content solely for your personal use. In
                            doing so, you will not remove or alter, or cause to be removed or
                            altered, any copyright, Trademark, trade name, service mark, or any
                            other proprietary notice or legend appearing on any of the Content.
                        </div>
                    </div>
                    <div class="paragraph4">
                        <div class="_1-permitted-use">4. Indemnity</div>
                        <div
                            class="you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease">
                            You will indemnify and hold the Company, its licensors, content
                            providers, service providers, clients and contractors (the
                            &quot;Indemnified Parties&quot;) harmless from your breach of these
                            Terms and Conditions and your use of the Content other than as expressly
                            authorised in these Terms and Conditions. You agree that the Indemnified
                            Parties will have no liability in connection with any such breach or
                            unauthorised use, and agree to indemnify any and all resulting loss,
                            damages, judgements, awards, costs, expenses, and attorneys&#039; fees
                            of the Indemnified Parties in connection therewith. You will also
                            indemnify and hold the Indemnified Parties harmless from and against any
                            claims brought by third parties arising out of your use of the
                            information accessed from the Website.
                        </div>
                    </div>
                    <div class="paragraph5">
                        <div class="_1-permitted-use">5. Disclaimers</div>
                        <div
                            class="you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease">
                            Your use of and browsing in the Website are at your sole risk. THE
                            WEBSITE AND ALL CONTENT ARE PROVIDED TO YOU &quot;AS IS&quot; WITHOUT
                            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT
                            LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, INFRINGEMENT, TITLE
                            OR FITNESS FOR A PARTICULAR PURPOSE. THE COMPANY DOES NOT WARRANT THAT
                            THE WEBSITE OR ITS CONTENTS WILL BE UNINTERRUPTED OR ERROR FREE; NOR
                            DOES THE COMPANY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
                            OBTAINED FROM USE OF THE WEBSITE OR THE CONTENT OR AS TO THE ACCURACY,
                            COMPLETENESS, TRUTHFULNESS OR RELIABILITY OF ANY INFORMATION PROVIDED
                            THROUGH THE WEBSITE. Some jurisdictions may not allow the exclusion of
                            certain implied warranties, so some of the above exclusions may not
                            apply to you.
                        </div>
                        <div
                            class="you-may-use-the-website-for-lawful-purposes-only-you-may-not-post-or-transmit-through-the-website-any-material-which-violates-or-infringes-in-any-way-upon-the-rights-of-others-which-is-unlawful-threatening-abusive-defamatory-libellous-invasive-of-privacy-or-publicity-rights-vulgar-obscene-profane-or-otherwise-objectionable-which-encourages-conduct-that-would-constitute-a-criminal-offence-give-rise-to-civil-liability-or-otherwise-violate-any-law-the-company-will-fully-cooperate-with-any-law-enforcement-authorities-or-court-order-requesting-or-directing-the-company-to-disclose-the-identity-of-anyone-posting-such-information-or-materials">
                            The Company does not guarantee or warrant that files available for
                            downloading from the Internet will be free of infection or viruses,
                            worms, trojan horses or other code that manifest contaminating or
                            destructive properties. You are responsible for implementing sufficient
                            procedures and checkpoints to satisfy your particular requirements for
                            accuracy of data input and output, and for maintaining a means external
                            to the Website for the reconstruction of any lost data. The Company does
                            not assume any responsibility or risk for your use of the Internet.
                        </div>
                    </div>
                    <div class="paragraph6">
                        <div class="_1-permitted-use">6. Limitation of Liability</div>
                        <div
                            class="you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease">
                            THE COMPANY, ITS LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, AND
                            THEIR EMPLOYEES, AGENTS, OFFICERS AND DIRECTORS (COLLECTIVELY, THE
                            &quot;COMPANY AND ITS AFFILIATES&quot;), WILL NOT BE LIABLE FOR ANY
                            DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, CONSEQUENTIAL OR SPECIAL
                            DAMAGES, INCLUDING LOSS OF REVENUE, LOST PROFITS OR SIMILAR DAMAGES
                            ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF OR ACCESS TO THE
                            WEBSITE, THE CONTENTS OR INABILITY TO USE THE WEBSITE OR OTHERWISE
                            ARISING IN CONNECTION WITH THE WEBSITE, EVEN IF THE COMPANY AND ITS
                            AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO
                            EVENT WILL THE COLLECTIVE LIABILITY OF THE COMPANY AND ITS AFFILIATES
                            (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT OR
                            OTHERWISE) EXCEED THE AMOUNT YOU HAVE PAID TO THE COMPANY FOR THE
                            APPLICABLE CONTENT OR SERVICE OUT OF WHICH LIABILITY AROSE, OR IF YOU
                            HAVEN&#039;T MADE ANY PAYMENTS TO COMPANY, THEN THE LIABILITY OF COMPANY
                            AND ITS AFFILIATES SHALL NOT EXCEED £100.00 UK STERLING. Some
                            jurisdictions may not allow the exclusion of certain implied warranties,
                            so some of the above exclusions may not apply to you.
                        </div>
                    </div>
                    <div class="paragraph7">
                        <div class="_1-permitted-use">7. Forums</div>
                        <div
                            class="you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease">
                            The opinions, advice, statements, and comments (together, the
                            &quot;Messages&quot;) posted on the discussion forums (the
                            &quot;Forums&quot;) are supplied by third parties and other users unless
                            the Company or its authorised representative identifies itself as the
                            content poster. The Messages submitted on the Forums are for general
                            information purposes only and are not warranted to be accurate or
                            current, are solely those of the various authors, and do not necessarily
                            reflect the views or official position of the Company. The Company
                            reserves the right to monitor the Forums to determine compliance with
                            these Terms and Conditions, as well as the right to remove or refuse to
                            post any posting. Although the Company may periodically undertake
                            certain editorial screening of the Messages, this is only for the
                            purpose of maintaining relevance to the purposes of the Forums. The
                            Company is not undertaking to assess, screen or provide a warning for
                            Messages containing threatening, libellous, obscene, harassing or
                            offensive material contained therein, any infringement of third party
                            intellectual property rights arising therefrom or any crime facilitated
                            thereby. By participating in the Forums, You waive for yourself and your
                            company all claims against the Company and other Forum participants,
                            including, without limitations, claims of libel, defamation, and
                            negligence.
                        </div>
                    </div>
                    <div class="paragraph8">
                        <div class="_1-permitted-use">8. License</div>
                        <div
                            class="you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease">
                            By communicating with the Company (by e-mail or otherwise) or
                            participating in any Forum, you grant the Company an irrevocable,
                            non-exclusive, transferable and worldwide royalty-free right and license
                            to use, reproduce, modify, adapt, translate, publicly perform and
                            display, distribute, sublicense, create derivative works from, and sell
                            any information, message, suggestion, idea or concept you communicated
                            to the Company or posted on the Website (in whole or in part) for any
                            purpose the Company chooses, commercial, public or otherwise.
                        </div>
                    </div>
                    <div class="paragraph9">
                        <div class="_1-permitted-use">9. Links</div>
                        <div
                            class="you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease">
                            The Website may contain hyperlinks to websites operated by third parties
                            other than the Company. Such hyperlinks are provided for your references
                            only. The Company does not control such third party websites and is not
                            responsible for their contents or the products and services offered on
                            the third party websites. The Company&#039;s inclusion of the hyperlinks
                            to such websites does not imply any endorsement of the material on such
                            websites or any association with their operators. Operators of other
                            websites may not maintain links to this Website without the
                            Company&#039;s prior written consent. The Company reserves the right to
                            prohibit other websites from linking to the Website.
                        </div>
                    </div>
                    <div class="paragraph10">
                        <div class="_1-permitted-use">10. Changes</div>
                        <div
                            class="you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease">
                            The Company has the right at any time to change or discontinue any
                            aspect or feature of the Website. These Terms and Conditions are subject
                            to change by the Company at any time in its discretion. Your use of the
                            Website after such changes are implemented constitutes your acceptance
                            of the changes. Please consult these Terms and Conditions regularly.
                        </div>
                    </div>
                    <div class="paragraph11">
                        <div class="_1-permitted-use">11. Miscellaneous</div>
                        <div
                            class="you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease">
                            If any section or provision of these Terms and Conditions are held
                            illegal, unenforceable or in conflict with any law by a court of
                            competent jurisdiction or arbitral tribunal, such section or provision
                            shall be deemed severed from these Terms and Conditions and the validity
                            of the remainder of these Terms and Conditions shall not be affected
                            thereby. These Terms and Conditions shall be interpreted, construed and
                            enforced in all respects in accordance with the laws of England. You and
                            the Company (collectively the &quot;Parties;&quot; individually a
                            &quot;Party&quot;) irrevocably submit to the non-exclusive jurisdiction
                            of the English Courts. The waiver by either Party of any provision of
                            these Terms and Conditions on any occasion and upon any particular
                            circumstances shall not operate as a waiver of such provision of these
                            Terms and Conditions on any other occasion or upon any other
                            circumstances. These Terms and Conditions may be waived or amended only
                            in writing signed by the Parties.
                        </div>
                    </div>
                    <div class="frame-2608800">
                        <div class="frame-2608799">
                            <div class="t-c-sticky-nav"
                                @click="$el.querySelector('.paragraph').scrollIntoView({ behavior: 'smooth' })">
                                <div class="fill"></div>
                                <div class="stick"></div>
                                <div class="_01">01.</div>
                                <div class="permitted-use">Permitted Use</div>
                            </div>
                            <div class="line-516"></div>
                            <div class="t-c-sticky-nav"
                                @click="$el.querySelector('.paragraph2').scrollIntoView({ behavior: 'smooth' })">
                                <div class="fill"></div>
                                <div class="stick"></div>
                                <div class="_01">02.</div>
                                <div class="permitted-use">Trademarks</div>
                            </div>
                            <div class="line-515"></div>
                            <div class="t-c-sticky-nav"
                                @click="$el.querySelector('.paragraph3').scrollIntoView({ behavior: 'smooth' })">
                                <div class="fill"></div>
                                <div class="stick"></div>
                                <div class="_01">03.</div>
                                <div class="permitted-use">Proprietary Information</div>
                            </div>
                            <div class="line-514"></div>
                            <div class="t-c-sticky-nav"
                                @click="$el.querySelector('.paragraph4').scrollIntoView({ behavior: 'smooth' })">
                                <div class="fill"></div>
                                <div class="stick"></div>
                                <div class="_01">04.</div>
                                <div class="permitted-use">Indemnity</div>
                            </div>
                            <div class="line-513"></div>
                            <div class="t-c-sticky-nav"
                                @click="$el.querySelector('.paragraph5').scrollIntoView({ behavior: 'smooth' })">
                                <div class="fill"></div>
                                <div class="stick"></div>
                                <div class="_01">05.</div>
                                <div class="permitted-use">Disclaimers</div>
                            </div>
                            <div class="line-512"></div>
                            <div class="t-c-sticky-nav"
                                @click="$el.querySelector('.paragraph6').scrollIntoView({ behavior: 'smooth' })">
                                <div class="fill"></div>
                                <div class="stick"></div>
                                <div class="_01">06.</div>
                                <div class="permitted-use">Limitation of Liability</div>
                            </div>
                            <div class="line-511"></div>
                            <div class="t-c-sticky-nav"
                                @click="$el.querySelector('.paragraph7').scrollIntoView({ behavior: 'smooth' })">
                                <div class="fill"></div>
                                <div class="stick"></div>
                                <div class="_01">07.</div>
                                <div class="permitted-use">Forums</div>
                            </div>
                            <div class="line-510"></div>
                            <div class="t-c-sticky-nav"
                                @click="$el.querySelector('.paragraph8').scrollIntoView({ behavior: 'smooth' })">
                                <div class="fill"></div>
                                <div class="stick"></div>
                                <div class="_01">08.</div>
                                <div class="permitted-use">License</div>
                            </div>
                            <div class="line-517"></div>
                            <div class="t-c-sticky-nav"
                                @click="$el.querySelector('.paragraph9').scrollIntoView({ behavior: 'smooth' })">
                                <div class="fill"></div>
                                <div class="stick"></div>
                                <div class="_01">09.</div>
                                <div class="permitted-use">Links</div>
                            </div>
                            <div class="line-518"></div>
                            <div class="t-c-sticky-nav"
                                @click="$el.querySelector('.paragraph10').scrollIntoView({ behavior: 'smooth' })">
                                <div class="fill"></div>
                                <div class="stick"></div>
                                <div class="_01">10.</div>
                                <div class="permitted-use">Changes</div>
                            </div>
                            <div class="line-519"></div>
                            <div class="t-c-sticky-nav"
                                @click="$el.querySelector('.paragraph11').scrollIntoView({ behavior: 'smooth' })">
                                <div class="fill"></div>
                                <div class="stick"></div>
                                <div class="_01">11.</div>
                                <div class="permitted-use">Miscellaneous</div>
                            </div>
                            <div class="line-520"></div>
                        </div>
                        <div class="webmaster-sensee-co-uk-latest-version-posted-25-09-07">
                            support@cloudworks.cc<br />Latest version posted 25/09/07
                        </div>
                    </div>
                </div>
            </div>




        </div>


        <!-- <img src="../assets/image/Bottom-Logos.png" class="bottom-logos"/> -->


    </div>
</template>

<script>

export default {
    name: 'termsPage',
    components: {

    },
    data() {
        return {

            isFixed: false,
            // activeIndex: 1,
        }
    },
    methods: {

    }



}
</script>

<style scoped lang="less">
.center {
    margin: 0 auto;
    width: 80%;
}

.container-main {
    margin: 0 auto;
    background: #F2F3F4;
}

.banner-box {
    overflow: hidden;
    width: 100%;
    // background: url('../assets/image/main-bg.png');
    // background-repeat: no-repeat;
    // background-size: 100% 50%;

    .fixed-box {
        width: 100%;
        z-index: 99;
        background: #0C5C6A;

        &.active {
            position: fixed;
            background-color: rgba(255, 255, 255, 0.8);

            .menu {
                a {
                    color: #000000;

                    &:last-child {
                        background: linear-gradient(0deg, #2AA1B6, #2AA1B6);
                        border: .5px solid #2AA1B6;
                    }
                }
            }

            .header {
                padding-bottom: 16px;
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding-top: 16px;
        padding-bottom: 16px;

        .menu {
            a {
                //styleName: Sec -WEB/Paragraph Regular/Medium;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                line-height: 44.5px;
                letter-spacing: 0em;
                text-align: center;
                color: #FFFFFFCC;
                text-decoration: none;
                padding: 8px 16px;

                &.active {
                    border-bottom: 2px solid #fff;
                    font-weight: 600;
                }

                &:last-child {
                    margin-right: 0px;
                    border: .5px solid #fff;
                    padding: 8px 24px;
                    border-radius: 4px;
                }
            }
        }
    }

    .title {
        margin-top: 36px;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .price-box {
        background: #0C5C6A;
        border-radius: 16px;
        margin-top: 60px;

        .package {
            padding: 12px 0px;

            img {
                width: 18px;
                height: 18px;
                margin-left: 8px;
            }
        }

        .package-top {
            padding: 12px 20px;
            color: #FFFFFF80;

        }

        div {
            box-sizing: border-box;
        }

        .outer {
            background: #0D4953;
            border-radius: 16px;

            .item-box-standard {
                background: #F8F9F9;
            }

            .item-box-starter-middle {
                padding: 0px 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }

            .item-box-standard-middle {
                padding: 0px 30px;
                border-right: 8px solid #5298E0;
                border-left: 8px solid #5298E0;
                background: #F2F3F4;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #5298E0;
                font-weight: 600;
            }

            .item-box-enterprise-middle {
                padding: 0px 30px;
                margin-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }

            .package-middle {
                padding: 12px 20px;
                color: #fff;

                .position-box {
                    position: relative;
                    display: flex;
                }

                .tip {
                    position: absolute;
                    width: 200px;
                    left: 0px;
                    top: 60px;
                    border-radius: 0px 16px 16px 16px;
                    background: #0D4953;
                    padding: 8px;
                    transition: all .2s linear;
                    opacity: 0;

                    &.active {
                        transition: all .2s linear;
                        left: 30px;
                        top: 30px;
                        opacity: 100%;
                    }
                }
            }
        }

        .colum {
            .item-box-standard-top {
                padding: 0px 30px;
                border-top: 8px solid #5298E0;
                border-right: 8px solid #5298E0;
                border-left: 8px solid #5298E0;
                border-radius: 16px 16px 0px 0px;
                background: #F2F3F4;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .item-box-starter-top {
                padding: 0px 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }

            .item-box-enterprise-top {
                padding: 0px 30px;
                margin-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }

            display: flex;

            >div {
                width: 25%;
            }

            .package {
                display: flex;
                align-items: center;
            }

            .item-box-standard {
                border-left: 8px solid #5298E0;
                border-right: 8px solid #5298E0;
            }
        }

        .priceUser {
            >.colum {
                display: flex;
                flex-direction: column;

                .box {
                    display: flex;
                    width: 100%;

                    >div {
                        width: 25%;
                    }
                }

                .childList {
                    margin-left: 20px;
                    width: calc(100% - 20px);
                    box-sizing: border-box;
                    border-radius: 16px 0px 0px 16px;
                    overflow: hidden;
                    max-height: 0px;
                    transition: all .4s ease 0s;

                    &.active {
                        max-height: 300px;
                    }

                    >.colum {
                        &:nth-child(1) {
                            .border-padding {
                                border-radius: 16px 0px 0px 0px;
                            }
                        }

                        &:last-child {
                            .border-padding {
                                border-radius: 0px 0px 0px 16px;
                            }
                        }

                        >div {
                            background-color: #F2F3F4;
                            width: calc((100% + 20px)/4);

                            &:nth-child(1) {
                                width: calc((100% + 20px)/4 - 20px);
                            }

                            &.border-padding {
                                background: #0D4953;
                            }
                        }

                        &:last-child {
                            .item-box-standard {
                                border-bottom: none;
                                border-radius: 0px;
                            }

                            .item-box-starter {
                                border-radius: 0px;
                                margin-bottom: 0px;

                                .starter {
                                    border-bottom: none;
                                }
                            }

                            .item-box-enterprise {
                                margin-bottom: 0px;
                                border-radius: 0px;
                            }

                            .enterprise,
                            .standard,
                            .starter {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

            .position-box {
                position: relative;
                display: flex;
            }

            .tip {
                position: absolute;
                width: 200px;
                left: 0px;
                top: 60px;
                border-radius: 0px 16px 16px 16px;
                background: #0D4953;
                padding: 8px;
                transition: all .2s linear;
                opacity: 0;

                &.active {
                    transition: all .2s linear;
                    left: 30px;
                    top: 30px;
                    opacity: 100%;
                }
            }

            .expand {
                margin-left: 34px;
                color: #52CBE0;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: right;
                text-decoration: underline;
                cursor: pointer;
            }

            .btn {
                margin-bottom: 20px;
                margin-top: 8px;
                padding: 12px 38px;
                text-align: center;
                border-radius: 4px;
                border: 1px solid #BCC5EA;
                cursor: pointer;
            }

            .btn-standard {
                background: #5298E0;
                color: #FFFFFF;
            }

            .starter-check {
                img {
                    border-radius: 50%;
                    background: #E05288;
                }
            }

            .standard-check {
                img {
                    border-radius: 50%;
                    background: #5298E0;
                }
            }

            .enterprise-check {
                img {
                    border-radius: 50%;
                    background: #255A91;
                }
            }

            .border-padding {
                padding: 0 20px;
            }

            .colum {
                .item-box-enterprise {
                    margin-right: 8px;
                }

                .item-box {
                    background: #F8F9F9;
                    padding: 0px 30px;
                }

                background: #0F363C;

                &:nth-child(1) {
                    border-radius: 16px 16px 0px 0px;
                }

                &:last-child {
                    border-radius: 0px 0px 16px 16px;
                }

                .package {
                    border-bottom: 1px solid #4B686D80;
                    color: #fff;
                }

                .starter,
                .standard,
                .enterprise {
                    padding: 12px 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid #D5DBE0
                }

                &:last-child {
                    .item-box-standard {
                        border-bottom: 8px solid #5298E0;
                        border-radius: 0px 0px 10px 10px;
                    }

                    .item-box-starter {
                        border-radius: 0px 0px 0px 16px;
                        margin-bottom: 8px;

                        .starter {
                            border-bottom: none;
                        }
                    }

                    .item-box-enterprise {
                        margin-bottom: 8px;
                        border-radius: 0px 0px 16px 0px;
                    }

                    .enterprise,
                    .standard,
                    .starter {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}



// upper


.component-66,
.component-66 * {
    box-sizing: border-box;
}

.component-66 {
    width: 100%;
    height: 100%;
    position: relative;
}

.termsUpper {
    width: 100vw;
    /* Set the width to 100% of the viewport width */
    height: auto;
    /* Set your desired fixed height */
    overflow: hidden;
}

.rectangle-1079 {
    height: auto;
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
    overflow: visible;
}

.group-626584 {
    height: auto;
    position: absolute;
    right: 10.42%;
    left: 48.96%;
    width: 40.62%;
    bottom: 0%;
    top: 15.15%;
    height: 84.85%;
    overflow: visible;
}

.our-home-based-advisors-provide-brilliant-service-for-leading-brands {
    color: var(--cloudworks-neutral-white, #ffffff);
    text-align: left;
    font: var(--pri-web-heading-h-2-medium,
            500 36px/52px "WorkSans-Medium",
            sans-serif);
    position: absolute;
    right: 55.49%;
    left: 10.42%;
    width: 34.1%;
    bottom: 37.58%;
    top: 15.15%;
    height: 47.27%;
}




// downward


.bottom-logos {
    width: 100%;
}

.component-67,
.component-67 * {
    box-sizing: border-box;
}

.component-67 {
    width: 1140px;
    height: 4800px;
    position: relative;
}

.terms {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--pri-web-heading-h-2-medium,
            500 36px/52px "WorkSans-Medium",
            sans-serif);
    position: absolute;
    right: 56.93%;
    left: 0%;
    width: 43.07%;
    bottom: 98.91%;
    top: 0%;
    height: 1.09%;
}

.these-terms-and-conditions-of-use-thereafter-the-terms-and-conditions-govern-your-use-of-the-sensee-co-uk-website-the-website-provided-by-sens-e-ltd-the-company-by-accessing-and-or-using-the-website-you-accept-without-limitation-or-qualification-the-following-terms-and-conditions {
    color: var(--grayscale-300, #45525c);
    text-align: left;
    font: var(--pri-web-heading-h-6-medium,
            500 20px/28px "WorkSans-Medium",
            sans-serif);
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 96.93%;
    top: 1.3%;
    height: 1.76%;
}

.frame-2608801 {
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 1.72%;
    height: 98.28%;
    overflow: hidden;
}

.paragraph {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 114px;
}

._1-permitted-use {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--pri-web-heading-h-2-medium,
            500 36px/52px "WorkSans-Medium",
            sans-serif);
    position: relative;
    align-self: stretch;
}

.you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--sec-web-paragraph-large-regular,
            400 18px/28px "Roboto-Regular",
            sans-serif);
    position: relative;
    align-self: stretch;
}

.you-may-use-the-website-for-lawful-purposes-only-you-may-not-post-or-transmit-through-the-website-any-material-which-violates-or-infringes-in-any-way-upon-the-rights-of-others-which-is-unlawful-threatening-abusive-defamatory-libellous-invasive-of-privacy-or-publicity-rights-vulgar-obscene-profane-or-otherwise-objectionable-which-encourages-conduct-that-would-constitute-a-criminal-offence-give-rise-to-civil-liability-or-otherwise-violate-any-law-the-company-will-fully-cooperate-with-any-law-enforcement-authorities-or-court-order-requesting-or-directing-the-company-to-disclose-the-identity-of-anyone-posting-such-information-or-materials {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--sec-web-paragraph-large-regular,
            400 18px/28px "Roboto-Regular",
            sans-serif);
    position: relative;
    align-self: stretch;
}

.paragraph2 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 764px;
}

.paragraph3 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 1126px;
}

.paragraph4 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 1488px;
}

.paragraph5 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 1850px;
}

.paragraph6 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 2416px;
}

.paragraph7 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 2946px;
}

.paragraph8 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 3476px;
}

.paragraph9 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 3754px;
}

.paragraph10 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 4088px;
}

.paragraph11 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 4310px;
    margin-bottom: 100px;
}

.frame-2608800 {
    width: 293px;
    height: 913px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.frame-2608799 {
    background: var(--cloudworks-neutral-white, #ffffff);
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 0px;
    top: 114px;
}

// .t-c-sticky-nav {
//     padding: 16px 20px 16px 16px;
//     display: flex;
//     flex-direction: row;
//     gap: 12px;
//     align-items: center;
//     justify-content: flex-start;
//     flex-shrink: 0;
//     width: 293px;
//     position: relative;
//     //   background-color: #eee;
//     // background-color: #eee;
//     background: linear-gradient(to right, transparent 50%, #2aa1b6 50%);
//     background-size: 200% 100%;
//     transition: background-size 2s ease-in-out;

//     &:hover {
//         /* Hover effect styles */
//         //   background-size: 100% 100%;
//         background-position: 100% 0;
//         //   background-color: #2aa1b6;

//         /* Fully cover the background on hover */
//     }


// }

.t-c-sticky-nav {
    position: relative;
    padding: 16px 20px 16px 16px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 293px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: rgba(82, 203, 224, 0.1);
        transition: width 0.3s ease-in-out;
    }

    &:hover::before {
        width: 100%;
    }

    &:hover .fill {
        opacity: 1;
    }

}


.fill {
    background: var(--product-color-live-desk-light, #52cbe0);
    opacity: 0;
    flex-shrink: 0;
    width: 2px;
    height: 60px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.stick {
    background: var(--product-color-live-desk-medium, #2aa1b6);
    opacity: 0;
    flex-shrink: 0;
    width: 2px;
    height: 60px;
    position: absolute;
    left: 0px;
    top: 0px;
}

._01 {
    color: var(--cloudworks-surface-dark-shade-06, #30333e);
    text-align: right;
    font: var(--pri-web-heading-h-6-medium,
            500 20px/28px "WorkSans-Medium",
            sans-serif);
    opacity: 0.5;
    position: relative;
    width: 30px;
}

.permitted-use {
    color: var(--cloudworks-surface-dark-shade-06, #30333e);
    text-align: left;
    font: var(--pri-web-heading-h-6-medium,
            500 20px/28px "WorkSans-Medium",
            sans-serif);
    opacity: 0.5;
    position: relative;
}

.line-516 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-515 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-514 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-513 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-512 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-511 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-510 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-517 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-518 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-519 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-520 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.webmaster-sensee-co-uk-latest-version-posted-25-09-07 {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--sec-web-paragraph-small-regular,
            400 14px/20px "Roboto-Regular",
            sans-serif);
    position: absolute;
    left: 0px;
    top: 840px;
}

.Group-626612 {
    display: none;
}

@media screen and(max-width:900px) {

    .component-67,
    .component-67 * {
        position: unset;
        height: unset;
        width: unset;
        font-size: 16px;
        padding-inline: 10px;
    }

    .frame-2608800 {
        width: unset;
        height: unset;
        position: relative;
        left: 0px;
        top: 0px;
        order:1;
        padding-inline: 0;
        margin-block: 10px;
    }

    .frame-2608801 {
        display: flex;
        flex-direction: column;
    }

    .frame-2608799 {
        background: var(--cloudworks-neutral-white, #ffffff);
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: start;
        justify-content: flex-start;
        position: unset;
        left: 0px;
        top: 114px;
        padding-inline: 0px;
    }

    .t-c-sticky-nav {
        position: relative;
        padding: 16px 20px 16px 16px;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: rgba(82, 203, 224, 0.1);
            transition: width 0.3s ease-in-out;
        }

        &:hover::before {
            width: 100%;
        }

        &:hover .fill {
            opacity: 1;
        }

    }

    .fill {
        background: var(--product-color-live-desk-light, #52cbe0);
        opacity: 0;
        flex-shrink: 0;
        width: 2px;
        height: 60px;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .stick {
        background: var(--product-color-live-desk-medium, #2aa1b6);
        opacity: 0;
        flex-shrink: 0;
        width: 2px;
        height: 60px;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    ._01 {
        color: var(--cloudworks-surface-dark-shade-06, #30333e);
        text-align: right;
        font: var(--pri-web-heading-h-6-medium,
                500 20px/28px "WorkSans-Medium",
                sans-serif);
        opacity: 0.5;
        position: relative;
        width: 30px;
    }

    .these-terms-and-conditions-of-use-thereafter-the-terms-and-conditions-govern-your-use-of-the-sensee-co-uk-website-the-website-provided-by-sens-e-ltd-the-company-by-accessing-and-or-using-the-website-you-accept-without-limitation-or-qualification-the-following-terms-and-conditions {
        font-size: 18px;
    }

    [class^="paragraph"] * {
        font-size: 16px;
        padding-inline: 0;
    }

    [class^="line-"] {
        width: 90%;
    }

    [class^="paragraph"] {
        padding-inline: 0;
        order:2;
    }

    ._1-permitted-use,
    .terms {
        font-size: 28px;
        font-weight: 600;
        margin-top: 20px;
        padding-inline: 0;

    }

    .terms {
        font-weight: 500;
        font-size: 36px;
    }

    .webmaster-sensee-co-uk-latest-version-posted-25-09-07 {
        margin-block: 10px;
        font-size: 14px;
    }

    .component-66 {
        display: none;
    }

    .Group-626612 {
        display: block;
        width: 100%;
        background-image: url('../assets/image/Rectangle-685.png');
        background-size: 100% 100%;

        p {
            color: #fff;
            font-size: 28px;
            padding: 10px;
            padding-top: 50px;
        }
    }
}</style>