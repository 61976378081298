<template>
  <div id="app">
    <!-- <frontPage/> -->
    <router-view></router-view>
    <FooterComponent/>
  </div>
</template>

<script>
import FooterComponent from './components/footer.vue'

export default {
  name: 'App',
  components: {
    FooterComponent,
  }
}
</script>

<style lang="less">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Vadodara:wght@100;200;300;400;500;600;700&display=swap');
</style>
