<!-- FaqList.vue -->
<template>
    <div class="container-main">
        <div class="banner-box">


            <div>
                <div class="container-faqh center">
                    <div class="left-box-faqh">
                        <p>Frequently Asked Questions.</p>
                    </div>
                    <!-- <div class="right-box-faqh">
                        <svg class="search-icon-faqh" width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.8164 10.6913H11.2239L11.0139 10.4888C11.7489 9.63378 12.1914 8.52378 12.1914 7.31628C12.1914 4.62378 10.0089 2.44128 7.31641 2.44128C4.62391 2.44128 2.44141 4.62378 2.44141 7.31628C2.44141 10.0088 4.62391 12.1913 7.31641 12.1913C8.52391 12.1913 9.63391 11.7488 10.4889 11.0138L10.6914 11.2238V11.8163L14.4414 15.5588L15.5589 14.4413L11.8164 10.6913ZM7.31641 10.6913C5.44891 10.6913 3.94141 9.18378 3.94141 7.31628C3.94141 5.44878 5.44891 3.94128 7.31641 3.94128C9.18391 3.94128 10.6914 5.44878 10.6914 7.31628C10.6914 9.18378 9.18391 10.6913 7.31641 10.6913Z"
                                fill="white" />
                        </svg>
                        <input v-model="searchTerm" @input="updateSearch" type="text" class="search-input-faqh"
                            placeholder="Search Here...">

                        <svg v-show="searchTerm != ''" @click="searchTerm = ''" class="clearSearch" width="30" height="30"
                            viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.5 2.25H5.25C4.7325 2.25 4.3275 2.5125 4.0575 2.91L0 9L4.0575 15.0825C4.3275 15.48 4.7325 15.75 5.25 15.75H16.5C17.325 15.75 18 15.075 18 14.25V3.75C18 2.925 17.325 2.25 16.5 2.25ZM14.25 11.6925L13.1925 12.75L10.5 10.0575L7.8075 12.75L6.75 11.6925L9.4425 9L6.75 6.3075L7.8075 5.25L10.5 7.9425L13.1925 5.25L14.25 6.3075L11.5575 9L14.25 11.6925Z"
                                fill="white" fill-opacity="0.8" />
                        </svg>

                    </div> -->
                </div>

                <!-- <div class="faq-container">
                    
                    <div v-for="faq in filteredFaqs" :key="faq.id" class="faq-item">
                        <span v-html="highlightSearchTerm(faq.question)"></span>
                    </div>
                </div> -->
            </div>

            <div class="container-faqspage center">

                <div v-for="(faq, index) in filteredFaqs" :key="index"
                    :class="{ 'faq-row': true, 'opened-question': faq.Opened }" @click="toggleFaq(index)"
                    >
                    <span class="question" v-html="highlightSearchTerm(faq.Question)"></span>
                    <div class="icon" @click.stop="toggleFaq(index)">
                        <svg v-if="!faq.Opened" class="vector" width="22" height="22" viewBox="0 0 19 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.633 9.53033C18.4859 9.67098 18.2863 9.75 18.0783 9.75H10.2334V17.25C10.2334 17.4489 10.1508 17.6397 10.0037 17.7803C9.85654 17.921 9.65701 18 9.44895 18C9.24089 18 9.04136 17.921 8.89424 17.7803C8.74712 17.6397 8.66447 17.4489 8.66447 17.25V9.75H0.819639C0.611581 9.75 0.412045 9.67098 0.264926 9.53033C0.117807 9.38968 0.0351562 9.19891 0.0351562 9C0.0351562 8.80109 0.117807 8.61032 0.264926 8.46967C0.412045 8.32902 0.611581 8.25 0.819639 8.25H8.66447V0.75C8.66447 0.551088 8.74712 0.360322 8.89424 0.21967C9.04136 0.0790178 9.24089 0 9.44895 0C9.65701 0 9.85654 0.0790178 10.0037 0.21967C10.1508 0.360322 10.2334 0.551088 10.2334 0.75V8.25H18.0783C18.2863 8.25 18.4859 8.32902 18.633 8.46967C18.7801 8.61032 18.8627 8.80109 18.8627 9C18.8627 9.19891 18.7801 9.38968 18.633 9.53033Z"
                                fill="black" />
                        </svg>
                        <svg v-else class="vector" width="22" height="2" viewBox="0 0 19 2" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.633 1.70711C18.4859 1.89464 18.2863 2 18.0783 2H0.819639C0.611581 2 0.412045 1.89464 0.264926 1.70711C0.117807 1.51957 0.0351562 1.26522 0.0351562 1C0.0351562 0.734784 0.117807 0.48043 0.264926 0.292893C0.412045 0.105357 0.611581 0 0.819639 0H18.0783C18.2863 0 18.4859 0.105357 18.633 0.292893C18.7801 0.48043 18.8627 0.734784 18.8627 1C18.8627 1.26522 18.7801 1.51957 18.633 1.70711Z"
                                fill="black" />
                        </svg>

                        <!-- {{ faq.opened ? '-' : '+' }} -->
                    </div>
                    <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                        <div class="faq-content" v-if="faq.Opened" @click.stop>
                            <p class="answer">{{ faq.Answer }}</p>
                        </div>
                    </transition>
                    <span class="bottom-margin"></span>
                </div>


            </div>


        </div>


        <!-- <div class="component-65 center">
            <div class="frame-285228">
                <div class="a-powerful-tool-you">Not able to find your answer?</div>
                <div class="write-to-us-we-will-get-back-to-you">
                    Write to us, we will get back to you.
                </div>
            </div>
            <div class="outline-cta">
                
                <input class="what-can-we-help-you-with" placeholder="What can we help you with?">
                
            </div>
            <div class="comments-input">
                
                <textarea class="write-your-comments-here" placeholder="Write your comments here..."></textarea>
            </div>
            <div class="frame-285232">
                <img class="img-1" src="../assets/image/img_1.png" />
            </div>
            <div class="cta">
                <div class="get-started">Submit</div>
                <svg class="arrow-forward" width="19" height="18" viewBox="0 0 19 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 3L8.4425 4.0575L12.6275 8.25H3.5V9.75H12.6275L8.4425 13.9425L9.5 15L15.5 9L9.5 3Z"
                        fill="white" />
                </svg>
            </div>
        </div> -->

        <BottomCtaComponent />
    </div>
</template>
  
<script>
import BottomCtaComponent from './bottomCta.vue';
import faqsList from '../json/faqs.json'


export default {
    name: 'faqsPage',
    components: {
        BottomCtaComponent
    },
    data() {
        return {
            faqs: faqsList.slice(),

            // isDragged: false,

            isFixed: false,

            searchTerm: '',

        };
    },
    mounted() {
        this.faqs = this.faqs.map((faq) => ({ ...faq, Opened: false }));


    },
    computed: {
        filteredFaqs() {
            if (!this.searchTerm) {
                return this.faqs;
            }
            const searchTermLowerCase = this.searchTerm.toLowerCase();
            return this.faqs.filter(faq =>
                faq.Question.toLowerCase().includes(searchTermLowerCase)
            );
        },
    },
    methods: {

        toggleFaq(index) {
            if (this.isDragged) { return; }
            this.faqs[index].Opened = !this.faqs[index].Opened;

            if (this.faqs[index].Opened) {
                this.faqs.forEach((faq, i) => {
                    if (i !== index) {
                        faq.Opened = false;
                    }
                });
            }

        },
        highlightSearchTerm(text) {
            if (!this.searchTerm) {
                return text;
            }

            const regex = new RegExp(`(${this.searchTerm})`, 'gi');
            return text.replace(regex, '<span style="background-color:yellow">$1</span>');
        },
        updateSearch() {
        },
        beforeEnter(el) {
            el.style.height = '0';
            el.style.paddingTop = '0';
            el.style.paddingBottom = '0';
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px';
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px';
        },
        leave(el) {
            el.style.height = '0';
        },
    },
};
</script>
  
<style scoped lang="less">
// @import url('https://fonts.googleapis.com/css?family=Inconsolata|Oswald');


.container-faqh {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 20px;
    padding-bottom: 30px;
    padding-top: 30px;
    //   background-color: #f0f0f0;
    border-bottom: 1px solid #ccc;

}

.left-box-faqh {
    width: 50%;
    color: var(--cloudworks-neutral-white, #ffffff);
    text-align: left;
    font: var(--pri-web-heading-h-2-medium,
            500 36px/52px WorkSans-Medium,
            sans-serif);
    position: relative;
}



.right-box-faqh {
    width: 50%;
    display: flex;
    position: relative;
    border-radius: 4px;
    border-style: solid;
    border-color: var(--cloudworks-neutral-white, #ffffff);
    border-width: 0px 0px 1px 0px;
    background-color: #0c5c6a;

}

.search-icon-faqh {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    //   flex-shrink: 0;
    width: 18px;
    height: 18px;
    //   position: relative;
    //   overflow: visible;
}

.search-icon {

    font-size: 18px;
    color: #999;
}

.search-input-faqh {
    background-color: #0c5c6a;
    // border-radius: 4px;
    // border-style: solid;
    // border-color: var(--cloudworks-neutral-white, #ffffff);
    // border-width: 0px 0px 0px 0px;
    padding: 12px 16px 12px 16px;
    width: 100%;
    padding: 10px;
    //   border: 1px solid #ccc;
    border-radius: 5px;
    color: white;
    margin-left: 30px;
    border: none;
    outline: none;

    &::focus {
        border: none;
        outline: none;
    }
}



.search-input-faqh::placeholder {
    color: rgba(255, 255, 255, 0.5);
}



.clearSearch {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 7px;
    margin-right: 10px;
}


// .faq-container {
//   margin-top: 20px;
// }

// .faq-item {
//   margin-bottom: 10px;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
// }




.container-faqspage {
    width: 80%;
    margin: 20px auto;
    padding-bottom: 75px;

}

.faq-row {
    background-color: rgba(255, 255, 255, 0.75);
    transition: background-color 0.5s ease-in-out;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    // margin-bottom: 10px;
    // padding: 10px;
    position: relative;
    cursor: pointer;

    // display: flex;
    // width: 1140px;
    padding: 20px 24px 0px 24px;

}

.opened-question {
    background-color: rgba(255, 255, 255);
    color: #0C5C6A;
    transition: background-color 0.5s ease-in-out;
}


// .fade-enter-active,
// .fade-leave-active {
//     transition: max-height 3s ease-in-out;
// }

// .fade-enter,
// .fade-leave-to {
//     max-height: 0;
//   overflow: hidden;
// }


.bottom-margin {
    position: relative;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    // margin-left: 20px;
    padding-top: 20px;
    // width: 100%;
}

.question {
    color: var(--cloudworks-grey-original, #545D7D);

    /* Pri -WEB/Heading H6/Medium */
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 140% */
    letter-spacing: -0.4px;

}

.answer {
    color: var(--cloudworks-neutral-black, #000);

    /* Sec -WEB/Paragraph Regular/Regular */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.faq-content {
    // max-height: 0;
    transition: height 0.5s ease-in-out;
    overflow: hidden;
    background-color: #fff;
    color: #333;
    padding: 25px;
    // margin-left: -23px;
    // margin-right: -24px;
    // margin-bottom: -20px;
    margin-top: 15px;

}

.icon {
    position: absolute;
    top: 50%;
    right: 23px;
    transform: translateY(-50%);
    cursor: pointer;
}


/* css from contact page */


@import '../css/font.css';


.center {
    margin: 0 auto;
    width: 80%;
}

.container-main {
    margin: 0 auto;
    background: #F2F3F4;
}


.banner-box {
    overflow: hidden;
    width: 100%;
    background: url('../assets/image/main-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 80%;


}








//recent

.frame-2608797,
.frame-2608797 * {
    box-sizing: border-box;
}

.frame-2608797 {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}

.frame-285232 {
    flex-shrink: 0;
    width: 604px;
    height: 668px;
    position: relative;
    overflow: hidden;
}

.image-63 {
    width: 1002px;
    height: 668px;
    position: absolute;
    left: calc(50% - 501px);
    top: 0px;
}

.frame-2608796 {
    flex-shrink: 0;
    width: 486px;
    height: 668px;
    position: relative;
}

.frame-2608795 {
    width: 486px;
    height: 556px;
    position: absolute;
    left: 0px;
    top: 112px;
}

// .outline-cta {
//     background: var(--cloudworks-neutral-white, #ffffff);
//     border-style: solid;
//     border-color: #545d7d;
//     border-width: 0px 0px 1px 0px;
//     padding: 12px 16px 12px 16px;
//     display: flex;
//     flex-direction: row;
//     gap: 16px;
//     align-items: center;
//     justify-content: flex-start;
//     width: 486px;
//     position: absolute;
//     left: 0px;
//     top: 256px;
// }



.arrow-drop-down {
    flex-shrink: 0;
    position: relative;
    overflow: visible;
}







.cta {
    background: var(--product-color-live-desk-dark, #0c5c6a);
    border-radius: 4px;
    padding: 12px 16px 12px 16px;
    display: flex;
    flex-direction: row;
    // gap: 10px;
    align-items: center;
    // justify-content: center;
    position: relative;
    max-width: 25%;
    // left: 0px;
    // top: 508px;
}

.arrow-back {
    flex-shrink: 0;
    position: relative;
    overflow: visible;
}

.get-started {
    color: var(--cloudworks-neutral-white, #ffffff);
    text-align: center;
    font: var(--sec-web-paragraph-regular-medium,
            500 16px/24px "Roboto",
            sans-serif);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-forward {
    flex-shrink: 0;
    position: relative;
    overflow: visible;
}

.a-powerful-tool-you {
    color: var(--cloudworks-neutral-white, #ffffff);
    text-align: left;
    font: var(--pri-web-display-large-large,
            600 64px/82px "Work Sans",
            sans-serif);
    // position: absolute;
    // left: 0px;
    // top: 0px;
}



.component-65,
.component-65 * {
    box-sizing: border-box;
}

.component-65 {
    width: 1140px;
    max-width: 100vw;
    height: 485px;
    position: relative;
    // margin-bottom: 50px;
}

.frame-285228 {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    right: 50.26%;
    left: 0%;
    width: 49.74%;
    bottom: 80.1%;
    top: 0%;
    height: 19.9%;
}

.a-powerful-tool-you {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--pri-web-heading-h-2-medium,
            500 36px/52px "WorkSans-Medium",
            sans-serif);
    position: relative;
}

.write-to-us-we-will-get-back-to-you {
    color: var(--cloudworks-grey-dark-shade-01, #111133);
    text-align: left;
    font: var(--sec-web-paragraph-regular-regular,
            400 16px/24px "Roboto-Regular",
            sans-serif);
    position: relative;
    width: 567px;
}

.outline-cta {
    background: var(--cloudworks-neutral-white, #ffffff);
    border-style: solid;
    border-color: var(--cloudworks-grey-original, #545d7d);
    border-width: 0px 0px 1px 0px;
    padding: 12px 16px 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    right: 57.37%;
    left: 0%;
    width: 42.63%;
    bottom: 61.17%;
    top: 21.18%;
    height: 9.65%;
}

.what-can-we-help-you-with {
    color: var(--cloudworks-surface-dark-shade-06, #30333e);
    text-align: left;
    font: var(--sec-web-paragraph-regular-regular,
            400 16px/24px "Roboto-Regular",
            sans-serif);
    opacity: 0.5;
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    //   border-color: white;
    // border-width: 0pc;
    border: none;

    &:focus {
        border: none;
        outline: none;
        // color: #000000;
        opacity: 1;

        &::placeholder {
            color: transparent;
        }
    }

}

.arrow-drop-down {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    position: relative;
    overflow: visible;
}

.comments-input {
    background: var(--cloudworks-neutral-white, #ffffff);
    border-style: solid;
    border-color: var(--cloudworks-grey-original, #545d7d);
    border-width: 0px 0px 1px 0px;
    padding: 12px 16px 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    right: 57.37%;
    left: 0%;
    width: 42.63%;
    bottom: 15.53%;
    top: 32.72%;
    height: 39.75%;
}

.write-your-comments-here {
    color: var(--cloudworks-surface-dark-shade-06, #30333e);
    text-align: left;
    font: var(--sec-web-paragraph-regular-regular,
            400 16px/24px "Roboto-Regular",
            sans-serif);
    opacity: 0.5;
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}


.comments-input textarea {
    border: none;
    outline: none;
    border-width: 0px;
    height: 100%;
    resize: none;

    &:focus {
        opacity: 1;

        &::placeholder {
            color: transparent;
        }
    }
}

.frame-285232 {
    position: absolute;
    right: 0%;
    left: 56.75%;
    width: 48.25%;
    bottom: 0%;
    top: 0%;
    height: 100%;
    overflow: hidden;
}

// .img-1 {
// //   width: 648px;
// //   height: 648px;
// //   position: absolute;
// //   right: 0px;
// //   top: -104px;
// }
.cta {
    background: var(--product-color-live-desk-dark, #0c5c6a);
    border-radius: 4px;
    padding: 12px 16px 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 84.04%;
    left: 0%;
    width: 11.96%;
    bottom: 0%;
    top: 74.35%;
    height: 8.65%;
}

.get-started {
    color: var(--cloudworks-neutral-white, #ffffff);
    text-align: center;
    font: var(--sec-web-paragraph-regular-medium,
            500 16px/24px "Roboto-Medium",
            sans-serif);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-forward {
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    position: relative;
    overflow: visible;
}


@media screen and (max-width:900px) {
    .container-faqh {
        flex-direction: column;
        text-align: start;
        align-items: start;

        .left-box-faqh,
        .right-box-faqh {
            width: 100%;
        }

        .left-box-faqh {
            font-size: 28px;
        }
    }

    .comments-input,
    .frame-285228,
    .outline-cta,
    .cta,
    .component-65 {
        position: unset;
        width: unset;
        height: unset;
    }

    .comments-input {
        margin-top: 10px;
        height: 172px;
    }

    .cta {
        max-width: max-content;
        margin-top: 10px;
    }

    .frame-285232 {
        display: none;
    }

    .component-65 {
        padding-block: 20px;
    }

    .center {
        width: 90%;
    }
}
</style>
  