<template>
    <div class="price-box-mobile">
        <!-- Starter -->
        <div class="price-card starter">
            <div class="package-name">Starter Package</div>
            <div class="price">£ 9.99</div>
            <div class="price-for-user">
                Price per user
                <img src="../assets/svg/about.svg">
            </div>
            <div class="btn" @click="$router.push('/contact')">Get Started</div>
            <div class="options">
                <div class="option">
                    <img class="check"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB7SURBVHgB7ZJBDYAwEARXAhIqAUcgAQfgABwgAQlIQEIlIKFsk33wKI/SS0hIJ9lHm83kcjmg8h9CCI7pYAFFDeNjUIpkh4QOpVCyZclYXJ7K/F+ZM2sylvfUBHyPkrXIIbUjySI93qCT8BLPkg0o4SaNTLDA9Hgr33EBqrKTm5JDiVgAAAAASUVORK5CYII=">
                    <p>300 Users</p>
                    <img src="../assets/svg/about.svg">
                </div>
            </div>
            <div>
                <div class="btn-show_more">
                    Show More
                </div>
            </div>

        </div>
        <!-- Starter -->
        <div class="price-card standard">
            <div class="package-name">Standard Package</div>
            <div class="price">£ 10.99</div>
            <div class="price-for-user">
                Price per user
                <img src="../assets/svg/about.svg">
            </div>
            <div class="btn" @click="$router.push('/contact')">Get Started</div>
            <div class="options">
                <div class="option">
                    <img class="check"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB7SURBVHgB7ZJBDYAwEARXAhIqAUcgAQfgABwgAQlIQEIlIKFsk33wKI/SS0hIJ9lHm83kcjmg8h9CCI7pYAFFDeNjUIpkh4QOpVCyZclYXJ7K/F+ZM2sylvfUBHyPkrXIIbUjySI93qCT8BLPkg0o4SaNTLDA9Hgr33EBqrKTm5JDiVgAAAAASUVORK5CYII=">
                    <p>500 Users</p>
                    <img src="../assets/svg/about.svg">
                </div>
            </div>
            <div>
                <div class="btn-show_more">
                    Show More
                </div>
            </div>

        </div>
        <!-- Starter -->
        <div class="price-card enterprise">
            <div class="package-name">Enterprise Package</div>
            <div class="btn" @click="$router.push('/contact')">Contact Us</div>
            <div class="price-for-user">
                For the pricing
                <img src="../assets/svg/about.svg">
            </div>
            <div class="options">
                <div class="option">
                    <img class="check"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB7SURBVHgB7ZJBDYAwEARXAhIqAUcgAQfgABwgAQlIQEIlIKFsk33wKI/SS0hIJ9lHm83kcjmg8h9CCI7pYAFFDeNjUIpkh4QOpVCyZclYXJ7K/F+ZM2sylvfUBHyPkrXIIbUjySI93qCT8BLPkg0o4SaNTLDA9Hgr33EBqrKTm5JDiVgAAAAASUVORK5CYII=">
                    <p>>500 Users</p>
                    <img src="../assets/svg/about.svg">
                </div>
            </div>
            <div>
                <div class="btn-show_more">
                    Show More
                </div>
            </div>

        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="less">
@media screen and (min-width:900px) {
    .price-box-mobile {
        display: none;
    }
}
.price-box-mobile {
    margin-top: 30px;

    .price-card {
        border-radius: 12px;
        border: 4px solid #D5DBE0;
        width: 90%;
        margin: auto;
        margin-top: 15px;
        padding: 30px 40px;
        background-color: #fff;

        .package-name {
            font-weight: 600;
            font-size: 24px;
        }

        .price {
            font-size: 36px;
            font-weight: 600;
            margin-left: 8px;
        }

        .price-for-user {
            font-weight: 600;
            color: #545D7D;
            display: flex;
            gap: 6px;
            align-items: center;
            cursor: pointer;
        }

        .btn {
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 12px 38px;
            text-align: center;
            border-radius: 4px;
            border: 1px solid none;
            cursor: pointer;
            font-size: 20px;
            font-weight: 500;
        }

        .options {
            .option {
                display: flex;
                gap: 20px;
                font-size: 16px;
                font-weight: 600;
                padding-block: 8px;
                border-top: 1px solid #D5DBE0;
                border-bottom: 1px solid #D5DBE0;
                align-items: center;
            }
        }

        .btn-show_more {
            padding: 12px 38px;
            text-align: center;
            margin-top: 20px;
            font-weight: 600;
            font-size: 16px;
            border-radius: 12px;
        }



        p {
            margin-bottom: 0;
        }
    }

}




.starter {
    .price {
        color: #E05288;
    }

    .btn {
        color: #545D7D;
        border-color: #BCC5EA;

    }

    .option {
        .check {
            border-radius: 300px;
            background-color: #E05288;
            width: 16px;
            height: 16px;
        }
    }

    .btn-show_more {
        color: #E05288;
        background-color: #fceef3;
    }
}

.standard {
    .price {
        color: #5298E0;
    }

    .btn {
        background-color: #5298E0;
        color: #fff;
    }

    .option {
        .check {
            border-radius: 300px;
            background-color: #5298E0;
            width: 16px;
            height: 16px;
        }
    }

    .btn-show_more {
        color: #5298E0;
        background-color: #eef5fc;
    }
}

.enterprise {
    .price {
        color: #255A91;
    }

    .btn {
        background-color: #255A91;
        color: #fff;
        margin-bottom: 10px !important;
    }

    .option {
        .check {
            border-radius: 300px;
            background-color: #255A91;
            width: 16px;
            height: 16px;
        }
    }
    .price-for-user{
        margin-bottom: 10px;
    }
    .btn-show_more {
        color: #255A91;
        background-color: #eef5fc;
    }
}</style>