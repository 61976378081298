<template>
    <div class="container-main">
        <div class="banner-box">
            <div v-show="isLoading" class="loader-container">
                <div class="loader"></div>
            </div>
            <div ref="meetingsContainer" class="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/meetings/livedesk/scheduledemo?embed=true"></div>

        </div>


    </div>
</template>

<script>

export default {
    name: 'BookMeeting',
    components: {

    },
    data() {
        return {
            isLoading: true,
            isFixed: false,
            meetingsScriptLoaded: false,
            
        }
    },
    mounted() {

        if (!this.meetingsScriptLoaded) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
            script.async = true;
            script.onload = this.loadMeetingsEmbed;

            document.head.appendChild(script);

            this.meetingsScriptLoaded = true;
            // this.isLoading=false;
        } else {
            this.loadMeetingsEmbed();
            // this.isLoading=false;
        }
        // this.isLoading=false;
    },
    methods: {
        loadMeetingsEmbed() {

            if (window.HubSpotConversations) {
                window.HubSpotConversations.run({
                    selector: '.meetings-iframe-container',
                    openInNewTab: false,
                    openSchedulerInNewTab: false,
                });

                window.HubSpotConversations.widget.load('https://meetings-eu1.hubspot.com/meetings/livedesk/scheduledemo?embed=true');
            }
            this.isLoading=false;
        },
    },

}
</script>

<style scoped lang="less">
.center {
    margin: 0 auto;
    width: 80%;
}

.container-main {
    margin: 0 auto;
    background: #F2F3F4;
}
.loader-container {
  display: flex;
  align-items: center;
//   justify-content: center;
//   height: 100vh;
}

.loader {
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid #3498db; /* Blue border, change the color as needed */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.banner-box {
    overflow: hidden;
    width: 100%;
    background: url('../assets/image/main-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 80%;


}



// upper


.component-66,
.component-66 * {
    box-sizing: border-box;
}

.component-66 {
    width: 100%;
    height: 100%;
    position: relative;
}

.termsUpper {
    width: 100vw;
    /* Set the width to 100% of the viewport width */
    height: auto;
    /* Set your desired fixed height */
    overflow: hidden;
}

.rectangle-1079 {
    height: auto;
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
    overflow: visible;
}

.group-626584 {
    height: auto;
    position: absolute;
    right: 10.42%;
    left: 48.96%;
    width: 40.62%;
    bottom: 0%;
    top: 15.15%;
    height: 84.85%;
    overflow: visible;
}

.our-home-based-advisors-provide-brilliant-service-for-leading-brands {
    color: var(--cloudworks-neutral-white, #ffffff);
    text-align: left;
    font: var(--pri-web-heading-h-2-medium,
            500 36px/52px "WorkSans-Medium",
            sans-serif);
    position: absolute;
    right: 55.49%;
    left: 10.42%;
    width: 34.1%;
    bottom: 37.58%;
    top: 15.15%;
    height: 47.27%;
}




// downward


.bottom-logos {
    width: 100%;
}

.component-67,
.component-67 * {
    box-sizing: border-box;
}

.component-67 {
    width: 1140px;
    height: 4800px;
    position: relative;
}

.terms {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--pri-web-heading-h-2-medium,
            500 36px/52px "WorkSans-Medium",
            sans-serif);
    position: absolute;
    right: 56.93%;
    left: 0%;
    width: 43.07%;
    bottom: 98.91%;
    top: 0%;
    height: 1.09%;
}

.these-terms-and-conditions-of-use-thereafter-the-terms-and-conditions-govern-your-use-of-the-sensee-co-uk-website-the-website-provided-by-sens-e-ltd-the-company-by-accessing-and-or-using-the-website-you-accept-without-limitation-or-qualification-the-following-terms-and-conditions {
    color: var(--grayscale-300, #45525c);
    text-align: left;
    font: var(--pri-web-heading-h-6-medium,
            500 20px/28px "WorkSans-Medium",
            sans-serif);
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 96.93%;
    top: 1.3%;
    height: 1.76%;
}

.frame-2608801 {
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 1.72%;
    height: 98.28%;
    overflow: hidden;
}

.paragraph {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 114px;
}

._1-permitted-use {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--pri-web-heading-h-2-medium,
            500 36px/52px "WorkSans-Medium",
            sans-serif);
    position: relative;
    align-self: stretch;
}

.you-have-a-non-exclusive-non-transferable-limited-revocable-right-to-use-the-website-solely-for-your-personal-educational-informational-and-entertainment-use-or-any-other-use-the-company-and-you-agreed-to-in-a-separate-written-agreement-you-will-not-use-the-website-for-any-other-purpose-including-any-commercial-purpose-without-the-company-s-express-prior-written-consent-for-example-you-will-not-authorise-any-other-person-to-i-co-brand-the-website-or-portion-thereof-or-ii-frame-the-website-or-portion-thereof-whereby-the-website-or-portion-thereof-will-appear-on-the-same-screen-with-a-portion-of-another-website-co-branding-means-to-display-a-name-logo-trademark-or-other-means-of-attribution-or-identification-of-any-party-in-such-a-manner-reasonably-likely-to-give-a-user-the-impression-that-such-other-party-has-the-right-to-display-publish-or-distribute-the-website-or-content-accessible-within-the-website-you-agree-to-cooperate-with-the-company-in-causing-any-unauthorised-co-branding-framing-or-linking-to-immediately-cease {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--sec-web-paragraph-large-regular,
            400 18px/28px "Roboto-Regular",
            sans-serif);
    position: relative;
    align-self: stretch;
}

.you-may-use-the-website-for-lawful-purposes-only-you-may-not-post-or-transmit-through-the-website-any-material-which-violates-or-infringes-in-any-way-upon-the-rights-of-others-which-is-unlawful-threatening-abusive-defamatory-libellous-invasive-of-privacy-or-publicity-rights-vulgar-obscene-profane-or-otherwise-objectionable-which-encourages-conduct-that-would-constitute-a-criminal-offence-give-rise-to-civil-liability-or-otherwise-violate-any-law-the-company-will-fully-cooperate-with-any-law-enforcement-authorities-or-court-order-requesting-or-directing-the-company-to-disclose-the-identity-of-anyone-posting-such-information-or-materials {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--sec-web-paragraph-large-regular,
            400 18px/28px "Roboto-Regular",
            sans-serif);
    position: relative;
    align-self: stretch;
}

.paragraph2 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 764px;
}

.paragraph3 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 1126px;
}

.paragraph4 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 1488px;
}

.paragraph5 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 1850px;
}

.paragraph6 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 2416px;
}

.paragraph7 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 2946px;
}

.paragraph8 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 3476px;
}

.paragraph9 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 3754px;
}

.paragraph10 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 4088px;
}

.paragraph11 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 780px;
    position: absolute;
    left: 360px;
    top: 4310px;
    margin-bottom: 100px;
}

.frame-2608800 {
    width: 293px;
    height: 913px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.frame-2608799 {
    background: var(--cloudworks-neutral-white, #ffffff);
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 0px;
    top: 114px;
}

// .t-c-sticky-nav {
//     padding: 16px 20px 16px 16px;
//     display: flex;
//     flex-direction: row;
//     gap: 12px;
//     align-items: center;
//     justify-content: flex-start;
//     flex-shrink: 0;
//     width: 293px;
//     position: relative;
//     //   background-color: #eee;
//     // background-color: #eee;
//     background: linear-gradient(to right, transparent 50%, #2aa1b6 50%);
//     background-size: 200% 100%;
//     transition: background-size 2s ease-in-out;

//     &:hover {
//         /* Hover effect styles */
//         //   background-size: 100% 100%;
//         background-position: 100% 0;
//         //   background-color: #2aa1b6;

//         /* Fully cover the background on hover */
//     }


// }

.t-c-sticky-nav {
    position: relative;
    padding: 16px 20px 16px 16px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 293px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: rgba(82, 203, 224, 0.1);
        transition: width 0.3s ease-in-out;
    }

    &:hover::before {
        width: 100%;
    }

    &:hover .fill {
        opacity: 1;
    }

}


.fill {
    background: var(--product-color-live-desk-light, #52cbe0);
    opacity: 0;
    flex-shrink: 0;
    width: 2px;
    height: 60px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.stick {
    background: var(--product-color-live-desk-medium, #2aa1b6);
    opacity: 0;
    flex-shrink: 0;
    width: 2px;
    height: 60px;
    position: absolute;
    left: 0px;
    top: 0px;
}

._01 {
    color: var(--cloudworks-surface-dark-shade-06, #30333e);
    text-align: right;
    font: var(--pri-web-heading-h-6-medium,
            500 20px/28px "WorkSans-Medium",
            sans-serif);
    opacity: 0.5;
    position: relative;
    width: 30px;
}

.permitted-use {
    color: var(--cloudworks-surface-dark-shade-06, #30333e);
    text-align: left;
    font: var(--pri-web-heading-h-6-medium,
            500 20px/28px "WorkSans-Medium",
            sans-serif);
    opacity: 0.5;
    position: relative;
}

.line-516 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-515 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-514 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-513 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-512 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-511 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-510 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-517 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-518 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-519 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.line-520 {
    border-style: solid;
    border-color: var(--cloudworks-surface-light-shade-06, #dae3fa);
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 261px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
}

.webmaster-sensee-co-uk-latest-version-posted-25-09-07 {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--sec-web-paragraph-small-regular,
            400 14px/20px "Roboto-Regular",
            sans-serif);
    position: absolute;
    left: 0px;
    top: 840px;
}

.Group-626612 {
    display: none;
}

@media screen and(max-width:900px) {

    .component-67,
    .component-67 * {
        position: unset;
        height: unset;
        width: unset;
        font-size: 16px;
        padding-inline: 10px;
    }

    .frame-2608800 {
        width: unset;
        height: unset;
        position: relative;
        left: 0px;
        top: 0px;
        order: 1;
        padding-inline: 0;
        margin-block: 10px;
    }

    .frame-2608801 {
        display: flex;
        flex-direction: column;
    }

    .frame-2608799 {
        background: var(--cloudworks-neutral-white, #ffffff);
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: start;
        justify-content: flex-start;
        position: unset;
        left: 0px;
        top: 114px;
        padding-inline: 0px;
    }

    .t-c-sticky-nav {
        position: relative;
        padding: 16px 20px 16px 16px;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        width: 100%;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: rgba(82, 203, 224, 0.1);
            transition: width 0.3s ease-in-out;
        }

        &:hover::before {
            width: 100%;
        }

        &:hover .fill {
            opacity: 1;
        }

    }

    .fill {
        background: var(--product-color-live-desk-light, #52cbe0);
        opacity: 0;
        flex-shrink: 0;
        width: 2px;
        height: 60px;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .stick {
        background: var(--product-color-live-desk-medium, #2aa1b6);
        opacity: 0;
        flex-shrink: 0;
        width: 2px;
        height: 60px;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    ._01 {
        color: var(--cloudworks-surface-dark-shade-06, #30333e);
        text-align: right;
        font: var(--pri-web-heading-h-6-medium,
                500 20px/28px "WorkSans-Medium",
                sans-serif);
        opacity: 0.5;
        position: relative;
        width: 30px;
    }

    .these-terms-and-conditions-of-use-thereafter-the-terms-and-conditions-govern-your-use-of-the-sensee-co-uk-website-the-website-provided-by-sens-e-ltd-the-company-by-accessing-and-or-using-the-website-you-accept-without-limitation-or-qualification-the-following-terms-and-conditions {
        font-size: 18px;
    }

    [class^="paragraph"] * {
        font-size: 16px;
        padding-inline: 0;
    }

    [class^="line-"] {
        width: 90%;
    }

    [class^="paragraph"] {
        padding-inline: 0;
        order: 2;
    }

    ._1-permitted-use,
    .terms {
        font-size: 28px;
        font-weight: 600;
        margin-top: 20px;
        padding-inline: 0;

    }

    .terms {
        font-weight: 500;
        font-size: 36px;
    }

    .webmaster-sensee-co-uk-latest-version-posted-25-09-07 {
        margin-block: 10px;
        font-size: 14px;
    }

    .component-66 {
        display: none;
    }

    .Group-626612 {
        display: block;
        width: 100%;
        background-image: url('../assets/image/Rectangle-685.png');
        background-size: 100% 100%;

        p {
            color: #fff;
            font-size: 28px;
            padding: 10px;
            padding-top: 50px;
        }
    }
}
</style>