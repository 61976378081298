<template>
    <div>

        <div class="footer">
            <div class="footer-top">
                <div class="top center">
                    <div class="logo">
                        <div class="logo-top">
                            <img src="../assets/image/livedesk-logo-light.png" alt="">
                            <!-- <span class="brandName Left-Vadodara-18">LiveDesk</span> -->
                        </div>
                        <!-- <div class="logo-description Regular">Unified Macro-Collaboration Toolkit<br /> for Contact Centre
                            Hybrid Teams</div> -->
                    </div>
                    <div class="right-container">
                        <div class="email">
                            <div class="email-item" v-for="(item, index) in emails" :key="index">
                                <div class="title Left-Vadodara-16">{{ item.title }}</div>
                                <div class="bottom" @click="mailOpen(item.emailAdress)">
                                    <img class="email-icon" src="../assets/svg/email_black.svg" />
                                    <div class="email-adress Regular">{{ item.emailAdress }}</div>
                                </div>
                            </div>

                        </div>
                        <div class="office-address">
                            Head Office Address:
                        </div>
                        <div class="office-address-content">
                            The Clockwork Building, 45 Beavor Lane, London W6 9AR
                        </div>
                    </div>


                </div>


                <!-- <div class="middle">
                    <div class="flex-box center">
                        <div class="left">
                            <div class="inputbox">
                                <input type="text" placeholder="Email">
                                <div class="btn Left-Vadodara-16">Subscribe</div>
                            </div>
                            <div class="description Left-Vadodara-12">No spam, notifications only about new products,
                                updates<br />and freebies. You can always unsubscribe.</div>
                        </div>
                        <div class="right">
                            <img src="../assets/image/Facebook.png">
                            <img src="../assets/image/Facebook-1.png">
                            <img src="../assets/image/Facebook-2.png">
                            <img src="../assets/image/Facebook-3.png">
                        </div>
                    </div>
                </div> -->

                <div class="bottom">
                    <div class="left-menu">
                        <a v-for="(item, index) in bottommenu" :href="getHref(item)" :key="index"
                            class="Left-Vadodara-12">{{
                                item.title }}</a>
                    </div>
                    <div class="right-rights Left-Vadodara-12">© 2024, Cloudworks. All Rights Reserved.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterComponent',
    data() {
        return {
            bottommenu: [
                { title: 'Terms', path: '/termsPage' },
                { title: 'Privacy', path: '/privacyPage' },
            ],
            emails: [
                { title: 'For Sales', emailAdress: 'sales@cloudworks.cc' },
                { title: 'For General Info', emailAdress: 'info@cloudworks.cc' }
            ],
        }
    },
    methods: {
        getHref(item) {
            return item.path;
        },
        mailOpen(email) {
            window.open(`mailto:${email}`, '_blank')
        }
    },
    components: {
    }
}
</script>

<style scoped lang="less">
.center {
    margin: 0 auto;
    width: 80%;
}



.footer {

    height: 240px;
    width: 100%;
    // background: linear-gradient(0deg, #000000, #000000);
    background: rgba(15, 54, 60, 255);

    .footer-top {
        padding-top: 50px;

        width: 100%;
        height: 175px;
        // background: linear-gradient(0deg, rgba(82, 203, 224, 0.2), rgba(82, 203, 224, 0.2));
        // background: rgba(12, 92, 106, 0.50);

        .top {
            margin-bottom: 35px;
            display: flex;
            // background: rgba(12, 92, 106, 0.50);



            @media (max-width: 767px) {
                flex-direction: column;
            }

            justify-content: space-between;
        }

        .logo-top {
            display: flex;

            @media (max-width: 767px) {
                flex-direction: column;
            }

            align-items: center;

            img {
                width: 100%;
                height: 52%;
            }

            .brandName {
                margin-left: 10px;
                font-family: Hind Vadodara;
                font-size: 18px;
                font-weight: 600;
                line-height: 34px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;

            }

        }

        .logo-description {
            // display: none;
            visibility: hidden;
            margin-top: 10px;
            color: #FFFFFF80;
        }


        .right-container{
            
            display: flex;
            flex-direction: column;
        }

        .office-address{
            margin: 20px 0 0 0;
            color: #FFFFFF99;
        }

        .office-address-content{
            margin: 5px 0 0 0;
            color: #D5DBE0;
        }

        .email {
            display: flex;
            cursor: pointer;

            @media (max-width: 767px) {
                flex-direction: column;
            }

            .email-item {
                &:nth-child(2) {
                    margin-left: 20px;
                }

                .title {
                    color: #FFFFFF99;
                }

                .bottom {
                    background: #FFFFFF0D;
                    padding: 1px;
                    display: flex;

                    @media (max-width: 767px) {
                        flex-direction: column;
                    }

                    border: 1px solid #0C5C6A;
                    border-radius: 4px;
                    margin-top: 10px;

                    .email-icon {
                        width: 45px;
                        height: 45px;
                        padding: 11px 11px 11px 11px;
                        border-right: 1px solid #0C5C6A;
                    }

                    .email-adress {
                        padding: 10px 15px 5px 15px;
                        color: #D5DBE0;
                        flex: 1;
                    }
                }
            }
        }

        .middle {
            margin-top: 50px;
            background: #0C5C6A80;
            padding: 40px 0px;

            .flex-box {
                display: flex;

                @media (max-width: 900px) {
                    flex-direction: column;
                }

                justify-content: space-between;
            }

            .inputbox {
                display: flex;

                .btn {
                    cursor: pointer;
                    //styleName: Menu Text;
                    font-family: Hind Vadodara;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #fff;
                    padding: 7px 20px;
                    background: #0C5C6A;
                    border: 1px solid #0C5C6A;
                    border-radius: 4px;
                    box-sizing: border-box;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                input {
                    box-sizing: border-box;
                    padding: 6px 0px 6px 18px;
                    border-radius: 4px;
                    width: 310px;
                    background-color: #0f363c;
                    color: #ffffff7c;
                    border: 1px solid #0C5C6A;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            .left {
                display: flex;

                @media (max-width: 767px) {
                    flex-direction: column;
                }

                .description {
                    color: #FFFFFF80;
                    margin-left: 30px;
                    //styleName: Menu Text Small;
                    font-family: Hind Vadodara;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    margin-top: 0px;
                }
            }

            .right {
                margin-right: 15px;
                display: flex;
                align-items: center;

                img {
                    width: 24px;
                    height: 24px;
                    margin-left: 10px;
                }
            }
        }

        .bottom {
            // margin-top: 35px;
            padding: 5px;
            padding-left: 175px;
            padding-right: 175px;
            display: flex;
            background: rgba(16, 41, 45, 255);

            @media (max-width: 767px) {
                flex-direction: column;
            }

            justify-content: space-between;

            .left-menu {
                a {
                    font-family: Hind Vadodara;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #fff;
                    margin-right: 40px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .right-rights {
                font-family: Hind Vadodara;
                margin-top: auto;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: right;
                color: #FFFFFFCC;
            }
        }
    }
}

@media screen and (max-width:900px) {
    .center {
        width: 90%;
    }

    .footer {
        height: unset;

        .footer-top {
            background-color: unset;
            height: unset;

            .top {
                margin-bottom: 20px;
            }

            .logo-top {
                align-items: start;
            }

            .email {
                .email-item {
                    margin-left: 0 !important;

                    .bottom {
                        flex-direction: row !important;
                    }
                }
            }



            .middle {
                background-color: #0f363c;
                margin-top: 0;

                .flex-box {
                    .left {
                        flex-direction: column;

                        .description {
                            margin-block: 15px;
                            margin-left: 0;
                        }
                    }

                    .right {
                        margin-top: 10px;

                        img:first-of-type {
                            margin-left: 0;
                        }

                    }

                }
            }

            .bottom {
                background-color: #10292d;
                width: 100%;
                flex-direction: column !important;
                padding: 20px;

                .right-rights {
                    text-align: start;
                    margin-block: 5px;
                }
            }
        }
    }
}

@media screen and (max-width:400px) {
    input {
        width: 250px !important;
        max-width: 250px !important;
    }
}
</style>