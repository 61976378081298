<template>
    <div class="top-small-nav-bar">
        <div class="top-small-nav-bar-child" />
        <div class="cloudworks-button-parent">
            <div class="cloudworks-button1" @click="cloudworksClick">
                <div class="cloudworks-button-item" />
                <div class="div15">Cloudworks</div>
            </div>
            <div class="livedesk-button1" >
                <div class="livedesk-button-item" />
                <div class="div16">LiveDesk</div>
            </div>
            <div class="teamtonic-button1" @click="teamtonicClick">
                <div class="teamtonic-button-item" />
                <div class="div17">TeamTonic</div>
            </div>
        </div>
        <div class="btn-small-english1">
            <div class="english1">English</div>
            <img class="vector-icon1" alt="" src="" />
            <img class="arrow-drop-down-black-24dp-1-icon1" alt="" src="" />
        </div>
        <div class="btn-small-operation-status2">
            <img class="btn-small-operation-status-item" alt="" src="" />
            <div class="operational-status1">Operational Status</div>
        </div>

    </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "TopNavBar",
    methods: {
        cloudworksClick() {
            window.location.href = "https://cloudworks.cc/";
        },
        teamtonicClick() {
            window.location.href = "https://teamtonic.cc/";
        },
    },
});
</script>
<style scoped>




.top-small-nav-bar-child {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(rgba(37, 90, 145, 0.3), rgba(82, 203, 224, 0.3)),
        #000;

        
}

/* tab color : 0C5C6A */

.cloudworks-button-item {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #000;
    opacity: 0;
}

.div15 {
    position: relative;
    font-weight: 600;
    display: inline-block;
    min-width: 85px;
    z-index: 1;
}

.cloudworks-button1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 9px 24px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.livedesk-button-item {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #0c5c6a !important;
    opacity: 0;
}

.div16 {
    position: relative;
    font-weight: 600;
    display: inline-block;
    min-width: 64px;
    z-index: 1;
}

.livedesk-button1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 30px 10px;
    position: relative;
    z-index: 1;
    text-align: left;
    color: #fff;
    background-color: #0c5c6a !important;
    cursor: default;
}

.teamtonic-button-item {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    /* background-color: #255a91; */
}

.div17 {
    position: relative;
    font-weight: 600;
    display: inline-block;
    min-width: 77px;
    z-index: 1;
}

.teamtonic-button1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 23px 10px 24px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    /* color: #fff; */
}

.cloudworks-button-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 20px;
    max-width: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
}

.english1 {
    position: absolute;
    top: 2px;
    left: 40px;
    line-height: 24px;
}

.vector-icon1 {
    position: absolute;
    height: 66.67%;
    width: 18.18%;
    top: 16.67%;
    right: 72.73%;
    bottom: 16.67%;
    left: 9.09%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.arrow-drop-down-black-24dp-1-icon1 {
    position: absolute;
    top: 7px;
    left: 89px;
    width: 16px;
    height: 16px;
    overflow: hidden;
}

.btn-small-english1 {
    height: 30px;
    width: 110px;
    position: relative;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.05);
    display: none;
    z-index: 2;
}

.btn-small-operation-status-item {
    position: absolute;
    top: 5px;
    right: 146px;
    width: 20px;
    height: 20px;
}

.operational-status1 {
    position: absolute;
    top: 2px;
    left: 38px;
    line-height: 24px;
}

.btn-small-operation-status2 {
    height: 30px;
    width: 178px;
    position: relative;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.05);
    display: none;
    white-space: nowrap;
    z-index: 3;
}

.dialpad-icon1 {
    width: 18px;
    height: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.dialpad-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3px 0px 0px;
}

.toll-free1 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: Roboto;
    color: #a0b8ce;
    text-align: left;
    display: inline-block;
    min-width: 70px;
}

.div18 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: Roboto;
    color: #a0b8ce;
    text-align: left;
    display: inline-block;
    min-width: 99px;
}

.toll-free-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
}

.btn-small-operation-status3 {
    cursor: pointer;
    border: none;
    padding: 3px 12px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    z-index: 1;
}

.btn-small-operation-status-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 0px 0px;
}

.top-small-nav-bar {

    display: flex;

    width: 100vw;
    flex-direction: row;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    padding-left: 150px;
    box-sizing: border-box;
    position: relative;
    max-width: 100%;
    gap: 20px;
    z-index: 1;
    text-align: left;
    font-size: 16px;
    color: #7d93a7;
    font-family: "Hind Vadodara";
}

@media screen and (max-width: 1125px) {
    .top-small-nav-bar {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 825px) {
    .cloudworks-button-parent {
        flex-wrap: wrap;
    }

    .top-small-nav-bar {
        padding-left: 75px;
        padding-right: 75px;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 450px) {
    .top-small-nav-bar {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
}
</style>