<template>
    <div class="container-main">



        <div class="banner-box">

        </div>



        <!-- upper -->

        <div class="component-66 center">

            <img class="termsUpper" src="../assets/image/TermsUpper.png">

        </div>
        <div class="Group-626612 center">
            <p>Our home based advisors provide brilliant service for leading brands</p>
            <img class="termsUpper" src="../assets/image/Group-626612.png">
        </div>

        <!-- <div class="group-626585 center">
            <div class="privacy-notice">Privacy Notice</div>
            <div class="how-your-information-will-be-used">
                How your information will be used.
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">1</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    As your employer, the Company needs to keep and process information about
                    you for normal employment purposes. The information we hold and process
                    will be used for our management and administrative use only. We will keep
                    and use it to enable us to run the business and manage our relationship
                    with you effectively, lawfully and appropriately, during the recruitment
                    process, whilst you are working for us, at the time when your employment
                    ends and after you have left. This includes using information to enable us
                    to comply with the employment contract, to comply with any legal
                    requirements, pursue the legitimate interests of the Company and protect
                    our legal position in the event of legal proceedings. If you do not
                    provide this data, we may be unable in some circumstances to comply with
                    our obligations and we will tell you about the implications of that
                    decision.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">2</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    We may sometimes need to process your data to pursue our legitimate
                    business interests, for example to prevent fraud, administrative purposes
                    or reporting potential crimes. We will never process your data where these
                    interests are overridden by your own interests.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">3</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    Much of the information we hold will have been provided by you, but some
                    may come from other internal sources, such as your manager, or in some
                    cases, external sources, such as referees.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">4</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    The sort of information we hold includes your application form and
                    references, your contract of employment and any amendments to it;
                    correspondence with or about you, for example letters to you about a pay
                    rise or, at your request, a letter to your mortgage company confirming
                    your salary; information needed for payroll, benefits and expenses
                    purposes; contact and emergency contact details; records of holiday,
                    sickness and other absence; information needed for equal opportunities
                    monitoring policy; and records relating to your career history, such as
                    training records, appraisals, other performance measures and, where
                    appropriate, disciplinary and grievance records.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">5</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    You will, of course, inevitably be referred to in many company documents
                    and records that are produced by you and your colleagues in the course of
                    carrying out your duties and the business of the company.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">6</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    Where necessary, we may keep information relating to your health, which
                    could include reasons for absence and GP reports and notes. This
                    information will be used in order to comply with our health and safety and
                    occupational health obligations – to consider how your health affects your
                    ability to do your job and whether any adjustments to your job might be
                    appropriate. We will also need this data to administer and manage
                    statutory and company sick pay.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">7</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    Where we process special categories of information relating to your racial
                    or ethnic origin, political opinions, religious and philosophical beliefs,
                    trade union membership, biometric data or sexual orientation, we will
                    always obtain your explicit consent to those activities unless this is not
                    required by law or the information is required to protect your health in
                    an emergency. Where we are processing data based on your consent, you have
                    the right to withdraw that consent at any time.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">8</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    In addition, we monitor computer use, as detailed in our Employee
                    Handbook, available on Team Tonic . We also keep records of your hours of
                    work by way of Team Tonic.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">9</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    Other than as mentioned below, we will only disclose information about you
                    to third parties if we are legally obliged to do so or where we need to
                    comply with our contractual duties to you, for instance we may need to
                    pass on certain information to HMRC.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">10</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    We may transfer information about you to other group companies for
                    purposes connected with your employment or the management of the company’s
                    business.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">11</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    In limited and necessary circumstances, your information may be
                    transferred outside of the EEA or to an international organisation to
                    comply with our legal or contractual requirements.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">12</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    We do use automated decision making (including profiling) in limited
                    circumstances including Credit checks and Criminal background checks.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">13</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    Your personal data will be stored in accordance with our retention policy.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">14</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    If in the future we intend to process your personal data for a purpose
                    other than that which it was collected we will provide you with
                    information on that purpose and any other relevant information.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">15</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    Under the General Data Protection Regulation (GDPR) and The Data
                    Protection Act 2018 (DPA) you have a number of rights with regard to your
                    personal data. You have the right to request from us access to and
                    rectification or erasure of your personal data, the right to restrict
                    processing, object to processing as well as in certain circumstances the
                    right to data portability.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">16</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    If you have provided consent for the processing of your data you have the
                    right (in certain circumstances) to withdraw that consent at any time
                    which will not affect the lawfulness of the processing before your consent
                    was withdrawn.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">17</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    You have the right to lodge a complaint to the Information Commissioners’
                    Office if you believe that we have not complied with the requirements of
                    the GDPR or DPA 18 with regard to your personal data.<br />Identity and
                    contact details of controller and data protection officer
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">18</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    Sensée Ltd is the controller and processor of data for the purposes of the
                    DPA 18 and GDPR.
                </div>
            </div>
            <div class="privacy">
                <div class="frame-2608804">
                    <div class="_1">19</div>
                </div>
                <div
                    class="as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision">
                    If you have any concerns as to how your data is processed you can
                    contact:<br />dataprotection@sensee.co.uk
                </div>
            </div>
        </div> -->

        <div class="privacy-policy">
            <h1>Privacy Policy</h1>
            <p>Last updated: January 17, 2024</p>
            <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
                information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </p>
            <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
                collection
                and use of information in accordance with this Privacy Policy.</p>
            <h2>Interpretation and Definitions</h2>
            <h3>Interpretation</h3>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
                The
                following definitions shall have the same meaning regardless of whether they appear in singular or in
                plural.
            </p>
            <h3>Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
                <li>
                    <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our
                        Service.</p>
                </li>
                <li>
                    <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control
                        with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity
                        interest or
                        other securities entitled to vote for election of directors or other managing authority.</p>
                </li>
                <li>
                    <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;,
                        &quot;Us&quot;
                        or &quot;Our&quot; in this Agreement) refers to Sensée Ltd. trading as Cloudworks, Cloudworks, The Clockwork
                        Building 45 Beavor Lane, London W6 9AR.</p>
                </li>
                <li>
                    <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other
                        device by a website, containing the details of Your browsing history on that website among its many
                        uses.</p>
                </li>
                <li>
                    <p><strong>Country</strong> refers to: United Kingdom</p>
                </li>
                <li>
                    <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone
                        or a
                        digital tablet.</p>
                </li>
                <li>
                    <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable
                        individual.</p>
                </li>
                <li>
                    <p><strong>Service</strong> refers to the Website.</p>
                </li>
                <li>
                    <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf
                        of
                        the Company. It refers to third-party companies or individuals employed by the Company to facilitate
                        the
                        Service, to provide the Service on behalf of the Company, to perform services related to the Service
                        or
                        to assist the Company in analyzing how the Service is used.</p>
                </li>
                <li>
                    <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of
                        the
                        Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                </li>
                <li>
                    <p><strong>Website</strong> refers to LiveDesk, Cloudworks website or Teamtonic accessible from <a href="https://www.livedesk.cc"
                            rel="external nofollow noopener" target="_blank">https://www.livedesk.cc</a>, <a href="https://www.cloudworks.cc"
                            rel="external nofollow noopener" target="_blank">https://www.cloudworks.cc</a> or <a href="https://www.teamtonic.cc"
                            rel="external nofollow noopener" target="_blank">https://www.teamtonic.cc</a></p>
                </li>
                <li>
                    <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other
                        legal
                        entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                </li>
            </ul>
            <h2>Collecting and Using Your Personal Data</h2>
            <h3>Types of Data Collected</h3>
            <h4>Personal Data</h4>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that
                can
                be used to contact or identify You. Personally identifiable information may include, but is not limited to:
            </p>
            <ul>
                <li>
                    <p>Email address</p>
                </li>
                <li>
                    <p>First name and last name</p>
                </li>
                <li>
                    <p>Phone number</p>
                </li>
                <li>
                    <p>Usage Data</p>
                </li>
            </ul>
            <h4>Usage Data</h4>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser
                type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time
                spent
                on those pages, unique device identifiers and other diagnostic data.</p>
            <p>When You access the Service by or through a mobile device, We may collect certain information automatically,
                including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP
                address
                of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique
                device
                identifiers and other diagnostic data.</p>
            <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the
                Service by or through a mobile device.</p>
            <h4>Tracking Technologies and Cookies</h4>
            <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
                information. Tracking technologies used are beacons, tags, and scripts to collect and track information and
                to
                improve and analyze Our Service. The technologies We use may include:</p>
            <ul>
                <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can
                    instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You
                    do
                    not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your
                    browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
                <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small
                    electronic
                    files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that
                    permit
                    the Company, for example, to count users who have visited those pages or opened an email and for other
                    related website statistics (for example, recording the popularity of a certain section and verifying
                    system
                    and server integrity).</li>
            </ul>
            <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your
                personal
                computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your
                web
                browser. Learn more about cookies on the <a
                    href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking"
                    target="_blank">Free Privacy Policy website</a> article.</p>
            <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
            <ul>
                <li>
                    <p><strong>Necessary / Essential Cookies</strong></p>
                    <p>Type: Session Cookies</p>
                    <p>Administered by: Us</p>
                    <p>Purpose: These Cookies are essential to provide You with services available through the Website and
                        to
                        enable You to use some of its features. They help to authenticate users and prevent fraudulent use
                        of
                        user accounts. Without these Cookies, the services that You have asked for cannot be provided, and
                        We
                        only use these Cookies to provide You with those services.</p>
                </li>
                <li>
                    <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                    <p>Type: Persistent Cookies</p>
                    <p>Administered by: Us</p>
                    <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                </li>
                <li>
                    <p><strong>Functionality Cookies</strong></p>
                    <p>Type: Persistent Cookies</p>
                    <p>Administered by: Us</p>
                    <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
                        remembering your login details or language preference. The purpose of these Cookies is to provide
                        You
                        with a more personal experience and to avoid You having to re-enter your preferences every time You
                        use
                        the Website.</p>
                </li>
            </ul>
            <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies
                Policy
                or the Cookies section of our Privacy Policy.</p>
            <h3>Use of Your Personal Data</h3>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
                <li>
                    <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.
                    </p>
                </li>
                <li>
                    <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The
                        Personal Data You provide can give You access to different functionalities of the Service that are
                        available to You as a registered user.</p>
                </li>
                <li>
                    <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of
                        the
                        purchase contract for the products, items or services You have purchased or of any other contract
                        with
                        Us through the Service.</p>
                </li>
                <li>
                    <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent
                        forms
                        of electronic communication, such as a mobile application's push notifications regarding updates or
                        informative communications related to the functionalities, products or contracted services,
                        including
                        the security updates, when necessary or reasonable for their implementation.</p>
                </li>
                <li>
                    <p><strong>To provide You</strong> with news, special offers and general information about other goods,
                        services and events which we offer that are similar to those that you have already purchased or
                        enquired
                        about unless You have opted not to receive such information.</p>
                </li>
                <li>
                    <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
                </li>
                <li>
                    <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger,
                        divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of
                        Our
                        assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in
                        which Personal Data held by Us about our Service users is among the assets transferred.</p>
                </li>
                <li>
                    <p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data
                        analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and
                        to
                        evaluate and improve our Service, products, services, marketing and your experience.</p>
                </li>
            </ul>
            <p>We may share Your personal information in the following situations:</p>
            <ul>
                <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers
                    to
                    monitor and analyze the use of our Service, to contact You.</li>
                <li><strong>For business transfers:</strong> We may share or transfer Your personal information in
                    connection
                    with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or
                    a
                    portion of Our business to another company.</li>
                <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we
                    will
                    require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any
                    other
                    subsidiaries, joint venture partners or other companies that We control or that are under common control
                    with Us.</li>
                <li><strong>With business partners:</strong> We may share Your information with Our business partners to
                    offer
                    You certain products, services or promotions.</li>
                <li><strong>With other users:</strong> when You share personal information or otherwise interact in the
                    public
                    areas with other users, such information may be viewed by all users and may be publicly distributed
                    outside.
                </li>
                <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with
                    Your consent.</li>
            </ul>
            <h3>Retention of Your Personal Data</h3>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
                Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
                obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
                disputes, and enforce our legal agreements and policies.</p>
            <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for
                a
                shorter period of time, except when this data is used to strengthen the security or to improve the
                functionality
                of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
            <h3>Transfer of Your Personal Data</h3>
            <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other
                places where the parties involved in the processing are located. It means that this information may be
                transferred to — and maintained on — computers located outside of Your state, province, country or other
                governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
            <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement
                to
                that transfer.</p>
            <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
                accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization
                or
                a country unless there are adequate controls in place including the security of Your data and other personal
                information.</p>
            <h3>Delete Your Personal Data</h3>
            <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected
                about
                You.</p>
            <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
            <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one,
                and
                visiting the account settings section that allows you to manage Your personal information. You may also
                contact
                Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
            <p>Please note, however, that We may need to retain certain information when we have a legal obligation or
                lawful
                basis to do so.</p>
            <h3>Disclosure of Your Personal Data</h3>
            <h4>Business Transactions</h4>
            <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We
                will
                provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
            </p>
            <h4>Law enforcement</h4>
            <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so
                by
                law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
            <h4>Other legal requirements</h4>
            <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
            <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the Company</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect against legal liability</li>
            </ul>
            <h3>Security of Your Personal Data</h3>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the
                Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable
                means
                to protect Your Personal Data, We cannot guarantee its absolute security.</p>
            <h2>Children's Privacy</h2>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
                information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your
                child
                has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal
                Data
                from anyone under the age of 13 without verification of parental consent, We take steps to remove that
                information from Our servers.</p>
            <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires
                consent
                from a parent, We may require Your parent's consent before We collect and use that information.</p>
            <h2>Links to Other Websites</h2>
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party
                link,
                You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of
                every
                site You visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any
                third
                party sites or services.</p>
            <h2>Changes to this Privacy Policy</h2>
            <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
                Privacy
                Policy on this page.</p>
            <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
                effective
                and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
                are
                effective when they are posted on this page.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <ul>
                <li>By email: support@cloudworks.cc</li>
            </ul>
        </div>


        <!-- <img src="../assets/image/Bottom-Logos.png" class="bottom-logos"/> -->

    </div>
</template>

<script>





export default {
    name: 'privacyPage',
    components: {

    },
    data() {
        return {

        }
    },
    methods: {

    }



}
</script>

<style scoped lang="less">
.center {
    margin: 0 auto;
    width: 80%;
}

.container-main {
    margin: 0 auto;
    background: #F2F3F4;
}

.banner-box {
    overflow: hidden;
    width: 100%;
    background: url('../assets/image/main-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 50%;

    .fixed-box {
        width: 100%;
        z-index: 99;
        background: #0C5C6A;

        &.active {
            position: fixed;
            background-color: rgba(255, 255, 255, 0.8);

            .menu {
                a {
                    color: #000000;

                    &:last-child {
                        background: linear-gradient(0deg, #2AA1B6, #2AA1B6);
                        border: .5px solid #2AA1B6;
                    }
                }
            }

            .header {
                padding-bottom: 16px;
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding-top: 16px;
        padding-bottom: 16px;

        .menu {
            a {
                //styleName: Sec -WEB/Paragraph Regular/Medium;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                line-height: 44.5px;
                letter-spacing: 0em;
                text-align: center;
                color: #FFFFFFCC;
                text-decoration: none;
                padding: 8px 16px;

                &.active {
                    border-bottom: 2px solid #fff;
                    font-weight: 600;
                }

                &:last-child {
                    margin-right: 0px;
                    border: .5px solid #fff;
                    padding: 8px 24px;
                    border-radius: 4px;
                }
            }
        }
    }

    .title {
        margin-top: 36px;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .price-box {
        background: #0C5C6A;
        border-radius: 16px;
        margin-top: 60px;

        .package {
            padding: 12px 0px;

            img {
                width: 18px;
                height: 18px;
                margin-left: 8px;
            }
        }

        .package-top {
            padding: 12px 20px;
            color: #FFFFFF80;

        }

        div {
            box-sizing: border-box;
        }

        .outer {
            background: #0D4953;
            border-radius: 16px;

            .item-box-standard {
                background: #F8F9F9;
            }

            .item-box-starter-middle {
                padding: 0px 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }

            .item-box-standard-middle {
                padding: 0px 30px;
                border-right: 8px solid #5298E0;
                border-left: 8px solid #5298E0;
                background: #F2F3F4;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #5298E0;
                font-weight: 600;
            }

            .item-box-enterprise-middle {
                padding: 0px 30px;
                margin-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }

            .package-middle {
                padding: 12px 20px;
                color: #fff;

                .position-box {
                    position: relative;
                    display: flex;
                }

                .tip {
                    position: absolute;
                    width: 200px;
                    left: 0px;
                    top: 60px;
                    border-radius: 0px 16px 16px 16px;
                    background: #0D4953;
                    padding: 8px;
                    transition: all .2s linear;
                    opacity: 0;

                    &.active {
                        transition: all .2s linear;
                        left: 30px;
                        top: 30px;
                        opacity: 100%;
                    }
                }
            }
        }

        .colum {
            .item-box-standard-top {
                padding: 0px 30px;
                border-top: 8px solid #5298E0;
                border-right: 8px solid #5298E0;
                border-left: 8px solid #5298E0;
                border-radius: 16px 16px 0px 0px;
                background: #F2F3F4;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .item-box-starter-top {
                padding: 0px 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }

            .item-box-enterprise-top {
                padding: 0px 30px;
                margin-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }

            display: flex;

            >div {
                width: 25%;
            }

            .package {
                display: flex;
                align-items: center;
            }

            .item-box-standard {
                border-left: 8px solid #5298E0;
                border-right: 8px solid #5298E0;
            }
        }

        .priceUser {
            >.colum {
                display: flex;
                flex-direction: column;

                .box {
                    display: flex;
                    width: 100%;

                    >div {
                        width: 25%;
                    }
                }

                .childList {
                    margin-left: 20px;
                    width: calc(100% - 20px);
                    box-sizing: border-box;
                    border-radius: 16px 0px 0px 16px;
                    overflow: hidden;
                    max-height: 0px;
                    transition: all .4s ease 0s;

                    &.active {
                        max-height: 300px;
                    }

                    >.colum {
                        &:nth-child(1) {
                            .border-padding {
                                border-radius: 16px 0px 0px 0px;
                            }
                        }

                        &:last-child {
                            .border-padding {
                                border-radius: 0px 0px 0px 16px;
                            }
                        }

                        >div {
                            background-color: #F2F3F4;
                            width: calc((100% + 20px)/4);

                            &:nth-child(1) {
                                width: calc((100% + 20px)/4 - 20px);
                            }

                            &.border-padding {
                                background: #0D4953;
                            }
                        }

                        &:last-child {
                            .item-box-standard {
                                border-bottom: none;
                                border-radius: 0px;
                            }

                            .item-box-starter {
                                border-radius: 0px;
                                margin-bottom: 0px;

                                .starter {
                                    border-bottom: none;
                                }
                            }

                            .item-box-enterprise {
                                margin-bottom: 0px;
                                border-radius: 0px;
                            }

                            .enterprise,
                            .standard,
                            .starter {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

            .position-box {
                position: relative;
                display: flex;
            }

            .tip {
                position: absolute;
                width: 200px;
                left: 0px;
                top: 60px;
                border-radius: 0px 16px 16px 16px;
                background: #0D4953;
                padding: 8px;
                transition: all .2s linear;
                opacity: 0;

                &.active {
                    transition: all .2s linear;
                    left: 30px;
                    top: 30px;
                    opacity: 100%;
                }
            }

            .expand {
                margin-left: 34px;
                color: #52CBE0;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: right;
                text-decoration: underline;
                cursor: pointer;
            }

            .btn {
                margin-bottom: 20px;
                margin-top: 8px;
                padding: 12px 38px;
                text-align: center;
                border-radius: 4px;
                border: 1px solid #BCC5EA;
                cursor: pointer;
            }

            .btn-standard {
                background: #5298E0;
                color: #FFFFFF;
            }

            .starter-check {
                img {
                    border-radius: 50%;
                    background: #E05288;
                }
            }

            .standard-check {
                img {
                    border-radius: 50%;
                    background: #5298E0;
                }
            }

            .enterprise-check {
                img {
                    border-radius: 50%;
                    background: #255A91;
                }
            }

            .border-padding {
                padding: 0 20px;
            }

            .colum {
                .item-box-enterprise {
                    margin-right: 8px;
                }

                .item-box {
                    background: #F8F9F9;
                    padding: 0px 30px;
                }

                background: #0F363C;

                &:nth-child(1) {
                    border-radius: 16px 16px 0px 0px;
                }

                &:last-child {
                    border-radius: 0px 0px 16px 16px;
                }

                .package {
                    border-bottom: 1px solid #4B686D80;
                    color: #fff;
                }

                .starter,
                .standard,
                .enterprise {
                    padding: 12px 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid #D5DBE0
                }

                &:last-child {
                    .item-box-standard {
                        border-bottom: 8px solid #5298E0;
                        border-radius: 0px 0px 10px 10px;
                    }

                    .item-box-starter {
                        border-radius: 0px 0px 0px 16px;
                        margin-bottom: 8px;

                        .starter {
                            border-bottom: none;
                        }
                    }

                    .item-box-enterprise {
                        margin-bottom: 8px;
                        border-radius: 0px 0px 16px 0px;
                    }

                    .enterprise,
                    .standard,
                    .starter {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}


.privacy-policy{
    padding: 50px;
    font: var(--sec-web-paragraph-large-regular,
            400 18px/28px "Roboto-Regular",
            sans-serif);
    
}



// upper


.component-66,
.component-66 * {
    box-sizing: border-box;
}

.component-66 {
    width: 100%;
    height: 100%;
    position: relative;
}

.termsUpper {
    width: 100vw;
    /* Set the width to 100% of the viewport width */
    height: auto;
    /* Set your desired fixed height */
    overflow: hidden;
}

.rectangle-1079 {
    height: auto;
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
    overflow: visible;
}

.group-626584 {
    height: auto;
    position: absolute;
    right: 10.42%;
    left: 48.96%;
    width: 40.62%;
    bottom: 0%;
    top: 15.15%;
    height: 84.85%;
    overflow: visible;

}

.our-home-based-advisors-provide-brilliant-service-for-leading-brands {
    color: var(--cloudworks-neutral-white, #ffffff);
    text-align: left;
    font: var(--pri-web-heading-h-2-medium,
            500 36px/52px "WorkSans-Medium",
            sans-serif);
    position: absolute;
    right: 55.49%;
    left: 10.42%;
    width: 34.1%;
    bottom: 37.58%;
    top: 15.15%;
    height: 47.27%;
}



// downward

.bottom-logos {
    width: 100%;
}


// .group-626585,
// .group-626585 * {
// //   box-sizing: border-box;
// }
.group-626585 {
    //   position: absolute;
    //   inset: 0;
    position: relative;
    padding-bottom: 25px;
    // height: ;
    // flex-wrap: wrap;
}

.privacy-notice {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--pri-web-heading-h-2-medium,
            500 36px/52px "WorkSans-Medium",
            sans-serif);
    position: relative;
    left: calc(50% - 570px);
    // top: 450px;
    width: 491px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.how-your-information-will-be-used {
    color: var(--grayscale-300, #45525c);
    text-align: left;
    font: var(--pri-web-heading-h-6-medium,
            500 20px/28px "WorkSans-Medium",
            sans-serif);
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 512px;
    width: 1140px;
    padding-bottom: 35px;
}

.privacy {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    margin-bottom: 25px;
    // top: 590px;
}

.frame-2608804 {
    background: rgba(84, 93, 125, 0.1);
    border-radius: 24px;
    border-style: solid;
    border-color: rgba(84, 93, 125, 0.1);
    border-width: 1px;
    padding: 1px 10px 1px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 30px;
    position: relative;
}

._1 {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: center;
    font: var(--sec-web-paragraph-large-regular,
            400 18px/28px "Roboto-Regular",
            sans-serif);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.as-your-employer-the-company-needs-to-keep-and-process-information-about-you-for-normal-employment-purposes-the-information-we-hold-and-process-will-be-used-for-our-management-and-administrative-use-only-we-will-keep-and-use-it-to-enable-us-to-run-the-business-and-manage-our-relationship-with-you-effectively-lawfully-and-appropriately-during-the-recruitment-process-whilst-you-are-working-for-us-at-the-time-when-your-employment-ends-and-after-you-have-left-this-includes-using-information-to-enable-us-to-comply-with-the-employment-contract-to-comply-with-any-legal-requirements-pursue-the-legitimate-interests-of-the-company-and-protect-our-legal-position-in-the-event-of-legal-proceedings-if-you-do-not-provide-this-data-we-may-be-unable-in-some-circumstances-to-comply-with-our-obligations-and-we-will-tell-you-about-the-implications-of-that-decision {
    color: var(--cloudworks-neutral-black, #000000);
    text-align: left;
    font: var(--sec-web-paragraph-large-regular,
            400 18px/28px "Roboto-Regular",
            sans-serif);
    position: relative;
    flex: 1;
}

.privacy2 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 816px;
}

.privacy3 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 902px;
}

.privacy4 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 988px;
}

.privacy5 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 1158px;
}

.privacy6 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 1244px;
}

.privacy7 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 1386px;
}

.privacy8 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 1528px;
}

.privacy9 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 1614px;
}

.privacy10 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 1700px;
}

.privacy11 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 1786px;
}

.privacy12 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 1872px;
}

.privacy13 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 1958px;
}

.privacy14 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 2018px;
}

.privacy15 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 2104px;
}

.privacy16 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 2218px;
}

.privacy17 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 2304px;
}

.privacy18 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 2418px;
}

.privacy19 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 1140px;
    position: relative;
    left: calc(50% - 570px);
    ;
    // top: 2478px;
}

.Group-626612 {
    display: none;
}

@media screen and (max-width:900px) {
    .center {
        width: 90%;
    }

    .privacy,
    .privacy-notice,
    .how-your-information-will-be-used {
        width: unset;
        position: unset;
    }

    .component-66 {
        display: none;
    }

    .Group-626612 {
        display: block;
        width: 100%;
        background-image: url('../assets/image/Rectangle-685.png');
        background-size: 100% 100%;

        p {
            color: #fff;
            font-size: 28px;
            padding: 10px;
            padding-top: 50px;
        }
    }
}</style>